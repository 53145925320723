.m-unordered-list-component {
  display: flex;
  padding: 0 20px;

  .m-unordered-list-column {
    flex: 1;
  }

  .m-unordered-list-item {
    display: flex;
    font-size: 16px;
    padding: 4px 2px;
  }
}