@import 'main/certificates.details.main.scss';
@import 'cnames/certificates.details.cnames.scss';

.m-certificates-details-component {
  .m-certificates-details-overview {
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-radius: 0;
    margin: 16px 0;
    padding: 12px 0;

    .m-certificates-details-overview-line {
      display: flex;

      .m-certificates-details-overview-field {
        flex: 1 1 1px;
        box-sizing: border-box;
        padding-right: 16px;

        .m-certificates-details-overview-field-title {
          color: $text-color;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
          width: max-content;
        }

        .m-certificates-details-overview-field-value {
          color: $gray1;
          display: flex;
          font-size: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .m-certificates-details-overview-field:last-child {
        flex-grow: 0;
        padding-right: 0;
      }
    }
  }
}