.m-stats-main-bandwidth-content {
  .m-stats-main-bandwidth-table {
    .date,
    .usage {
      width: 20%;
    }

    .dist,
    .zone {
      width: 30%;
    }
  }
}
