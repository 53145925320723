.m-footer {
  align-items: center;
  border-top: 1px solid $border-color;
  display: flex;
  height: 36px;
  padding: 0 16px;

  .m-footer-left {
    .m-footer-left-copyright-text {
      background-color: inherit;
      border: none;
      color: $text-color;
      font-size: 10px;
      font-weight: 500;
      height: inherit;
      letter-spacing: 0.5px;
      margin: 0 8px;
      padding: 0;
      text-transform: uppercase;
    }
  }

  .m-footer-right {
    display: flex;
    margin-left: auto;
  }

  .m-footer-link {
    color: $border-color-dark;
  }

  .m-footer-link:hover {
    color: $brand-color-contrast;
    cursor: pointer;
    text-decoration: underline;
  }

  .m-footer-link:not(:last-child) {
    margin-right: 12px;
  }

  .m-footer-button {
    background-color: inherit;
    border: none;
    color: $text-color;
    font-size: 10px;
    font-weight: 500;
    height: inherit;
    letter-spacing: 0.5px;
    margin: 0 8px;
    padding: 0;
    text-transform: uppercase;
  }

  .m-footer-button:hover {
    color: $cobalt3;
    text-decoration: underline;
  }

  .version {
    color: $text-color;
    font-size: 10px;
    font-weight: 500;
    opacity: 0.7;
    letter-spacing: 0.5px;
    margin: 0 8px;
    padding: 0;
  }
}
