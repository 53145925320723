.m-workspaces-invite-user-module {
  padding-bottom: 72px;
}

.m-workspaces-invite-user-custom-role-name-dialog {
  .m-form-text-input-component {
    margin-top: 12px;
  }

  .m-basic-dialog-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .crs-btn {
      margin: 0 8px;
    }

    .crs-btn:last-child {
      margin-right: 0;
    }
  }
}