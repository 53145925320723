.m-basic-dialog-component {
  border: none;
  
  .m-basic-dialog-container {
    background-color: #fff;
    border-radius: 3px;
    padding: 24px;
    width: 600px !important;

    .m-basic-dialog-head {
      display: flex;
  
      .m-basic-dialog-head-left-side {
        
        .m-basic-dialog-head-title {
          font-size: 20px;
          font-weight: 500;
        }
      }
  
      .m-basic-dialog-head-right-side {
        margin-left: auto;
  
        .m-basic-dialog-head-right-side-close-button-container {
  
          .m-basic-dialog-head-right-side-close-button {
            border: none;
            border-radius: 3px;
            color: $gray3;
            height: inherit;
            padding: 0;
          }

          .m-basic-dialog-head-right-side-close-button:hover {
            background-color: $light-gray3;
            color: $dark-gray5;
          }
        }
      }
    }
  
    .m-basic-dialog-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;

      .m-basic-dialog-text {
        color: $gray1;
        font-size: 16px;
      }

      .m-basic-dialog-checkbox {
        display: flex;
    
        label {
          color: $gray5;
          cursor: pointer;
    
          .m-basic-dialog-checkbox-label {
            display: flex;
    
            .m-basic-dialog-checkbox-label-checked {
              border: 1px solid $border-color;
              border-radius: 2px;
              height: 20px;
              margin-right: 8px;
              width: 20px;
    
              .m-basic-dialog-checkbox-label-checked-content {
                margin: 3px;
                height: 12px;
                width: 12px;
              }
            }
          }
        }
    
        input {
          display: none;
        }
    
        input:checked + label {
          color: $text-color;
    
          .m-basic-dialog-checkbox-label {
    
            .m-basic-dialog-checkbox-label-checked {
    
              .m-basic-dialog-checkbox-label-checked-content {
                background-color: $cobalt5;
                
              }
            }
          }
        }
    
        input:hover + label {
    
          .m-billing-main-module-add-credit-card-option-label {
    
            .m-basic-dialog-label-checked {
              border-color: $gray5;
            }
          }
        }
      }

      .m-basic-dialog-radio {
        display: flex;
        margin-right: 16px;
    
        label {
          color: $gray5;
          cursor: pointer;
    
          .m-basic-dialog-radio-label {
            display: flex;
    
            .m-basic-dialog-radio-label-checked {
              border: 1px solid $border-color;
              border-radius: 50%;
              height: 20px;
              margin-right: 8px;
              width: 20px;
    
              .m-basic-dialog-radio-label-checked-content {
                margin: 3px;
                height: 12px;
                width: 12px;
              }
            }
          }
        }
    
        input {
          display: none;
        }
    
        input:checked + label {
          color: $text-color;
    
          .m-basic-dialog-radio-label {
    
            .m-basic-dialog-radio-label-checked {
    
              .m-basic-dialog-radio-label-checked-content {
                background-color: $cobalt5;
                border-radius: 50%;
              }
            }
          }
        }
    
        input:hover + label {
    
          .m-billing-main-module-add-credit-card-option-label {
    
            .m-basic-dialog-label-checked {
              border-color: $gray5;
            }
          }
        }
      }

      .m-basic-dialog-section {

        .m-basic-dialog-section-title {
          color: $text-color;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .m-basic-dialog-section-content {
          padding-top: 8px;
        }
      }

      .m-basic-dialog-section:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.m-basic-dialog-radio {
  display: flex;
  margin-right: 16px;

  label {
    color: $gray5;
    cursor: pointer;

    .m-basic-dialog-radio-label {
      display: flex;

      .m-basic-dialog-radio-label-checked {
        border: 1px solid $border-color;
        border-radius: 50%;
        height: 20px;
        margin-right: 8px;
        width: 20px;

        .m-basic-dialog-radio-label-checked-content {
          margin: 3px;
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  input {
    display: none;
  }

  input:checked + label {
    color: $text-color;

    .m-basic-dialog-radio-label {

      .m-basic-dialog-radio-label-checked {

        .m-basic-dialog-radio-label-checked-content {
          background-color: $cobalt5;
          border-radius: 50%;
        }
      }
    }
  }

  input:hover + label {

    .m-billing-main-module-add-credit-card-option-label {

      .m-basic-dialog-label-checked {
        border-color: $gray5;
      }
    }
  }
}