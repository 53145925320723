.m-m-buckets-pages-metrics {
  .m-m-buckets-pages-metrics-filter {
    float: right;

    .m-filter-selectbox-title {
      color: #5c7080;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
    .m-filter-date-picker-button {
      -webkit-align-items: center;
      align-items: center;
      border: 1px solid #e1e8ed;
      font-size: 16px;
      font-weight: inherit;
      height: 33px;
      padding: 6px 12px;
      width: 400px;
      position: relative;
      .m-filter-date-picker-button-text {
        text-align: left;
      }
      .m-filter-date-picker-button-icon {
        position: absolute;
        right: 10px;
        top: 4px;
      }
    }
  }
  padding-bottom: 70px;
  .m-m-buckets-pages-metrics-content {
    margin-top: 37px;

    .m-m-buckets-pages-metrics-legend-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .m-m-buckets-pages-metrics-legend-data,
      .m-m-buckets-pages-metrics-legend {
        width: 80%;
        display: flex;
        font-size: 18px;

        .left-text {
          width: 55%;
        }
        .right-text {
          text-align: end;
          width: 11.25%;
        }
      }
      .m-m-buckets-pages-metrics-legend {
        padding-bottom: 5px;
        border-bottom: solid 1px $border-color;
        margin-bottom: 5px;
      }
    }
  }
}
