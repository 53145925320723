.header-editor-form-custom-headers {
  width: 100%;

  .header-editor-form-custom-headers-fields,
  .header-editor-form-custom-headers-items {
    display: flex;

    .header-editor-form-custom-headers-field {
      width: calc(50% - 96px);

      .header-editor-form-custom-headers-field-title {
        color: $gray1;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        height: 12px;
        margin-bottom: 2px;
        text-transform: uppercase;
      }

      .header-editor-form-custom-headers-field-input {
        width: 100%;
      }
    }

    .header-editor-form-custom-headers-field:not(:last-child) {
      margin-right: 16px;
    }

    .header-editor-form-custom-headers-button {
      height: 33px;
      margin-top: 14px;
      min-width: 160px;
      white-space: nowrap;
    }
  }

  .header-editor-form-custom-headers-items {
    flex-wrap: wrap;

    .header-editor-form-custom-header {
      display: flex;
      height: 33px;
      margin-bottom: 12px;

      .header-editor-form-custom-header-left {
        align-items: center;
        background-color: $brand-color-light;
        border: 1px solid $brand-color-light-border;
        border-radius: 3px 0 0 3px;
        color: #fff;
        display: flex;
        padding: 6px 12px 6px 6px;
      }

      .header-editor-form-custom-header-right {
        align-items: center;
        border: 1px solid $border-color;
        border-left: none;
        border-radius: 0 3px 3px 0;
        display: flex;
        padding: 6px 12px;
      }

      .header-editor-form-custom-header-button {
        background-color: inherit;
        border: none;
        border-radius: 3px;
        color: #fff;
        height: min-content;
        margin-right: 6px;
        padding: 2px;
      }

      .header-editor-form-custom-header-button:hover {
        background-color: $brand-color-light-hover;
      }
    }

    .header-editor-form-custom-header:not(:last-child) {
      margin-right: 12px;
    }
  }
}