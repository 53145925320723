.m-table-title-component {
  background-color: $light-gray4;
  border: 1px solid $light-gray3;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  display: flex;
  padding: 12px 16px 8px 16px;

  .m-table-title-left-side {
    .m-table-title-title {
			align-items: center;
			color: $text-color;
			display: flex;
			height: 100%;
      font-size: 18px;
      font-weight: 500;
    }
  }
  
  .m-table-title-right-side {
    margin-left: auto;
  }
}