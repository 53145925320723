.m-waf-lists-add-contents {
  height: 100%;

  .m-waf-lists-add-contents-content {
    height: calc(100% - 125px);
    padding-top: 12px;

    .m-waf-lists-add-contents-content-import {
      margin-bottom: 10px;
      float: right;

      .m-waf-lists-add-contents-content-import-file {
        margin-left: 10px;
      }
    }

    .m-waf-lists-add-contents-content-table {
      margin-top: 10px;

      .m-waf-lists-add-contents-content-table-no-items {
        align-items: center;
        border: 1px solid $light-gray4;
        border-top: none;
        color: $text-color;
        display: flex;
        height: 42px;
        justify-content: center;
      }
    }

    .m-waf-lists-add-contents-buttons {
      margin-top: 10px;
      float: right;
    }
  }
}      

.m-waf-lists-add-content-dialog {
  .m-waf-lists-add-content-dialog-button {
    float: right;
  }
}

.m-waf-content-delete-dialog {
  .m-waf-content-delete-dialog-buttons {
    margin-top: 10px;
    float: right;
  }
}