.datatable__column {
  color: #a7b6c2;
  padding: 4px 6px 3px 6px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  &:first-child {
    padding-left: 16px;
  }
  &__button {
    display: flex;
    flex-direction: row;
    background: none!important;
    border: none!important;
    color: #a7b6c2!important;
    font-family: "Roboto Flex", sans-serif;
    font-size: 12px!important;
    font-weight: 500;
    height: inherit!important;
    padding: 0!important;
    text-transform: uppercase;
    width: -webkit-min-content;
    align-items: center;
    width: min-content;
    &:hover {
      color: #ff9424;
      font-weight: 500;
    }
    &__sorted {
      position: relative;
      color: #7b7f82!important;
    }
    &__order {
      position: absolute;
      top: 0px;
      right: -3px;
      font-size: 9px;
    }
  }
}

.crs-table-column {
  color: $gray4;
  padding: 4px 6px 3px 6px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;

  .crs-table-column-order-icon-sorted {
    position: relative;

    .crs-table-column-order-icon-sorted-order {
      position: absolute;
      top: -2px;
      right: -3px;
      font-size: 9px;
    }
  }

  th {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.selection {
  width: 5%;
}

.crs-table .name {
  width: 25%;
}

.crs-table .cname {
  width: 20%;
}

.crs-table .domain-name {
  width: 25%;
}

.crs-table .created {
  width: 15%;
}

.crs-table .options {
  width: 5%;
}
