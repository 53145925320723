.m-m-distributions-pages-main {
  height: 100%;

  .m-m-distributions-pages-main-table-head {
    display: flex;
    height: 30px;

    .m-m-distributions-pages-main-table-head-create-button {
      margin-right: 12px;
    }
    
    .m-m-distributions-pages-main-table-head-search {
      height: 30px;
      width: 256px;
    }
  }

  .m-m-distributions-pages-main-content {
    padding: 16px 0;

    .m-m-distributions-pages-main-content-head {
      display: flex;
      height: 30px;
      margin-bottom: 16px;

      .m-m-distributions-pages-main-content-head-search {
        height: 30px;
        margin-left: auto;
        width: 256px;
      }

      .m-m-distributions-pages-main-content-head-select-options-container {

        .m-m-distributions-pages-main-content-head-select-options {
          align-items: center;
          border: 1px solid $light-gray3;
          border-radius: 3px;
          color: $gray1;
          display: flex;
          height: 30px;
          padding: 0 8px;

  
          :hover {
            color: $text-color;
            cursor: pointer;
          }

        }
      }

      .m-m-distributions-pages-main-content-head-view-mode-switch-container {
        margin-left: auto;
      }
    }

    .m-m-distributions-pages-main-content-items {

      .m-m-distributions-pages-main-content-items-no-item {
        align-items: center;
        border: 1px solid $light-gray4;
        border-top: none;
        color: $text-color;
        display: flex;
        height: 42px;
        justify-content: center;
      }

      .dist__link{
        color: #2b95d6!important;
        cursor: pointer;
        font-weight: 500;
        &:hover{
          color: #106ba3!important;
        }
      } 

      .name {
        width: 25%;
      }

      .type {
        width: 15%;
      }

      .domain-name {
        width: 30%;
      }

      .updated-at {
        width: 20%;
      }
    }
  }

  .m-m-distributions-pages-main-no-content {
    align-items: center;
    border: 2px dashed rgba(21, 179, 113, 0.2);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 0 64px 0;
    margin: 32px 0;

    .m-m-distributions-pages-main-no-content-icon {
      color: rgba(21, 179, 113, 0.2);
    }

    .m-m-distributions-pages-main-no-content-main-text {
      color: $green2;
      font-size: 24px;
      margin-bottom: 8px;
      margin-top: 32px;
    }

    .m-m-distributions-pages-main-no-content-alt-text {
      color: rgba(21, 179, 113, 0.6);
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.m-distributions-main-move-dialog-content {
  .m-selectbox-component {
    margin-top: 8px;
  }
}

.m-distributions-main-move-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .m-distributions-main-move-dialog-button:not(:last-child) {
    margin-right: 8px;
  }
}