.m-sidebar {
  background: $light-gray4;
  border-right: 1px solid $light-gray1;
  color: $text-color;
  display: flex;
  flex-direction: column;
  height: inherit;
  padding-top: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 200px;
  transition: 0.5s;

  .m-sidebar-groups {

    .m-sidebar-group-collapsed {

      .m-sidebar-group-collapsed-title {
        border-bottom: 1px solid $light-gray1;
        height: 0;
        margin: 19px 0 18px 0;
      }
      
      .m-sidebar-group-collapsed-children {

        .m-sidebar-group-collapsed-child {
          align-items: center;
          background: inherit;
          border: none;
          border-left: 3px solid $light-gray4;
          border-radius: 0;
          color: $gray1;
          cursor: pointer;
          display: flex;
          height: 36px;
          justify-content: center;
          padding: 0 13px;
          width: 100%;
          
          .m-sidebar-group-collapsed-child-icon {
            color: $text-color;
          }
        }
        
        .m-sidebar-group-collapsed-child:hover {
          background-color: #A854A810;
        }

        .m-sidebar-group-collapsed-child--active {
          background-color: #A854A840;
          border-left: 3px solid $violet4;
          color: $violet4;

          .m-sidebar-group-collapsed-child-title {
            font-weight: 500;
          }

          .m-sidebar-group-collapsed-child-icon {
            color: $violet4;
          }
        }

        .m-sidebar-group-collapsed-child--active:hover {
          background-color: #A854A840;
        }
      }
    }

    .m-sidebar-group {
      border-left: 3px solid $light-gray4;

      .m-sidebar-group-title {
        align-items: center;
        color: $gray2;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 1px;
        height: 38px;
        padding: 0 13px;
        padding-top: 6px;
        text-transform: uppercase;
      }

      .m-sidebar-group-children {

        .m-sidebar-group-child {
          align-items: center;
          background: inherit;
          border: none;
          border-radius: 0;
          color: $gray1;
          cursor: pointer;
          display: flex;
          height: 36px;
          padding: 0 13px;
          width: 100%;

          .m-sidebar-group-child-icon {
            align-items: center;
            color: $text-color;
            display: flex;
            margin-right: 12px;
          }

          .m-sidebar-group-child-title {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .5px;
            margin-top: 1px;
          }
        }

        .m-sidebar-group-child:hover {
          background-color: #A854A810;
        }

        .m-sidebar-group-child--active {
          background-color: #A854A840;
          color: $violet4;

          .m-sidebar-group-child-title {
            font-weight: 500;
          }

          .m-sidebar-group-child-icon {
            color: $violet4;
          }
        }

        .m-sidebar-group-child--active:hover {
          background-color: #A854A840;
        }
      }
    }

    .m-sidebar-group--active {
      background-color: #A854A820;
      border-left: 3px solid $violet4;

      .m-sidebar-group-title {
        color: $text-color;
        font-weight: 500;
      }
    }
  }

  .m-sidebar-footer {
    margin-top: auto;

    .m-sidebar-collapse-button {
      background: inherit;
      border: none;
      border-top: 1px solid $light-gray1;
      color: $gray3;
      font-weight: 400;
      letter-spacing: .5px;
      height: 36px;
      padding-top: 4px;
      width: 100%;

      .m-sidebar-collapse-button-open {
        align-items: center;
        display: flex;

        .m-sidebar-collapse-button-open-text {
          display: flex;
          margin-left: 8px;
          white-space: nowrap;
        }
      }
    }

    .m-sidebar-collapse-button:hover {
      background-color: $light-gray2;
    }
  }
}