.m-stats-main-hits-content {
  .m-stats-main-hits-table {
    .date {
      width: 20%;
    }

    .dist,
    .zone {
      width: 25%;
    }
  
    .hit,
    .miss {
      width: 15%;
    }
  }
}