.m-register-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  width: 400px;

  .m-register-form-head {
    display: flex;
    justify-content: center;

    .m-register-form-head-slogan {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  .m-register-form-forgot-password-section {
    display: flex;
    margin-bottom: 16px;
  }

  .m-register-form-sign-up-section {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .m-register-form-link-button {
    background-color: inherit;
    border: none;
    color: $text-color;
    font-weight: 500;
    height: inherit;
    margin: 0 4px;
    padding: 0;
  }

  .m-register-form-link-button:hover {
    color: $cobalt3;
    text-decoration: underline;
  }
  
  .m-register-form-submit-button {
    font-size: 18px;
    height: 40px;
    padding-bottom: 8px;
    width: 100%;
  }

  .m-register-form-footer {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .m-register-form-link-button {
      font-size: 10px;
      margin: 0 8px;
      text-transform: uppercase;
    }
  }
  
  .m-form-section-content {
    padding-top: 2px !important;
  }

  .m-register-form-agreement-checkbox {
    margin-bottom: 12px;

    input:checked + label {
  
      .m-checkbox-component-label {
  
        .m-checkbox-component-label-checked {
  
          .m-checkbox-component-label-checked-content {
            background-color: $green5;
          }
        }
      }
    }
  }

  .m-register-form-agreement-notifier {
    font-size: 12px;
    margin-top: 8px;
  }
}
.grecaptcha-badge { visibility: hidden; }
