.delete__item_table {
  width: 100%;
  table-layout: unset;
  display: block;

  thead {
    display: block;
    width: 100%;
    tr {
      display: block;
      th {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  tbody {
    max-height: 150px;
    display: inline-block;
    width: 100%;
    overflow: auto;
    tr {
      display: block;
      td {
        width: 100%;
      }
    }
  }
}

.table__object {
  width: 100%;
  table-layout: fixed;
  position: relative;

  .crs-table-cell {
    word-break: break-all;
  }

  thead {
    width: 100%;
    tr {
      th {
        max-width: none;
      }
      .name {
        width: 70%;
      }
      display: block;
      .size {
        width: 15%;
      }
      .last_modified {
        width: 15%;
      }
      .storage_class {
        width: 15%;
      }
    }
  }
  tbody {
    max-height: 500px;
    display: block;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    tr {
      .name {
        width: 71.6%;
      }
      display: block;
      .size {
        width: 15.4%;
      }
      .last_modified {
        width: 15.4%;
      }
      .storage_class {
        width: 15%;
      }
    }
  }
}

.m-m-buckets-pages-files {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .m-spinnerpage-component {
    z-index: 400;
    position: absolute;
    top: 1rem;
    right: 0rem;
    left: 0rem;
    bottom: 0rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
  }

  .bread-crumbs {
    padding-bottom: 30px;
    .bread-crumbs-item {
      float: left;
      color: #106ba3;
      cursor: pointer;
      font-size: 17px;
      display: block;

      &:not(:last-child)::after {
        content: ">";
        padding-left: 8px;
        padding-right: 7px;
        color: #53758d;
        font-size: 17px;
        font-weight: 500;
      }
    }
    .disabled {
      color: #687078 !important;
      cursor: unset !important;
    }
  }

  .m-buckets-pages-main-disable {
    pointer-events: none;
    opacity: 0.3;
  }

  .m-m-buckets-pages-files-content {
    width: 100%;

    .m-m-buckets-pages-files-content-uploader {
      height: 100px;

      .m-form-image-uploader-file-dropzone {
        height: 60px !important;
      }
    }

    .m-m-buckets-pages-files-content-action {
      display: flex;
      margin-bottom: 15px;

      .m-buckets-pages-files-content-uploader-create-folder-button {
        min-width: 121px;
      }

      .m-buckets-pages-files-content-uploader-delete-button {
      }

      .m-buckets-pages-files-content-uploader-previous-folder-button {
        min-width: 134px;
      }

      .m-buckets-pages-files-content-uploader-reflesh-button {
        min-width: 110px;
      }

      .m-buckets-pages-files-content-uploader-upload-button {
        min-width: 77px;
      }

      .m-m-buckets-pages-files-content-uploader-dropzone {
        width: 100%;

        .bp3-progress-bar {
          height: 16px !important;
        }

        .bp3-progress-bar {
          background-color: #bebebe !important;
        }

        .m-progressbar-progress {
          display: block !important;
          left: 200px;
          position: absolute;
          top: 44px;
          font-size: 12px;
          color: #fff;
        }
        .m-form-text-input {
          padding: 0px !important;

          .m-form-image-uploader-button {
            width: 100%;
            display: flex;
            justify-content: center;

            .m-form-image-uploader-content {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
        }
      }

      .m-buckets-pages-files-content-search-info {
        position: absolute;
        font-size: 12px;
        margin-top: -81px;
        width: 350px;
        background-color: #fff;
        word-break: keep-all;
        word-wrap: break-word;
        border: 1px solid #e1e8ed;
        border-radius: 4px;
        padding: 10px;
        z-index: 99;
      }
      .m-buckets-pages-files-content-search-input {
        display: block;
        position: relative;
        width: 100%;

        .m-form-text-field {
          margin-bottom: 8px !important;
        }
        .m-buckets-pages-files-content-search-input-icon__search {
          position: absolute;
          z-index: 1;
          margin-top: 4px;
          margin-left: 6px;
          color: #a3a3a3;
        }
        .m-buckets-pages-files-content-search-input-icon__clear {
          position: absolute;
          z-index: 1;
          margin-top: 4px;
          margin-left: 6px;
          color: #a3a3a3;
          right: 5px;
          top: 1px;
          cursor: pointer;
        }
        input {
          min-width: 100% !important;
          padding: 5px 32px 5px 35px;
          font-size: 15px;
        }
      }
    }
  }

  .m-buckets-pages-files-table-items-no-item {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }
}

.m-form-image-uploader-content-icon-file,
.m-form-image-uploader-content-icon {
  color: $violet4;
  margin-right: 5px;
}

.m-form-image-uploader-content-icon-file {
  transform: rotate(270deg);
}

.m-form-image-uploader-content-icon-delete {
  color: $red5;
}
input:checked + label {
  .m-checkbox-component-label {
    .m-checkbox-component-label-checked {
      .m-checkbox-component-label-checked-content {
        background-color: #a854a8 !important;
      }
    }
  }
}

.m-bucket-object-delete-dialog-description-text {
  margin-bottom: 10px;
  word-break: break-all;
}

.m-bucket-object-delete-dialog-description-warning {
  color: $red3;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: flex-end;

  .m-bucket-object-delete-dialog-description-warning-icon-container {
    margin-right: 15px;
  }
}

.m-buckets-main-content {
  .m-buckets-main-content-items {
    .order-info {
      padding: 10px;
      height: 40px;
      background-color: #f3fafe;
      border: 1px solid #ebf1f5;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .text {
        padding-left: 5px;
      }
    }

    .item-info {
      display: flex;
      align-items: center;
    }
  }
}

.m-buckets-main-create-presigned-dialog {
  .m-buckets-main-create-presigned-dialog-presigned-name {
    word-break: break-all;
  }

  .m-buckets-main-create-presigned-dialog-presigned-bar {
    margin: 16px 0px 8px;
    border: 0px;
    width: auto;
    height: 1px;
    flex-shrink: 0;
    background-color: #eff0f2;
  }
  .m-buckets-main-create-presigned-dialog-time-picker {
    display: flex;
    gap: 5px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .expire-date {
      padding-top: 5px;
      svg {
        width: 20px;
        float: left;
        fill: #818181;
      }
      p {
        float: left;
        padding-left: 5px;
        font-size: 13px;
        line-height: 20px;
        display: block;
      }
    }
    .bp3-popover-target {
      display: block;
    }
    .date {
      min-width: 100%;
      input {
        height: 40px;
      }
    }
    .label {
      flex: 0 1 100%;
      font-weight: 500;
    }
  }
}
