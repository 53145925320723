.m-login-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  width: 400px;

  .m-login-form-head {
    display: flex;
    justify-content: center;

    .m-login-form-head-slogan {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  .m-login-form-forgot-password-section {
    display: flex;
    margin-bottom: 16px;
  }

  .m-login-form-sign-up-section {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .verify-code-btn{
    width: 100%;
  }

  .m-login-form-link-button {
    background-color: inherit;
    border: none;
    color: $text-color;
    font-weight: 500;
    height: inherit;
    margin: 0 4px;
    padding: 0;
  }

  .m-login-form-link-button-disabled {
    background-color: transparent !important;
    display: flex;
    align-items: flex-start;
    min-height: 5px;
  }

  .m-login-form-link-button:hover {
    color: $cobalt3;
    text-decoration: underline;
  }

  .m-login-form-submit-button {
    font-size: 18px;
    height: 40px;
    padding-bottom: 8px;
    width: 100%;
  }

  .m-login-form-footer {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .m-login-form-link-button {
      font-size: 10px;
      margin: 0 8px;
      text-transform: uppercase;
    }
  }

  .m-form-section-content {
    padding-top: 2px !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}