.workspace-overview__main {
  &__container {
    display: flex;
    flex-direction: row;
    padding: 16px 0 30px;
    gap: 10px;

    .crs-table {
      border: none !important;
    }

    &__info {
      display: flex;
      margin-top: 16px;
      width: 100%;

      .m-info-box-component {
        cursor: pointer;
        min-height: 85px;
        &:hover {
          box-shadow: 0 0 0 1px rgb(210 210 210 / 20%), 0 2px 4px rgb(213 213 213 / 40%),
            0 8px 24px rgb(225 225 225 / 40%);
        }
      }
      .content__box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        font-size: 15px;
        padding-top: 10px;
        padding-left: 6px;
        color: #5c7080;
        flex-wrap: nowrap;
        position: relative;

        &__column {
          flex: 1;

          &:first-of-type {
            flex: 0.4;
            padding-right: 10px;
          }

          &__box {
            display: flex;
            flex-direction: column;

            &__item {
              display: flex;
              align-items: center;

              .m-status-circle-component {
                margin-right: 5px;
                margin-top: 0;
              }

              &__description {
                display: block;
                width: 110px;
              }
            }
          }
        }

        &__column--img {
          position: absolute;
          flex: 0;
          margin-top: -27px;
          right: 0;
          img {
            width: 70px;
            opacity: 0.7;
          }
        }
      }

      ul {
        padding-left: 22px;
        padding-top: 7px;
        margin: 0px;
        font-size: 15px;
      }

      &__card {
        h5 {
          color: #8a9ba8;
        }

        flex: 1;
        &:nth-child(3n-1) {
          margin-left: 1.3%;
          margin-right: 1.3%;
        }
        h5 {
          padding-top: 0px;
          margin-top: 0px;
          font-size: 15px;
          margin-bottom: 5px;
        }
        p {
          padding-left: 3px;
          margin-bottom: 5px;
          font-size: 15px;
        }
      }

      .m-info-box-component:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      flex: 1;

      &:first-of-type {
        flex-grow: 1.4;
      }

      &__started-item {
        height: 213px !important;
      }

      &__item {
        border: 1px solid #e1e8ed;
        margin-bottom: 13px;

        &__header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          border-bottom: 1px solid #ebf1f5;

          &__title {
            padding: 10px;
            font-weight: 500;
            padding-left: 16px;
            color: #8a9ba8;
          }

          &__action {
            padding: 10px;
            &__button {
              cursor: pointer;
              color: #8a9ba8;
              &:hover {
                color: #000;
              }
            }
          }

          &:nth-of-type(2n-1) {
            margin-right: 16px;
          }
        }
        //itwillchange
        &__content--full {
          min-height: 475px !important;
        }
        &__content {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 200px;

          &__links {
            li {
              a {
                color: #8a9ba8;
                line-height: 1.8;
                &:hover {
                  color: #000 !important;
                }
              }
            }
          }

          &__pagination {
            border: none;
            &--disable {
              cursor: not-allowed;
              pointer-events: none;
              .crs-pgn-navigation-button {
                color: #bfccd6 !important;
              }
            }
          }
          &__table {
            &__head {
              .causer {
                width: 20%;
              }
              .created_at {
                width: 27%;
              }
              .sservice {
                width: 13%;
              }
              .name {
                width: 30%;
              }
              .download {
                width: 13%;
              }
              .service {
                width: 20%;
              }
              .created_at__short {
                width: 35%;
              }
            }
            &__loading {
              opacity: 0.3;
            }

            &__loader {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
              left: 45%;
            }

            &__body {
              .crs-tooltip-component-with-content {
                p {
                  margin-bottom: 15px;
                  height: 3px;
                }
              }
              .crs-table-row {
                border-top: 1px solid #ebf1f5;
                border-bottom: 1px solid #ebf1f5;
              }
              &__no-item {
                padding: 10px;
                width: 100%;
                position: absolute;
                text-align: center;
                padding-top: 50px;
              }
              color: #5c7080;
              .download__button {
                &:hover {
                  color: #000 !important;
                }
              }
              strong {
                color: #5c7080;
              }
            }
          }
        }
        &__content_two {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 250px;
          max-height: 250px;
          position: relative;
          overflow-y: auto;

          &__links {
            li {
              a {
                color: #8a9ba8;
                line-height: 1.8;
                &:hover {
                  color: #000 !important;
                }
              }
            }
          }

          &__pagination {
            border: none;
            &--disable {
              cursor: not-allowed;
              pointer-events: none;
              .crs-pgn-navigation-button {
                color: #bfccd6 !important;
              }
            }
          }
          &__table {
            &__head {
              .causer {
                width: 20%;
              }
              .created_at {
                width: 27%;
              }
              .sservice {
                width: 13%;
              }
              .name {
                width: 30%;
              }
              .download {
                width: 13%;
              }
              .service {
                width: 20%;
              }
              .created_at__short {
                width: 35%;
              }
            }
            &__loading {
              opacity: 0.3;
            }

            &__loader {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
              left: 45%;
            }

            &__body {
              .crs-tooltip-component-with-content {
                p {
                  margin-bottom: 15px;
                  height: 3px;
                }
              }
              .crs-table-row {
                border-top: 1px solid #ebf1f5;
                border-bottom: 1px solid #ebf1f5;
              }
              &__no-item {
                padding: 10px;
                width: 100%;
                position: absolute;
                text-align: center;
                padding-top: 50px;
              }
              color: #5c7080;
              .download__button {
                &:hover {
                  color: #000 !important;
                }
              }
              strong {
                color: #5c7080;
              }
            }
          }
        }
      }
    }
  }
}
