.m-edit-address-form {
  .m-edit-address-form-buttons{
    float: right;
  }
  .m-basic-dialog-section-content {
    display: flex;
    flex-direction: row; // İkişerli gruplamalarla yatay sıralama
    flex-wrap: wrap; // Eğer sığmazsa bir sonraki satıra geç

    .m-edit-address-form-line {
      width: 50%;       // İki div yatayda sığdığı için bu değeri kullanıyoruz
      box-sizing: border-box; // padding ve border'ın genişliği etkilememesi için
  

      &:nth-child(2n+1) {
        padding-right: 15px;
      }
    }
  }
  .m-basic-dialog-section-content{
    padding-top: 8px;
  }
  .m-selectbox-button {
    height: 54px !important;
    margin-bottom: 12px;
  }
  .warning__text{
    font-size: 13px;
    color: red;
  }
}
.m-edit-address-form-line{
  position: relative;

  .m-billing-main-tax-id-tooltip{
    position: absolute;
    width: 22px;
    z-index: 1;
    right: 25px;
    top: 17px;
  }
}

.m-billing-main-module-edit-address-dialog {
  .m-basic-dialog-section-content {
    display: flex;
    flex-direction: row; // İkişerli gruplamalarla yatay sıralama
    flex-wrap: wrap; // Eğer sığmazsa bir sonraki satıra geç

    .m-edit-address-form-line {
      width: 50%;       // İki div yatayda sığdığı için bu değeri kullanıyoruz
      box-sizing: border-box; // padding ve border'ın genişliği etkilememesi için
  

      &:nth-child(2n+1) {
        padding-right: 15px;
      }
    }
  }
}

