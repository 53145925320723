.m-searchbar-component {
  border: 1px solid $border-color;
  border-radius: 3px;
  display: flex;
  height: 30px;
  width: inherit;

  .m-searchbar-icon {
    align-items: center;
    color: $gray5;
    display: flex;
    justify-content: center;
    padding: 6px;
  }

  input {
    border: none;
    color: $gray1;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 14px;
    height: 28px;
    padding: 6.5px 12px 5.5px 0;
    width: inherit;
  }

  ::placeholder {
    color: $gray5;
  }

  .m-searchbar-loading-spinner {
    align-items: center;
    display: flex;
    padding-right: 4px;
  }
}