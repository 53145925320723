.button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 6px 12px;
    font: 500 14px "Roboto Flex", sans-serif;
    border-radius: 3px;
    color: white;
    fill: white;
    cursor: pointer;
    outline: none;
    text-decoration: none;

    border-width: 1px;
    border-style: solid;
    cursor: pointer;

    .loading-content {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}


.bp3-disabled {
    background-color: #ebf1f5 !important;
    border: none !important;
    color: #bfccd6 !important;
    cursor: not-allowed !important;
    fill: #bfccd6 !important;

    &:hover {
        color: #bfccd6 !important;
    }
}

.button2-link {
    background-color: inherit;
    border: none;
    color: $text-color;
    font-weight: 500;
    height: inherit;
    margin: 0 4px!important;
    padding: 0!important;
    min-height: 20px!important;
    max-height: 20px!important;
}

.button2-link--disabled {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    min-height: 5px;
}
