.m-workspaces-owned-workspaces-content {
  .m-workspaces-owned-workspaces-table {
    margin-top: 16px;

    .icon {
      width: 3%;
    }
    
    .updated,
    .users {
      width: 20%;
    }

    .name {
      width: initial;
    }
  }

  .m-workspaces-owned-workspaces-table-no-items {
      align-items: center;
      border: 1px solid $light-gray4;
      border-top: none;
      color: $text-color;
      display: flex;
      height: 42px;
      justify-content: center;
  }

  .m-workspaces-owned-workspaces-section {
    .m-workspaces-owned-workspaces-section-title {
      display: flex;
      justify-content: space-between;

      .m-workspaces-owned-workspaces-section-title-text {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .m-workspaces-owned-workspaces-section-description {
      color: $gray1;
      font-size: 14px;
      margin-top: 2px;
    }

    .m-workspaces-owned-workspaces-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;
    }
  }
  
  .m-workspaces-owned-workspaces-section:not(:last-child) {
    border-bottom: 1px solid $light-gray4;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.m-workspaces-owned-workspaces-transfer-dialog {
  .m-form-text-input-component {
    margin-top: 8px;
  }

  .m-workspaces-owned-workspaces-transfer-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    .m-workspaces-owned-workspaces-transfer-dialog-button:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.m-workspaces-create-workspace-dialog-edit-workspace-form{
  .m-form-image-uploader-container {
      align-items: center !important;
    
      .m-form-text-input-component {
        .m-form-text-input {
          flex-direction: row !important;
        }
      }
    } 
}

@media screen and (max-width: 1600px) {
  .m-workspaces-owned-workspaces-content {
    .m-workspaces-owned-workspaces-table {
      .icon {
        width: 4%;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .m-workspaces-owned-workspaces-content {
    .m-workspaces-owned-workspaces-table {
      .icon {
        width: 5%;
      }
    }
  }
}