@import "tabs/billing.main.tabs.scss";

.m-billing-main-module {
  // NOTE The following is adjusted mainly because it makes balance tab scroll unnecessarily at 1080p, even though it's a (semi-)standard
  // padding-bottom: 96px;
  padding-bottom: 30px;

  .m-billing-main-module-content-tab {
    padding: 8px 0;
  }
}

.add-credit-card-dialog {
  .m-basic-dialog-content {
    padding-top: 0 !important;
  }
}
