.m-waf-custom-rules-component {
  .m-waf-custom-rules-create {
    display: flex;
    float: right;
    margin-bottom: 10px;

    .m-waf-custom-rules-button {
      margin-right: 10px;
    }
  }

  .m-waf-custom-rules-table {
    .m-waf-custom-rules-table-row.--dragged {
      background-color: $border-color !important;
    }

    .drag {
      width: 5%;
    }

    .name {
      width: 50%;
    }

    .on-off {
      width: 10%;
    }

    .updated {
      width: 30%;
    }
  }

  .m-waf-custom-rules-table-no-items {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }
}

.m-waf-custom-rules-switch {
  margin-bottom: 0;
}