.m-status-health-check-circle-component {
  align-items: center;
  display: flex;
  height: 20px;
  width: 60px;
  justify-content: center;
  margin-top: 15px;

  .m-status-health-check-circle-content {
    border-radius: 10px;
    height: 20px;
    width: 20px;
  }

  .icon {
    display: inline-table !important;
    color: #fff !important;
    margin-left: 1px;
    font-weight: bold;
  }

  .--healthy {
    background-color: $green5;
  }

  .--disabled {
    background-color: $gray5;
  }

  .--unhealthy {
    background-color: $red5;
  }

  .--degraded {
    background-color: $orange5;
  }

  .--default {
    background-color: $dark-gray5;
  }
}
