.m-edit-alert-form {
  .m-form-section-field {
    margin-bottom: 12px;
  }

  .m-edit-alert-form-submit-field {
    margin-bottom: 0;
  }

  .m-edit-alert-form-alerts {
    width: 100%;

    .m-edit-alert-form-alerts-fields {
      display: flex;

      .m-edit-alert-form-alerts-field {
        width: calc(50% - 96px);
        margin-bottom: 12px;

        .m-edit-alert-form-alerts-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-edit-alert-form-alerts-field-input {
          width: 100%;
        }
      }

      .m-edit-alert-form-alerts-field:not(:last-child) {
        margin-right: 16px;
      }

      .m-edit-alert-form-alerts-button {
        height: 33px;
        margin-top: 14px;
        min-width: 160px;
        white-space: nowrap;
      }
    }
    .m-edit-alert-form-alerts-items {
      .m-edit-alert-form-header {
        display: flex;

        .m-edit-alert-form-alerts-field {
          margin-bottom: 12px;

          .m-edit-alert-form-alerts-field-title {
            color: $gray1;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 1px;
            height: 12px;
            margin-bottom: 2px;
            text-transform: uppercase;
          }

          .m-edit-alert-form-alerts-field-input {
            width: 100%;
          }
        }

        .m-edit-alert-form-alerts-field:not(:last-child) {
          flex: 1;
          margin-right: 16px;
        }

        .m-edit-alert-form-alerts-button {
          height: 33px;
          margin-top: 14px;
          min-width: 160px;
          white-space: nowrap;
        }
      }
    }
  }
}
