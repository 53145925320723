.m-organization-settings-form {
  .m-organization-settings-form-button {
    margin-top: 12px;
  }
  .m-organization-settings-form-dropzone {
    border-radius: 3px;
    color: $gray5;
    cursor: alias;
    margin-bottom: 16px;

    .m-organization-settings-form-dropzone-hover {
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 3px;
      display: flex;
      height: 150px; 
      justify-content: center;
      min-height: 102px;
      padding: 0 10px;
      text-align: center;
    }
  }
}