.selectbox-mini-menu {
  &__selected {
    background-color: $green5;
    color: #fff !important;
    font-size: 16px;
    margin: 2px 0;
    padding: 4px 12px;
    &:hover {
      color: #fff !important;
    }
  }
  &__option{

  }
}
.selectbox-mini {
  .m-selectbox-option--selected {
    .bp3-text-overflow-ellipsis {
      background-color: $green5;
      color: #fff !important;
      font-size: 16px;
      margin: 2px 0;
      padding: 4px 12px;
    }
    .bp3-menu-item-label {
      color: #fff !important;
    }
  }
  &__filter {
    margin-bottom: -5px;

    input {
      border: none;
      box-shadow: none !important;
      color: $gray1;
    }

    ::placeholder {
      text-transform: capitalize;
    }
  }
  &__button-container {
    width: 100%;
    width: stretch;
    &__button {
      align-items: center;
      border: 1px solid $border-color;
      color: $gray1;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      height: inherit;
      width: inherit;
      padding: 4px 12px;
      min-width: 100px;
      &__text {
        color: #5c7080;
        display: -webkit-flex;
        display: flex;
        font-size: 15px;
        font-weight: 400;
      }

      .m-selectbox-loading-spinner {
        margin-left: auto;
      }

      .m-selectbox-button-icon {
        margin-left: auto;
      }

      .m-selectbox-button-label {
        color: $gray5;
        margin-left: 8px;
      }
    }
  }
}
