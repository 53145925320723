.m-waf-managed-rules {
  padding-top: 10px;
  .m-waf-managed-rules-row {
    display: flex;
    min-height: 50px;
    padding: 10px;
    .m-waf-managed-rules-name {
      min-width: 150px;
      font-weight: 500;
    }
    .m-waf-managed-rules-description {
      width: 100%;
    }
    .m-waf-managed-rules-check {
      text-align: right;
      width: 100px;
      padding-left: 40px;
    }
  }
}