.m-invite-user-form {
  .m-form-section-custom-field {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .m-form-section-field-left {
      color: $text-color;
      padding-right: 32px;
      width: 50%;

      .m-form-section-field-left-title {
        font-size: 16px;
        font-weight: 500;
      }

      .m-form-section-field-left-description {
        color: $gray3;
        font-size: 14px;
      }
    }

    .m-form-section-field-right {
      width: 50%;
    }
  }

  .m-form-section-buttons {
    margin-top: 12px;
  }

  .m-invite-user-form-advanced-section-head {
    .m-section-head-left {
      cursor: pointer;
    }

    .m-invite-user-form-advanced-section-head-buttons {
      .crs-btn {
        margin: 0 8px;
      }

      .crs-btn:last-child {
        margin-right: 0;
      }
    }
  }
}


@media screen and (max-width: 1280px) {
  .m-invite-user-form {
    .m-form-section-custom-field {
      flex-direction: column;

      .m-form-section-field-left {
        margin-bottom: 4px;
        width: 100%;
      }

      .m-form-section-field-right {
        margin-left: unset;
        width: 100%;
      }
    }
  }
}