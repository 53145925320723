@import "./bandwidth/stats.main.bandwidth.scss";
@import "./hits/stats.main.hits.scss";
@import "./requests/stats.main.requests.scss";
@import "./traffic/stats.main.traffic.scss";

@import './browser/visitors.main.browser.scss';
@import './country/visitors.main.country.scss';
@import './device/visitors.main.device.scss';
@import './os/visitors.main.os.scss';

.m-stats-main-tabs-content {
  .m-stats-main-tabs-content-box {
    margin: 16px 0;
  }

  .m-stats-main-tabs-chart-box {
    border: 1px solid $border-color;
    border-radius: 3px;
    margin-top: 8px;
    padding-bottom: 16px;
  }

  .m-stats-main-tabs-info-boxes {
    display: flex;

    .m-stats-main-tabs-info-box-group {
      display: flex;
      width: 32.5%;

      .csv_download {
        right: -1px!important;
        top: -14px!important;
      }
    }

    .m-stats-main-tabs-info-box-group:not(:last-child) {
      margin-right: 16px;
    }

    .m-stats-main-tabs-info-box-group.vertical {
      flex-direction: column;

      .m-info-box-component:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .m-stats-main-table-loading-state {
    height: 120px;
    padding: 24px 0;
  }
}

@media screen and (max-width: 1440px) {
  .m-stats-main-tabs-content {
    .m-stats-main-tabs-info-boxes {
      flex-wrap: wrap;
      justify-content: space-between;

      .m-stats-main-tabs-info-box-group {
        width: 48%;
        margin-bottom: 16px;
      }

      .m-stats-main-tabs-info-box-group:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .m-stats-main-tabs-content {
    .m-stats-main-tabs-info-boxes {
      flex-direction: column;

      .m-stats-main-tabs-info-box-group {
        width: 100%;
        margin-right: 0;
      }

      .m-stats-main-tabs-info-box-group:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

.m-stats-main-content-tab-container  {
  .m-visitors-showcase {
    display: flex;
    margin: 16px 0;
    width: 100%;

    .m-visitors-showcase-right-side {
      margin-left: 16px;
      width: 600px;

      .m-visitors-showcase-table {
        .color {
          width: 8%;
        }

        .m-visitors-showcase-table-no-item {
          align-items: center;
          border: 1px solid $light-gray4;
          border-top: none;
          color: $text-color;
          display: flex;
          height: 42px;
          justify-content: center;
        }

        .m-visitors-showcase-table-color-circle {
          border-radius: 10px;
          height: 10px;
          width: 10px!important;
        }
      }
    }
  }
  .m-visitors-chart-box {
    border: 1px solid $border-color;
    border-radius: 3px;
    flex-grow: 1;
  }

  .m-visitors-table-box {
    .date {
      width: 30%;
    }

    .count {
      width: 20%;
    }

    .expand {
      width: 5%;
    }

    .expand-cell {
      text-align: right;
    }

    .m-visitors-table-expandable-row {
      cursor: pointer;
    }
    
    .crs-table-row {
      border-bottom: none !important;
    }

    .m-visitors-table-expanded-row {
      display: flex;
      background-color: $light-gray5;
      padding: 16px 0 0 16px;

      .m-visitors-table-expanded-row-fragments {
        display: flex;
        flex-wrap: wrap;
    
        .m-visitors-table-expanded-row-fragment {
          background-color: #fff;
          border-bottom: none;
          border-radius: 3px;
          margin-bottom: 16px;
          margin-right: 16px;
          width: 260px;
    
          .m-visitors-table-expanded-row-fragment-head {
            background-color: $brand-color-contrast-hover;
            border-radius: 3px 3px 0 0;
            color: #fff;
            display: flex;
            font-size: 16px;
            font-weight: 500;
            padding: 8px 12px;
    
            .m-visitors-table-expanded-row-fragment-head-count {
              margin-left: auto;
            }
          }
    
          .m-visitors-table-expanded-row-fragment-zones {
            display: flex;
            flex-wrap: wrap;
    
            .crs-tooltip-component-with-content {
              border-bottom: 2px solid $border-color;
              border-left: 2px solid $border-color;
              flex: 1;
              min-width: 64px;
    
              .bp3-popover-wrapper {
                width: 100%;
    
                .bp3-popover-target {
                  width: 100%;
                }
              }
            }
            
            .crs-tooltip-component-with-content:last-child {
              border-right: 2px solid $border-color;
            }
            
            .crs-tooltip-component-with-content:nth-of-type(4n) {
              border-right: 2px solid $border-color;
            }
    
            .m-visitors-table-expanded-row-fragment-zone {
              align-items: center;
              display: flex;
              flex-direction: column;
              padding: 4px;
              width: 100%;
              
              .m-visitors-table-expanded-row-fragment-zone-name {
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
    
            .m-visitors-table-expanded-row-fragment-zone:hover {
              background-color: $light-gray5;
            }
          }
        }
      }
    }
    
    .crs-expanded-row .crs-table-cell .bp3-collapse-body {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1400px) {
  .m-visitors-showcase {
    flex-direction: column;

    .m-visitors-showcase-right-side {
      margin-left: 0 !important;
      margin-top: 12px;
      width: 100% !important;
    }
  }
}