.m-distributions-error-pages-main {

  .m-distributions-error-pages-table-head {
    display: flex;
    height: 30px;
  }

  .m-distributions-error-pages-table {
    margin: 16px 0 48px 0;

    .code {
      width: 15%;
    }

    .name {
      width: 60%;
    }

    .ttl {
      width: 15%;
    }
  }
}