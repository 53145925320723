.m-section-head-component {
  display: flex;

  .m-section-head-left {
    .m-section-head-title {
      color: $text-color;
      font-size: 16px;
      font-weight: 500;
    }

    code {
      background: #f4f4f4;
      border: 1px solid #ddd;
      color: #666;
      page-break-inside: avoid;
      font-family: monospace;
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 1.6em;
      max-width: 100%;
      overflow: auto;
      padding: 1px 0.5em;
    }

    .m-section-head-description {
      color: $description-color;
      font-size: 14px;
      line-height: 20px;
    }

    .m-section-head-description-tooltip {
      display: block;
      clear: both;
    }
  }

  .m-section-head-right {
    margin-left: auto;
  }

  .crs-tooltip-component-title {
    float: left;
  }

  .m-generic-form-policy-tooltip-title {
    font-size: 14px;
    margin-left: 5px;
    float: left;
    width: 21px;
    margin-top: 2px;
    padding: 2px;
    cursor: pointer;

    .crs-tooltip-component-content {
      padding: 0 4px;
    }
  }
}
