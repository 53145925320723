.m-edit-details-form {

  .user-edit-security {
    //border: 1px solid #e4e7eb;
    border-radius: 6px;

    .methods {
      color: #5a5a5a;
      padding: 5px;
      border-radius: 6px;
      padding-bottom: 20px;

      &__header {
        font-size: 16px;
        margin-bottom: 5px;
        color: #3a4a59;
        font-weight: 500;
        border-bottom: 1px solid #e4e7eb;
        height: 30px;
      }

      &__item {
        display: flex;
        align-items: flex-start;
        padding: 5px 0;
        border: none;
        border-bottom: 1px solid #e4e7eb;
        align-items: center;
        height: 100%;
        text-align: left;
        min-height: 55px;
        width: 100%;

        .indicator {
          width: 5px;
          height: 100%;
          margin-right: 10px;
          padding-right: 5px;
          height: 44px;
        }

        &:hover {
          .indicator {
            background-color: rgba(43, 148, 214, 0.3);
          }
        }

        .indicator--selected {
          background-color: #4580e6!important;
        }

        &:last-child {
          border-bottom: none;
        }

        &-icon {
          margin-right: 8px;
          align-self: flex-start;
          color: #5c7080;
        }

        &-content {
          padding: 0 2px;
          position: relative;
          color: #5c7080;
          font-size: 16px;
          grid-gap: 20px;
          gap: 0px;
          display: flex;
          align-items: flex-start;
          margin-top: 2px;
          flex-grow: 1;
          flex-direction: column;
        }

        &__header {
          display: flex;
          padding-top: 3px;

          &-title {
            color: #5c7080;
            font-weight: bold;
            padding-bottom: 6px;
            font-size: 14px;
          }

          &-status {
            display: inline-block;
            margin-left: 10px;
            padding: 3px 5px;
            border-radius: 4px;
            color: #ffffff;
            font-size: 12px;

            &.configured {
              background-color: #4580e6;
              height: 100%;
            }
          }
        }

        &-description {
          font-size: 14px;
          color: #5C7080;
        }

        &-restriction {
          font-size: 14px;
          color: $orange4;
        }

        &-action {
          padding: 8px 0;
          font-size: 14px;

          .button2 {
            width: 75px;
          }
        }
      }
    }
  }

  .m-edit-details-form-email-field {
    margin-bottom: 12px;

    .m-form-text-input-component {
      margin-bottom: 0;
    }

    .m-edit-details-form-email-field-change-button {
      border: none;
      color: $blue4;
      font-size: 12px;
      font-weight: 500;
      height: inherit;
      padding: 0;
    }

    .m-edit-details-form-resend-button-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .m-edit-details-form-resend-button {
        border: none;
        color: $blue4;
        font-size: 12px;
        font-weight: 500;
        height: inherit;
        padding: 5px;
        width: max-content;
      }
    }

    .m-edit-details-form-email-field-change-button:hover {
      color: $blue3;
      text-decoration: underline;
    }

    .m-edit-details-form-email-field-phone {
      .flag-dropdown {
        background-color: #fff !important;
        border: none !important;
        margin-left: -5px !important;

        .selected-flag {
          background-color: #fff !important;
        }
      }
    }
  }
}