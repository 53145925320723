.m-stats-main-requests-content {
  .m-stats-main-requests-table {
    .date,
    .count {
      width: 20%;
    }

    .dist,
    .zone {
      width: 30%;
    }
  }
}