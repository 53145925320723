// Dark-Gray Color Scheme
$dark-gray1: #182026;
$dark-gray2: #202B33;
$dark-gray3: #293742;
$dark-gray4: #30404D;
$dark-gray5: #394B59;

// Light-Gray Color Scheme
$light-gray1: #CED9E0;
$light-gray2: #D8E1E8;
$light-gray3: #E1E8ED;
$light-gray4: #EBF1F5;
$light-gray5: #F5F8FA;
$light-gray-custom: #fafafa;

// Gray Color Scheme
$gray1: #5C7080;
$gray2: #738694;
$gray3: #8A9BA8;
$gray4: #A7B6C2;
$gray5: #BFCCD6;

// Blue Color Scheme
$blue1: #0E5A8A;
$blue2: #106BA3;
$blue3: #137CBD;
$blue4: #2B95D6;
$blue5: #48AFF0;

// Green Color Scheme
$green1: #0A6640;
$green2: #0D8050;
$green3: #0F9960;
$green4: #15B371;
$green5: #3DCC91;

// Turquoise Color Scheme
$turquoise1: #008075;
$turquoise2: #00998C;
$turquoise3: #00B3A4;
$turquoise4: #14CCBD;
$turquoise5: #2EE6D6;

// Vermilion Color Scheme
$vermilion1: #9E2B0E;
$vermilion2: #B83211;
$vermilion3: #D13913;
$vermilion4: #EB532D;
$vermilion5: #FF6E4A;

// Red Color Scheme
$red1: #A82A2A;
$red2: #C23030;
$red3: #DB3737;
$red4: #F55656;
$red5: #FF7373;

// Gold Color Scheme
$gold1: #A67908;
$gold2: #BF8C0A;
$gold3: #D99E0B;
$gold4: #F2B824;
$gold5: #FFC940;

// Indigo Color Scheme
$indigo1: #5642A6;
$indigo2: #634DBF;
$indigo3: #7157D9;
$indigo4: #9179F2;
$indigo5: #AD99FF;

// Cobalt Color Scheme
$cobalt1: #1F4B99;
$cobalt2: #2458B3;
$cobalt3: #2965CC;
$cobalt4: #4580E6;
$cobalt5: #669EFF;

// Violet Color Scheme
$violet1: #5C255C;
$violet2: #752F75;
$violet3: #8F398F;
$violet4: #A854A8;
$violet5: #C274C2;

// Orange Color Scheme
$orange1: #A66321;
$orange2: #BF7326;
$orange3: #D9822B;
$orange4: #F29D49;
$orange5: #FFB366;
