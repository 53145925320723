$billing-settings-width: 768px;

.m-billing-main-module-edit-address-dialog-content{
  .m-edit-address-form-buttons{
    float: right;
  }
}
.m-billing-main-alert {
  width: $billing-settings-width;

  .m-billing-main-alert-content {
    margin-top: 8px;
    display: flex;
    flex-direction: row; // İkişerli gruplamalarla yatay sıralama
    flex-wrap: wrap; // Eğer sığmazsa bir sonraki satıra geç

    .m-edit-address-form-line {
      width: 50%;       // İki div yatayda sığdığı için bu değeri kullanıyoruz
      box-sizing: border-box; // padding ve border'ın genişliği etkilememesi için
  

      &:nth-child(2n+1) {
        padding-right: 15px;
      }
    }

    label {
      color: $gray1;
    }

    .m-billing-main-alert-content-button {
      margin-top: 12px;
    }

    .m-billing-main-alert-content-amount-input {
      margin-top: 12px;

      .m-form-text-input-component {
        margin-bottom: 0;
      }
    }
  }
}

.m-billing-main-address {
  width: 80%;

  .m-billing-main-address-content {
     
  }
}

.m-billing-main-tax-id {

  .m-billing-main-tax-id-tooltip {
    width: max-content;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }

  .m-billing-main-tax-id-content {
    padding-top: 16px;

    .m-billing-main-tax-id-field {
      width: 200px;
    }

    .m-billing-main-tax-id-button {
      margin-top: 12px;
    }
  }
}
