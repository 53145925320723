.m-list-edit-form-component {
  .m-list-edit-form {
    &-type {
      display: flex;

      &-title {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-right: 12px;
      }

      &-field {
        flex: 1;
      }
    }

    &-buttons {
      margin-top: 15px;
      float: right;
    }
  }
}

