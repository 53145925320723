.m-add-balance-form-content-billing-address-not-found {
  color: $gray5;
  font-size: 16px;
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.biling-adress {
  .m-basic-dialog-container {
    width: 800px !important;
  }
}