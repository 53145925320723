.m-agreements-dialog {
  .m-basic-dialog-container {
    width: 75vw !important;
    height: 85vh;
    overflow: hidden;
	}

	.m-basic-dialog-head {
		.m-basic-dialog-head-right-side {
			z-index: 2;
		}
	}
	
  .m-basic-dialog-content {
    padding-top: 8px !important;
    height: 100%;

    .m-agreements-dialog-agreement {
      border: 2px solid $border-color;
      height: calc(100% - 74px); // 74 = 24px bottom + 30px button + 12px between + 8px padding-top
      width: 100%;
      margin-bottom: 7px; // There is an extra 5px space between them somehow, so it matches 12px
    }

    .m-agreements-dialog-title-bar {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 32px;
      margin-bottom: -16px;
      width: 100%;

      .m-agreements-dialog-steps {
        display: flex;
    
        .m-agreements-dialog-step {
          .m-agreements-dialog-step-button {
            border: 1px solid $border-color;
            border-radius: 3px;
            cursor: auto;
            font-family: 'Roboto Flex', sans-serif;
            font-size: 10px;
            font-weight: 500;
            height: inherit;
            padding: 5px 8px 4px 8px;
            text-transform: uppercase;
          }
    
          .m-agreements-dialog-step-button--active {
            background-color: $brand-color-contrast;
            border: 1px solid $brand-color-contrast;
            color: #fff;
          }
        }
    
        .m-agreements-dialog-step:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  .m-agreements-dialog-buttons {
    display: flex;
    justify-content: space-between;
  }
}