.cloner__main {
  &__header {
    display: flex;
    flex-direction: row;
    column-gap: 24px;

    &__header {
      width: 256px;
    }
  }
  &__container {
    &__content {
      padding: 16px 0 30px;
      &__pagination {
        border: none;
        &--disable {
          cursor: not-allowed;
          pointer-events: none;
          .crs-pgn-navigation-button {
            color: #bfccd6 !important;
          }
        }
      }

      &__table {
        position: relative;
        margin-top: 20px;
        &__header {
          .id {
            width: 27%;
          }
          .type {
            width: 9%;
          }
          .enabled {
            width: 10%;
          }
          .run_count {
            width: 10%;
          }
          .last_execution{
            width: 13%;
          }
          .action {
            width: 7%;
          }
        }
        &__body--disable {
          pointer-events: none;
          opacity: 0.3;
        }
        &__body {
          &__no-item {
            padding: 10px;
            width: 100%;
            position: absolute;
            text-align: center;
            padding-top: 0px;
          }
          &__loader {
            position: absolute;
            top: 40%;
            left: 45%;
            height: 20px;
          }
          &__info {
            padding: 5px;
            border-radius: 3px;
            width: 72px;
            display: block;
            text-align: center;
            &__active {
              background-color: rgba(177, 236, 181, 0.36);
            }
            &__deactivate {
              background-color: rgb(236 177 180 / 36%);
            }
          }
        }
      }
    }
  }
}
