.m-create-cache-invalidation-form {
  .m-create-cache-invalidation-form-headers-fields {
    display: flex;
    justify-content: space-between;

    .m-create-cache-invalidation-form-headers-button {
      align-self: center;
      height: 33px;
      margin-top: 3px;
    }

    > div:nth-child(1) {
      width: 30%;
    }

    > div:nth-child(2) {
      margin: 0 8px 8px;
      width: calc(70% - 151px);
    }

    .m-selectbox-button-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }

    .m-create-cache-invalidation-form-headers-button {
      flex-shrink: 0;
      width: 135px;
    }
  }

  .m-create-cache-invalidation-form-textarea {
    .m-form-text-input {
      height: 194px !important;
      justify-content: inherit !important;
    }
  }
}