.info-bar {
  border: 1px solid #eaeaea;

  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &:nth-child(n+2):not(:last-child) {
      border-bottom: 1px solid #ecdac9ba;
    }
  }

  .bold-text {
    font-weight: 600;
  }

  &__button {
    position: absolute;
    right: 20px;
    top: 30%;
    width: 250px;

    button {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      border-color: #da9959!important;
    }
  }
}