@import "pages/user/user.main.scss";
@import "pages/service/service.main.scss";
@import "pages/server/server.main.scss";
@import "pages/service/log/detail/pages/serverDetail.main.scss";
@import "pages/workspace/detail/WorkspaceDetail.main.scss";

.admin {
    &__main {
        .crs-page-head-component {
            height: 35px;
            padding-bottom: 0px !important;
        }
        &__page-head {
            margin-top: 5px;
        }

        .breadcrumbs {
            position: relative;
            display: block;
            height: 20px;
        }
        &__head {
            &__action-menu {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
            }
        }
        .crs-page-head-component {
            padding-bottom: 15px;
        }
        .admin_btn {
            min-width: 40px;
            position: relative;
            img {
                position: absolute;
                top: 19px;
                right: 1px;
            }
        }

        .admin_btn-primary {
            background-color: $gray2 !important;
            border: none;
            color: white;

            &:hover {
                background-color: $gray3 !important;
            }
        }

        .m-section-content-component {
            margin-top: 0px!important;
            position: relative;
        }

        &__right-info {
            right: 9px;
            text-align: right;
            position: absolute;
            top: -90px;
        }

        &__group {
            display: flex;
            flex-direction: row;
            gap: 100px;
            padding-top: 15px;
            &__delete {
                display: flex;
                flex-direction: row;
                gap: 20px;
                padding-top: 15px;
            }
        }
        &__form-group {
            display: flex;
            gap: 15px;
            &__box {
                text-align: center;
            }
        }
        &__form {
            &__item {
                position: relative;
                &__delete-button {
                    position: absolute;
                    top: 28px;
                    right: 5px;
                    size: 18px;
                    color: #aaaaaa;
                    cursor: pointer;
                }
            }
            width: 400px;
            .admin_btn-primary {
                margin-bottom: 10px;
                background-color: #5c7080;
            }
            .m-selectbox-component {
                padding-bottom: 10px;
            }

            &__deleted_server_form {
                width: 100% !important;

                .m-selectbox-component {
                    margin-bottom: 12px;
                }
            }
        }
        .m-basic-dialog-component .m-basic-dialog-container {
            background-color: #fff;
            border-radius: 3px;
            padding: 24px;
            width: 760px !important;
            min-height: 00px;
        }

        &__form {
            .m-form-image-uploader .m-form-image-uploader-container {
                display: flex;
                flex-direction: row;
                gap: 14px;
                align-items: center;
            }
            .m-form-image-uploader-dropzone {
                border: 3px solid #e1e8ed !important;
                width: 100px !important;
                height: 80px !important;

                .m-form-image-uploader-preview {
                    width: 76px !important;
                    height: 80px !important;
                }
            }
            &__check-group {
                .m-checkbox-component-label {
                    font-size: 14px;
                }
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 0px;
                padding-bottom: 6px;
            }
            &__actions {
                text-align: right;
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: flex-end;
            }
            &__label {
                color: #5c7080;
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 1px;
                margin-bottom: 2px;
                text-transform: uppercase;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                padding-bottom: 5px;
            }
        }

        &__tab {
            // padding-top: 4px;
            padding-top: 5px;
            .bp3-tab-panel {
                margin-top: 8px;
            }
            .bp3-tab-list {
                border-bottom: 2px solid #ebf1f5;
                .bp3-tab {
                    font-weight: 400;
                    height: 33px;
                    bottom: -2px;
                    font-size: 14px;
                    color: #474747;
                }
                .bp3-tab[aria-selected="true"] {
                    border-bottom: 2px solid #2b95d6 !important;
                    color: #2b95d6 !important;
                }
                .bp3-tab:hover {
                    border-bottom: 2px solid #ffa950;
                    color: #ff9424;
                }
                .bp3-tab-indicator-wrapper .bp3-tab-indicator {
                    background-color: transparent;
                }
            }
        }
    }
}
