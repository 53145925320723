@import "~@blueprintjs/core/lib/scss/variables";

.m-distributions-redirection-main {
  .disable {
    pointer-events: none;
    opacity: 0.3;
  }

  .m-spinnerpage-component {
    position: absolute;
    top: 50%;
    left: 45%;
    height: 20px;
  }

  .crs-table-cell {
    position: relative;

    .action {
      padding: 5px;
      border-radius: 3px;
      width: 72px;
      display: block;
      text-align: center;
    }
    .color-rewrite {
      background-color: rgb(179 207 255 / 36%);
      color: #394B59;
    }
    .color-redirect {
      background-color: rgb(177 236 181 / 36%);
      color: #394B59;
    }
  }

  .icon-box {
    width: 60px !important;
  }

  .crs-table-row {
    img {
      width: 20px !important;
      vertical-align: middle;
    }
    .crs-table-column:first-child {
      width: 5%;
    }
    .flag {
      width: 9%;
    }
  }

  .m-distributions-redirection-main-disable {
    pointer-events: none;
    opacity: 0.3;
  }

  .m-distributions-redirection-table-head {
    display: flex;

    .m-distributions-redirection-table-head-search {
      height: 30px;
      width: 256px;
    }

    .m-distributions-redirection-table-head-add-button {
      margin-right: 12px;
    }
  }

  .m-distributions-redirection-main-table {
    position: relative;
    margin: 16px 0 0 0;

    .m-distributions-redirection-main-table-loading {
      height: 240px;
      padding: 24px 0;
    }
  }

  .m-distributions-redirection-main-table-no-item {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }

  .path-operator {
    width: 15%;
  }

  .path-pattern {
    width: 35%;
  }

  .origin {
    width: 25%;
  }

  .updated-at {
    width: 15%;
  }

  .m-distributions-redirection-callout {
    margin-top: 15px;
  }
}
