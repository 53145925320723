.s3-dialog-section {
    .s3-dialog-section-form {
    }
    .m-form-buttons {
        float: right;
    }
}

.m-public-link-buttons {
    float: right;
    .m-public-link-buttons-generate {
        margin-left: 14px;
    }
}
