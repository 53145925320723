.m-switch-component {
  input[type="checkbox"]:checked + .bp3-control-indicator {
    background-color: $green4;
  }

  input[type="checkbox"]:checked:not(:disabled):active + .bp3-control-indicator {
    background-color: $green3;
  }

  &:hover {
    input[type="checkbox"]:checked + .bp3-control-indicator {
      background-color: $green5;
    }
  }
}