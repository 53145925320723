@import "./forgotPassword/forgotpassword.scss";
@import "./login/login.scss";
@import "./totp/totp.scss";
@import "./register/register.scss";
@import "./resetPassword/resetpassword.scss";
@import "./verifyMail/verifymail.scss";

.main__login__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("~common/assets/background-8.jpg");
  background-size: cover;
  min-height: 100%;

  .m-auth-module {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}
