.breadcrumbs {
  font-size: 16px !important;
  &__item {
    font-weight: 500;
    color: #8697a5;
    a {
      &:hover {
        color: #ff9424 !important;
      }
    }
  }
  &__item-last {
    font-weight: 500;
    color: #5c7080;
  }
}
