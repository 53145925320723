.crs-table-cell {
  height: 42px;
  padding: 4px 6px;
}

.crs-table-cell:first-child {
  padding-left: 16px;
}

.crs-table-cell:last-child {
  padding-right: 16px;
}

.crs-table-body .options {
  text-align: right;
}

/* Interactable Table Cell (links etc.) */

.crs-table-cell--interactable {

  .crs-table-column-button {
    background: none;
    border: none;
    color: $blue4;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: min-content;
    padding: 0;
  }

  .crs-table-column-button:hover {
    color: $blue2;
  }
}