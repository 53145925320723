@import './inviteUser/inviteUser.workspaces.scss';
@import './main/workspaces.main.scss';
@import './manage/manage.workspaces.scss';

.m-workspaces-module {
  .m-workspaces-tab {
    .m-workspaces-tab-title {
      display: flex;
      justify-content: space-between;

      .m-workspaces-tab-title-text {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .m-workspaces-tab-description {
      color: $gray1;
      font-size: 14px;
      margin-top: 2px;
    }

    .m-workspaces-tab-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;
    }

    .m-workspaces-table-no-items {
      align-items: center;
      border: 1px solid $light-gray4;
      border-top: none;
      color: $text-color;
      display: flex;
      height: 42px;
      justify-content: center;
    }
  }
} 