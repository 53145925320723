.cloner__main {
  &__header {
    display: flex;
    flex-direction: row;
    column-gap: 24px;

    &__header {
      width: 256px;
    }
  }
  &__container {
    &__content {
      padding: 0px 0 30px;
      &__pagination {
        border: none;
        &--disable {
          cursor: not-allowed;
          pointer-events: none;
          .crs-pgn-navigation-button {
            color: #bfccd6 !important;
          }
        }
      }

      &__table {
        position: relative;
        &__header {
          .ex_id {
            width: 100%;
          }
          .completed {
            width: 55%;
          }
          .status {
            width: 40%;
          }
          .error-column{
            width: 40%;
          }

          .object_count {
            width: 40%;
          }
          .transferred_data_size {
            width: 60%;
          }
          .put_request_count {
            width: 50%;
          }
          .get_request_count {
            width: 45%;
          }
          .progress {
            width: 45%;
          }
          .start_time {
            width: 100%;
          }
          .end_time {
            width: 100%;
          }
        }
        &__body--disable {
          pointer-events: none;
          opacity: 0.3;
        }
        &__body {
          .m-tooltip-component .m-tooltip-component-content{
            padding-left: 9px;
          }
          &__no-item {
            padding: 10px;
            width: 100%;
            position: absolute;
            text-align: center;
            padding-top: 0px;
          }
          .m-tooltip-component{
            width: 22px;
            background-color: $orange5;
            color: white;
          }
          &__loader {
            position: absolute;
            top: 40%;
            left: 45%;
            height: 20px;
          }
          &__info {
            padding: 5px;
            border-radius: 3px;
            min-width: 85%;
            display: block;
            text-align: center;
            &__active {
              background-color: rgba(177, 236, 181, 0.36);
            }
            &__deactivate {
              background-color: rgb(236 177 180 / 36%);
            }
          }
        }
      }
    }
  }
}
