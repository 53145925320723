.m-filter-component {
  
  .m-filter-content {
    display: flex;

    .m-filter-selectbox {
      flex: 1;

      .m-filter-selectbox-title {
        color: $gray1;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 2px;
        text-transform: uppercase;
      }

      .m-filter-selectbox-content {
        
        .m-filter-date-picker-button {
          align-items: center;
          border: 1px solid $border-color;
          display: flex;
          font-size: 16px;
          font-weight: inherit;
          height: 33px;
          padding: 6px 12px;
          width: 100%;

          .m-filter-date-picker-button-content {
            display: flex;
            width: 100%;

            .m-filter-date-picker-button-text {
            }
  
            .m-filter-date-picker-button-icon {
              margin-left: auto;
            }
          }

          .m-filter-date-picker-button-not-selected-content {
            color: $gray5;
            display: flex;
            width: 100%;

            .m-filter-date-picker-button-text {
            }
  
            .m-filter-date-picker-button-icon {
              margin-left: auto;
            }
          }
        }
        
        .m-filter-date-picker-button:hover {
          border-color: $light-gray1;
          color: $text-color;

          .m-filter-date-picker-button-not-selected-content {
            color: $gray4;
          }
        }
      }
    }

    .m-filter-selectbox:not(:last-child) {
      margin-right: 16px;
    }
  }
}