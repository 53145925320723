.m-app-module {
  background-color: $light-gray5;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 50px;

  .crs-sidebar {
    padding-bottom: 25px;
    .crs-sidebar-group--active{
      a{
        padding-left: 13px!important;
      }
    }
    .crs-sidebar-group-child {
      padding-left: 0px !important;
      padding-right: 0px !important;
      a {
        padding-left: 16px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .m-app-top-notification {
    background-color: $red4;
    display: flex;
    padding: 16px 24px;

    .m-app-top-notification-text {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }

    .m-app-top-notification-button-container {
      margin-left: auto;

      .m-app-top-notification-button {
        background: inherit;
        border: none;
        color: #fff;
        height: inherit;
        padding: 0;
      }

      .m-app-top-notification-button:hover {
        text-decoration: underline;
      }
    }
  }

  .m-app-content-container {
    display: flex;
    height: inherit;

    .m-app-sidebar-container {
      height: inherit;

      .m-app-sidebar-workspace-box {
        display: flex;
        flex-direction: column;
        margin: 8px 0 36px 0;

        .m-app-sidebar-workspace-box-role {
          display: flex;
          justify-content: center;
          background-color: $border-color;
          border-top: 1px solid $gray5;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          padding: 6px 13px 6px 16px;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .m-app-sidebar-workspace-indicator-button {
        height: 40px;
        padding: 0 13px 0 16px;
        background-color: $border-color;
        border-color: $border-color;
        border-width: 4px 0 4px 0;
      }

      .m-app-sidebar-workspace-indicator {
        display: flex;
        align-items: center;

        .m-app-sidebar-workspace-indicator-icon {
          display: flex;
          align-items: center;
          color: $text-color;
        }

        .m-app-sidebar-workspace-indicator-workspace-icon {
          display: flex;
          align-items: center;
          height: 32px;
          width: 32px;
          margin-right: 8px;
        }

        .m-app-sidebar-workspace-indicator-title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: auto;
          font-weight: 400;
          max-width: calc(100% - 52px);
        }

        .m-app-sidebar-workspace-indicator-organization {
          font-size: 10px;
          letter-spacing: 1px;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .m-app-sidebar-workspace-indicator-workspace {
          font-size: 14px;
          letter-spacing: 0.5px;
          margin-left: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .m-app-sidebar-workspace-button {
        align-items: center;
        background: inherit;
        border: none;
        border-radius: 0;
        color: $gray1;
        cursor: pointer;
        display: flex;
        height: 36px;
        margin: 8px 0 24px 0;
        padding: 0 13px 0 16px;
        width: 100%;

        .m-app-sidebar-workspace-button-icon {
          align-items: center;
          color: $text-color;
          display: flex;
          margin-right: 12px;
        }

        .m-app-sidebar-workspace-button-text {
          align-items: center;
          display: flex;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.5px;
          margin-top: 1px;
        }
      }
    }

    .m-app-rest-container {
      display: flex;
      flex-direction: column;
      min-width: 824px;
      width: 100%;

      .m-app-rest-content-container {
        display: flex;
        justify-content: center;
        flex: 1;
        overflow-y: scroll;
        padding: 33px 0;
        padding-top: 20px;

        .m-app-rest-content {
          margin: 0 24px;
          min-width: 760px;
          width: 100%;
        }

        .m-app-content--page {
          height: 100%;
        }
      }
    }
  }
}
