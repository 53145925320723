.bucket-upload {
  padding-bottom: 30px;
  &__main {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-top: 15px;

    &__action {
      display: flex;
      padding-top: 15px;
      justify-content: flex-end;
      flex-direction: row;
      gap: 15px;
    }

    &__drop-zone {
      margin-bottom: 15px;
      cursor: pointer;
      width: 100%;
      height: 50px;
      border: 1px dashed #0073bb;
      text-align: center;
      .dropzone {
        height: 50px;
        padding: 15px;
      }
    }
    &__content {
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      gap: 15px;
      &__header {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
        background-color: #f6f8fa;
        padding: 15px;
        border: 1px solid #e1e8ed;
        &__action {
          display: flex;
          gap: 8px;
        }
        &__search {
          width: 100%;
          position: relative;
          padding-top: 10px;
          .m-form-text-field {
            margin-bottom: 0 !important;
          }
          &__icon {
            position: absolute;
            z-index: 1;
            margin-top: 4px;
            margin-left: 6px;
            color: #a3a3a3;
          }
          &__icon-clear {
            cursor: pointer;
            position: absolute;
            display: none !important;
            z-index: 9;
            color: #5c7080;
            margin: 14px;
            right: 0;
            top: 0;
            margin-right: 10px;
            &__show {
              display: block !important;
            }
          }
          input {
            padding-left: 35px !important;
          }
        }
      }

      &__files {
        &__table {
          .crs-table-cell {
            box-sizing: border-box;
            word-wrap: break-word;
          }
          .action {
            width: 5%;
          }
          .name {
            width: 40%;
          }
          .folder {
            width: 35%;
          }
          .type {
            width: 15%;
          }
          .size {
            width: 10%;
          }
          &__empty {
            width: 100%;
            padding: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}
