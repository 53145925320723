@import "pages/advanced/distributions.details.advanced.scss";
@import "pages/cacheInvalidation/distributions.details.cacheInvalidation.scss";
@import "pages/cachePrefetching/distributions.details.cachePrefetching.scss";
@import "pages/cnames/distributions.details.cnames.scss";
@import "pages/configuration/distributions.details.configuration.scss";
@import "pages/errorPages/distributions.detials.errorpages.scss";
@import "pages/general/distributions.details.general.scss";
@import "pages/origins/distributions.details.origins.scss";
@import "pages/pagerules/distributions.details.pagerules.scss";
@import "pages/redirection/distributions.details.redirection.scss";
@import "pages/restrictions/distributions.details.restrictions.scss";
@import "pages/waf/distributions.details.waf.scss";

.m-m-distributions-pages-details {
	.m-m-distributions-pages-details-content-container {
		display: flex;
		padding: 16px 0;

		.m-m-distributions-pages-details-sidebar {
			margin-right: 24px;
			min-width: 155px;
			.crs-page-sidebar-item-button-text {
				min-width: 163px;
			}
		}

		.m-m-distributions-pages-details-content {
			width: 100%;
			width: -moz-available; /* WebKit-based browsers will ignore this. */
			width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
			width: stretch;
		}
	}

	.m-m-distributions-pages-details-overview {
		border-bottom: 1px solid $border-color;
		border-top: 1px solid $border-color;
		border-radius: 0;
		margin: 16px 0;
		padding: 12px 0;

		.m-m-distributions-pages-details-overview-line {
			display: flex;

			.m-m-distributions-pages-details-overview-field {
				flex: 1 1 1px;
				box-sizing: border-box;
				padding-right: 16px;

				.m-m-distributions-pages-details-overview-field-title {
					color: $text-color;
					font-size: 12px;
					font-weight: 500;
					letter-spacing: 1px;
					text-transform: uppercase;
					width: max-content;
				}

				.m-m-distributions-pages-details-overview-field-value {
					color: $gray1;
					font-size: 16px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.m-m-distributions-pages-details-overview-field-value {
				color: $gray1;
				display: flex;
				font-size: 16px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.bp3-popover-target {
					display: flex;

					.m-m-distributions-pages-details-overview-field-value-utility-button {
						background: inherit;
						border: none;
						font-size: 0;
						height: inherit;
						margin-left: 4px;
						padding: 4px;
					}

					.m-m-distributions-pages-details-overview-field-value-utility-button:hover {
						background-color: $light-gray1;
					}
				}
			}

			.m-m-distributions-pages-details-overview-field:last-child {
				flex-grow: 0;
				padding-right: 0;
			}
		}
	}
}
