.databable-filter {
    width: 725px;
    background: white;
    z-index: 1;
    border: 2px solid #e1e8ee;
    border-radius: 3px;
    &__header {
        display: flex;
        justify-content: space-between;
        padding: 5px;
        text-align: right;
        padding-left: 15px;
        background-color: #f6f8fa;
        border-bottom: 1px solid #e9eced;
        &__title {
            font-weight: 500;
            font-size: 14px;
            padding-top: 3px;
        }
        &__action {
            display: flex;
            gap: 5px;
            &__button {
                cursor: pointer;
                border: 1px solid #cad6d1;
                height: 26px !important;
                padding: 3px 9px !important;
                font-size: 13px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                &__primary {
                    background-color: $blue4!important;
                    border-color: $blue4!important;
                    &:hover {
                        background-color: $blue5!important;
                        border-color: $blue5!important;
                    }
                }
                &__clear {
                    margin-top: 4px;
                }
            }
        }
    }
    &__head {
        display: flex;
        font-size: 15px;
        font-weight: 500;
        padding: 6px;
        background-color: #f6f8fa;
        border-bottom: 1px solid #eaeced;
        color: #a7b6c2;
        &__key {
            width: 250px;
            padding-left: 29px;
            border-right: 1px solid #d9d9d9;
            text-align: center;
        }
        &__operator {
            width: 132px;
            padding-left: 10px;
            border-right: 1px solid #d9d9d9;
            text-align: center;
        }
        &__value {
            width: 318px;
            text-align: center;
            padding-left: 10px;
        }
    }
    &__content {
        padding: 14px;
        max-height: 270px;
        overflow-x: hidden;
        min-height: 330px;
        &__empty {
            width: 100%;
            margin-top: 15%;
            font-size: 15px;
            height: 20px;
            text-align: center;
        }

        &__box {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            gap: 15px;
            padding-bottom: 3px;
            padding-top: 3px;
            border-bottom: 1px solid #f0f0f0;
            &:last-child {
                border: none;
            }
            &__item {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                position: relative;
                .m-form-text-input-error {
                    .m-form-text-error {
                        position: absolute;
                        top: 5px;
                        right: 9px;
                    }
                }

                &__selectbox-full {
                    button {
                        width: 210px !important;
                    }
                }

                &__selectbox-min {
                    button {
                        width: 120px;
                    }
                }

                &__delete {
                    width: 7px;
                    right: 8px;
                    cursor: pointer;
                    &:hover {
                        color: #d13212;
                    }
                }
                .m-checkbox-component-label-checked {
                    margin-right: 0px !important;
                }
                .m-form-text-input-component {
                    margin-bottom: 0 !important;
                }
                .m-checkbox-component {
                    height: 18px;
                }

                .picker {
                    input[type="text"]:read-only {
                    }
                }

                .m-form-text-input-component {
                    .m-form-text-input {
                        min-height: 29px !important;
                        padding: 4px 12px !important;
                        margin-top: 1px;
                        height: 29px;
                        width: 300px;
                    }
                }
            }
        }
    }
}

.filter__date-picker {
    &__error-field {
        input {
            border-color: #f55656 !important;
        }
    }

    &__error {
        -webkit-align-items: center;
        align-items: center;
        color: #f55656;
        display: -webkit-flex;
        display: flex;
        font-size: 12px;
        margin-top: 2px;
        &__text {
            position: absolute;
            top: 7px;
            right: 9px;
        }
    }
    input {
        border: 1px solid #e1e8ed;
        width: 300px !important;
        box-shadow: none;
        &:focus {
            box-shadow: none;
        }
    }
    .bp3-active {
        color: white !important;
    }
}
