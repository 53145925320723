.m-workspace-transfer-details-main-content {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .m-section-component {
    width: 100%;
  }

  .m-info-box-component {
    margin: 12px 0;
  }

  .m-section-component--bordered {
    border: 1px solid #ced9e0;
    border-radius: 3px;
    padding: 10px 15px;
  }

  .m-workspace-transfer-details-chain-link {
    padding: 0 64px;
  }

  .m-workspace-transfer-details-main-chain-info-box {
    display: flex;
    font-size: 15px;
    padding: 6px 0;

    .m-workspace-transfer-details-main-chain-info-text {
      margin-left: 10px;
    }

    .m-workspace-transfer-details-main-chain-info-key {
      font-weight: 500;
      margin-right: 4px;
    }
  }

  .m-workspace-transfer-details-main-chain-info-icon {
    color: $gray3;
    display: flex;
    align-items: center;
  }

  .m-workspace-transfer-details-main-chain-info--error {
    color: $red5;
  }

  .m-workspace-transfer-details-main-chain-info--warning {
    color: $orange5;
  }

  .m-workspace-transfer-details-main-chain-info--success {
    color: $green5;
  }

  .m-section-content-component {
    padding-left: 0;
  }
}

.m-workspace-transfer-details-form-divider {
  background-color: $border-color;
  height: 1px;
  margin: 24px 0;
}

.m-workspace-transfer-prepare-dialog-description-text {
  margin-bottom: 10px;
}

.m-workspace-transfer-prepare-dialog-description-warning {
  color: $orange4;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 5px;

  .m-bucket-settings-prepare-dialog-description-warning-icon-container {
    margin-right: 15px;
  }
}

.m-workspace-transfer-prepare-dialog-description-info {
  color: $gray1 ;
  display: flex;
  flex-direction: row;

  .m-bucket-settings-prepare-dialog-description-info-icon-container {
    margin-right: 15px;
  }
}
