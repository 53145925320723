
    .m-profile-main-security-settings-tab-box-no-key {
      width: $profile-tab-width;
      .m-profile-main-security-settings-tab-box-no-key-button-container {
        margin-top: 12px;
      }
    }
  .m-profile-main-security-settings-tab-box-key-existing {
    width: $profile-tab-width;

    .m-profile-main-security-settings-tab-box-key-existing-spinner-page-container {
      height: 57px;
    }
    .m-profile-main-security-settings-tab-box-key-existing-button-container {
      margin-top: 12px;
    }
  }