.crs-table-column {
  color: $gray4;
  padding: 4px 6px 3px 6px;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;

  .crs-table-column-button {
    background: none;
    border: none;
    color: $gray4;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 12px;
    font-weight: 500;
    height: inherit;
    padding: 0;
    text-transform: uppercase;
    width: min-content;

    .crs-table-column-order-icon-unsorted {
      visibility: hidden;
    }
  }
}

.crs-table-column:first-child {
  padding-left: 16px;
}

.crs-table-column:last-child {
  padding-right: 16px;
}

.crs-table-column--sortable:hover .crs-table-column-button {
  color: $brand-color-contrast;
  font-weight: 500;
}

.crs-table-column--sorted .crs-table-column-button {
  color: $dark-gray5;
  font-weight: 500;
}

th {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selection {
  width: 5%;
}

.crs-table .name {
  width: 25%;
}

.crs-table .cname {
  width: 20%;
}

.crs-table .domain-name {
  width: 25%;
}

.crs-table .created {
  width: 15%;
}

.crs-table .options {
  width: 5%;
}