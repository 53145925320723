.m-create-bucket-form {
  .m-form-text-field {
    .m-form-text-field-label {
      flex-direction: column;

      .m-form-text-field-left {
        margin-bottom: 0px;
        width: 100%;
      }

      .m-form-text-field-right {
        margin-left: unset;
        width: 100%;
      }
    }
  }

  .m-estimated-cost-container {
    display: flex;
    flex-direction: row;
    max-width: 600px;
    border: 1px solid $light-gray1;
    border-radius: 3px;
    margin-bottom: 20px;
  
    .m-estimated-cost-left {
      width: 70%;
      padding: 16px;
  
      .m-estimated-cost-left-title-container {
        display: flex;
        margin-bottom: 20px;
  
        .m-estimated-cost-left-title-icon-container {
          color: $violet2;
        }
        .m-estimated-cost-left-title {
          font-size: 16px;
          padding: 0 10px;
        }
      }
  
      .m-estimated-cost-left-content {
        .m-estimated-cost-left-content-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 55px;
          border-bottom: 1px solid $light-gray1;

          .m-estimated-cost-left-content-row-title {
            display: flex;
            align-items: center;

            .m-estimated-cost-left-content-row-title-detail {
              margin-left: 8px;
              font-style: italic;
              color: $violet2;
            }

            .m-generic-form-policy-tooltip {
              margin-left: 8px;

              .crs-tooltip-component-content {
                padding: 2px 4px;
              }
            }
          }
  
          .m-estimated-cost-left-content-row-region {
            font-weight: 500;
          }
  
          .m-estimated-cost-left-content-row-visibility {
            display: flex;
            align-items: center;
            
            .m-estimated-cost-left-lock-icon-private {
              color: $red3;
              padding: 0 5px;
            }
  
            .m-estimated-cost-left-lock-icon-public {
              color: $green3;
              padding: 0 5px;
            }
          }

          .m-estimated-cost-left-content-row-storage {
            display: flex;
            align-items: center;

            .m-estimated-cost-left-content-row-storage-input {
              min-height: 33px;
              display: flex;
              align-items: center;
              border: 1px solid $light-gray3;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
              padding: 5px;
              color: $gray2;
            }

            .m-form-text-field {
              max-width: 100px;
              margin-bottom: 0px;

              .m-form-text-field-right {
                .m-form-text-field-right-input {
                  input {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    padding: 6px;
                  }
                }
              }
            }
          }

          .m-estimated-cost-left-content-row-request__amount {
            display: flex;
            align-items: center;

            .m-estimated-cost-left-content-row-request__amount-input {
              min-height: 33px;
              display: flex;
              align-items: center;
              border: 1px solid $light-gray3;
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
              padding: 5px;
              color: $gray2;
            }

            .m-form-text-field {
              max-width: 100px;
              margin-bottom: 0px;

              .m-form-text-field-right {
                .m-form-text-field-right-input {
                  input {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    padding: 6px;
                  }
                }
              }
            }
          }
        }
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  
    .m-estimated-cost-right {
      width: 30%;
      padding: 16px;
      background-color: $light-gray4;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;

      .m-estimated-cost-right-title-container {
        display: flex;
        align-items: center;
        margin-bottom: 7px;

        .m-form-text-field {
          max-width: 50px;
          margin-bottom: 0px;

          .m-form-text-field-right {
            .m-form-text-field-right-input {
              input {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding: 6px;
              }
            }
          }
        }

        .m-selectbox-component {
          .bp3-popover-wrapper {
            .bp3-popover-target {
              .m-selectbox-button-container {
                width: 100px;
              }
            }
          }
        }
      }

      .m-estimated-cost-right-content {
        .m-estimated-cost-right-content-row:not(:last-child) {
          border-bottom: 1px solid $light-gray1;
        }

        .m-estimated-cost-right-content-row {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 55px;
          color: $gray3;

          .m-estimated-cost-preview-container {
            display: flex;
            flex-direction: column;

            .m-estimated-cost-preview-cost {
              display: flex;
              justify-content: flex-end;
              font-size: 16px;
              color: $violet2;
            }

            .m-estimated-cost-preview-rate {
              display: flex;
              justify-content: flex-end;
              color: $gray2;
            }
          }
        }
      }
    }
  }
}