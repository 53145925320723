.dialog-mini {
  border: none;
  background-color: transparent;
  box-shadow: none !important;
  &__container {
    width: 736px !important;
    border-radius: 3px;
    background-color: white;
    &__footer {
      position: relative;
      display: block;
      &__button {
        padding: 10px;
        text-align: right;
        padding-top: 3px;
        &__close {
        }
      }
    }
    &__content {
      padding: 5px;
    }
  }
}
.bp3-overlay-backdrop {
  background-color: rgb(16 22 26 / 39%);
}
