.m-error-page-component {
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  padding: 72px;
  width: inherit;

  .m-error-page-icon {
    color: $border-color;
  }

  .m-error-page-title {
    font-size: 32px;
  }

  .m-error-page-description {
    color: $gray3;
    font-size: 16px;

    .m-error-page-link {
      border: none;
      color: $gray3;
      font-size: 16px;
      font-weight: 400;
      margin-left: 4px;
      padding: 0;
    }

    .m-error-page-link:hover {
      color: $cobalt3;
      text-decoration: underline;
    }
  }
}