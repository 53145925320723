.m-tag-input-field {
	&-component {
		box-shadow: none !important;
		border: 1px solid $border-color;
		min-height: 33px;

		input {
			color: $gray1;
			font-family: 'Roboto Flex', sans-serif;
			font-size: 16px;
		}
	}

	&-clear-button {
		border: none;
		align-self: stretch;
		height: initial;

		&:hover {
			background-color: $gray3;
			color: white;
		}

		&:active {
			background-color: $gray2;
			color: white;
		}
	}

	&-error {
    align-items: center;
    color: $red4;
    display: flex;
    font-size: 12px;
    margin-top: 2px;
	}
}