.m-m-buckets-pages-settings {
  height: 100%;
  .m-m-buckets-pages-settings-info {
    padding: 20px 0;

    .m-buckets-main-info-boxes {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      width: 100%;

      .m-info-box-component {
        margin-right: 16px;
        margin-bottom: 16px;
        flex: 1 0 30%;
      }
    }
    .button__full__weight{
      width: 185!important;
    }
  }

  .m-m-buckets-pages-settings-tags {
    padding: 20px 0;
    margin-bottom: 20px;

    .m-m-buckets-pages-settings-tags-input {
      margin-top: 16px;
      margin-right: 16px;
      .bp3-input {
        box-shadow: none !important;
        border: 1px solid #e1e8ed !important;
        min-height: 33px !important;
        .m-tag-input-field-clear-button {
          border: none !important;
        }
      }
    }
  }

  .m-m-buckets-pages-settings-buttons {
    padding-bottom: 40px;

    .m-buckets-buttons-line {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 16px 0;

      .m-buckets-buttons-line-right{
        min-width: 180px;
        text-align: right;
      }


      .m-buckets-buttons-line-title {
        font-size: 16px;
        font-weight: 500;
      }

      .m-buckets-buttons-line-description {
        color: $gray3;
        font-size: 14px;
        padding-right: 25px;
      }
    }

    .m-buckets-buttons-line-button {
      margin-right: 16px;
    }
  }
}

.m-bucket-settings-delete-dialog-description-text {
  margin-bottom: 10px;
}

.m-bucket-settings-delete-dialog-description-warning {
  color: $red3;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .m-bucket-settings-delete-dialog-description-warning-icon-container {
    margin-right: 15px;
  }
}

.m-bucket-main-setting-website-dialog {
  .m-bucket-main-setting-website-dialog-button-cancel {
    margin-right: 10px;
  }
  .m-bucket-main-setting-website-dialog-description {
    .bp3-form-group {
      flex-wrap: nowrap;
      justify-content: space-around;
      flex-direction: row;
      .row {
        width: 250px;
      }
    }
    .bp3-text-muted {
      color: #5c7080;
      font-weight: bold;
      font-size: 14px;
    }
    .m-bucket-main-setting-website-dialog-description-text {
      padding-bottom: 15px;
    }
    .crs-switch {
      float: right !important;
    }
    .bp3-card {
      padding-bottom: 15px;
    }
    padding-bottom: 15px;
  }

  .m-bucket-main-setting-website-dialog-buttons {
    float: right;
  }
}

.m-buckets-buttons-website-detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15%;
  .m-buckets-buttons-website-detail-box {
    .m-buckets-buttons-website-detail-box-title {
      font-weight: 500;
      font-size: 15px;
      padding-bottom: 10px;
    }
    .m-buckets-buttons-website-detail-box-content {
    }
  }
}

.m-bucket-main-setting-policy-dialog {
  .m-bucket-main-setting-policy-dialog-button-cancel {
    margin-right: 10px;
  }
  textarea {
    width: 100%;
    height: 300px;
  }

  .m-bucket-main-setting-policy-dialog-description {
    margin-bottom: 15px;
  }

  .m-bucket-main-setting-policy-dialog-buttons {
    float: right;
  }

  .m-bucket-main-setting-policy-dialog-buttons-left {
    display: flex;
    float: left;
    gap: 10px;
    flex-direction: row;
  }


  .bp3-form-group {
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;
    .row {
      width: 250px;
    }
  }
}

.m-bucket-main-setting-policy-content {
  .m-basic-dialog-container{
    width: 750px !important;
  }
}
