.m-selectbox-component {
  .bp3-popover-wrapper {
    display: block;
    position: relative; // Necessary; otherwise 100% width applies to the viewpoint

    .bp3-popover-target {
      width: 100%;
      width: stretch;

      .m-selectbox-button-container {
        width: 100%;
        width: stretch;

        .m-selectbox-button {
          align-items: center;
          border: 1px solid $border-color;
          color: $gray1;
          display: flex;
          font-size: 16px;
          font-weight: 400;
          height: inherit;
          width: inherit;
          padding: 6px 12px;
      
          .m-selectbox-loading-spinner {
            margin-left: auto;
          }
      
          .m-selectbox-button-icon {
            margin-left: auto;
          }
      
          .m-selectbox-button-label {
            color: $gray5;
            margin-left: 8px;
          }
        }
      }

      .m-selectbox-button:hover:not(:disabled) {
        border-color: $light-gray1;
        color: $text-color;
      }

      .m-selectbox-button:disabled {
        color: $gray5;
      }
    }

    .bp3-transition-container {
      width: 100%;
      
      .bp3-select-popover {
        width: 100%;
        min-width: 300px;
        max-width: 550px; // Can be adjusted on wish

        .m-selectbox-filter-input {
          margin-bottom: -5px;
          
          input {
            border: none;
            box-shadow: none !important;
            color: $gray1;
          }
        
          ::placeholder {
            text-transform: capitalize;
          }
        }
        
        .bp3-menu {
          color: $gray1 !important;
          font-family: 'Roboto Flex', sans-serif !important;
          font-size: 16px !important;
          max-height: 300px;
          overflow: auto;
          
          .m-selectbox-option {
            font-size: 16px;
            margin: 2px 0;
            padding: 6px 12px;
          }
          
          .m-selectbox-option--selected:hover {
            background-color: $green5;
            color: #fff !important;
          }

          .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss.m-selectbox-option {
            color: #fff !important;
          }

          .bp3-menu-item {
            .bp3-menu-item-label {
              flex-shrink: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
            }

            .bp3-fill {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }

  .m-selectbox-errors {
    align-items: center;
    display: flex;

    .m-selectbox-errors-icon {
      color: $red5;
      margin-bottom: 2px;
    }

    .m-selectbox-errors-content {
      margin-left: 4px;

      .m-selectbox-error {
        color: $red5;
      }
    }
  }

  // Not in .bp3-menu with other options specifically to not overwrite the .bp3-active class
  .m-selectbox-option--selected {
    background-color: $green5;
    color: #fff !important;
    font-size: 16px;
    margin: 2px 0;
    padding: 6px 12px;
  
    .bp3-menu-item-label {
      color: #fff !important;
    }
  }
}
