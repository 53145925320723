.m-forgot-password-module {
  .m-forgot-password-head {
    margin-bottom: 64px;

    .m-forgot-password-page-brand-logo {
      width: 300px;
    }
  }

  .m-forgot-password-content {
    display: flex;
    flex-direction: column;
    height: 400px;
    .m-forgot-password-content-right-captcha { 
      width: 400px;
      visibility: hidden;

        .m-forgot-password-content-right-captcha-link-button {
          font-size: 11px;
        }
        .m-forgot-password-content-right-captcha-section-text{
          font-size: 11px;
        }
      }
  }
}