.bp3-dialog {
  color: $text-color !important;
  font-family: 'Roboto Flex', sans-serif !important;
  padding: 0 !important;
  width: max-content !important;
}

.m-notification-component {
  background-color: #fff;
  border-radius: 3px;
  color: $dark-gray5;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Flex', sans-serif;
  padding: 24px;
  width: 400px;
}

.m-notification-icon-component {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  .--success {
    color: $green4;
  }

  .--error {
    color: $red4;
  }
}

.m-notification-head-component {
  display: flex;
  font-size: 24px;
  font-weight: 500;
  justify-content: center;
  margin-bottom: 16px;
}

.m-notification-error-detail-component {
  font-weight: 500;
  margin: 12px 0;
  padding: 0 16px;

  .m-notification-error {

    .m-notification-error-title {
      color: $gray1;
      font-size: 12px;
      text-transform: uppercase;
    }

    .m-notification-error-list {
      margin: 0;
      padding-left: 16px;
    }
  }
}

.m-notification-button-component {
  margin-top: 12px;

  .m-notification-button {
    width: 352px;
  }

  .m-notification-button-2x {
    width: 170px;
  }

  .m-notification-button-2x:not(:last-child) {
    margin-right: 12px;
  }
}
