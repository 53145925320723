.server-detail {
    &__main {
        &__container {
            padding: 10px;
            &__item {
                &__content {
                    &__table {
                        height: 100%;
                        max-height: 405px;
                        display: block;
                        overflow-x: hidden;
                        &__title {
                            font-weight: 400;
                            padding: 10px 0 10px 4px !important;
                            color: #2c95d6;
                        }
                        &__link {
                            text-decoration: underline !important;
                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                        border: 1px solid #e1e8ed;
                        tbody {
                            tr:nth-child(even) {
                                background-color: #f6f8fa;
                            }
                            tr {
                                td {
                                    overflow: hidden;
                                    padding: 4px;
                                    &:first-child {
                                        min-width: 205px !important;
                                        width: 205px !important;
                                    }
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.detail-dialog {
    position: relative;
    &__info {
        display: block;
        float: right;
        top: 3px;
        position: absolute;
        right: 65px;
        &__table {
            tbody {
                tr {
                    td {
                        font-size: 13px;
                        overflow: hidden;
                        padding: 2px;
                        &:first-child {
                            min-width: 85px !important;
                            width: 85px !important;
                        }
                        width: 100%;
                    }
                }
            }
        }
    }
    .m-basic-dialog-head-title {
        font-size: 17px !important;
    }
    .m-basic-dialog-container {
        width: 1130px !important;
        height: 600px;
        padding: 15px !important;
    }
    .m-basic-dialog-content {
        padding-top: 0px !important;
    }
}
