@import 'lists/waf.main.lists';
@import 'policies/waf.main.policies';

.m-waf-module-head-description-link {
	color: $blue4 !important;

	&:hover {
		color: $blue2 !important;
	}
}
