.m-status-circle-component {
  align-items: center;
  display: flex;
  height: 14px;
  justify-content: center;
  margin-top: 6px;
  width: 14px;

  .m-status-circle-content {
    border-radius: 10px;
    height: 10px;
    width: 10px;
  }

  .--active {
    background-color: $green5;
  }

  .--default {
    border: 2px solid $gray5;
  }

  .--disabled {
    background-color: $gray5;
  }
  
  .--error {
    background-color: $red5;
  }
  
  .--processing {
    background-color: $gold5;
  }
  
  .--suspended {
    background-color: $dark-gray5;
  }
}