.m-release-notes-module {
  align-items: center;
  background-image: url('~common/assets/background-8.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-width: 1024px;
  padding: 100px 0;

  .m-release-notes-logo {
    width: 300px;
  }

  .m-release-notes-content {
    background-color: #fff;
    border-radius: 8px;
    margin: 24px;
    max-height: 90%;
    min-width: 976px;
    max-width: 80%;
    padding: 24px;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;

    .m-release-notes-head {
      margin-bottom: 24px;

      .m-release-notes-title {
        font-size: 32px;
        font-weight: 500;
      }

      .m-release-notes-description {
        color: $gray5;
        font-size: 16px;
      }
    }

    .m-release-notes-versions {
      max-height: calc(100% - 120px);
      padding: 0 16px;
      overflow-y: scroll;

      h1 {
        margin-bottom: 0;
      }

      h1:first-child {
        margin-top: 0;
      }

      ul {
        margin-bottom: 1em;
      
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}