.m-distributions-cnames-main {

  .m-distributions-cnames-table {
    margin: 16px 0 48px 0;

    .cname {
      width: 50%;
    }

    .ssl {
      width: 25%;
    }
    
    .updated-at {
      width: 15%;
    }
    .m-distributions-cnames-table-cell-cname {
      .bp3-popover-target > span {
        align-items: center;
        display: inline-flex;
      }

      .m-distributions-cnames-table-cell-cname-default {
        background-color: $cobalt4;
        border: 1px solid $blue3;
        border-radius: 3px;
        color: #fff;
        margin-left: 8px;
        padding: 4px 6px 3px 6px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        width: min-content;
      }
    }

    .m-distributions-cnames-table-row-ssl-cell {
      align-items: center;
      display: flex;
      
      .m-distributions-cnames-table-row-ssl-cell-status {
        display: flex;
        margin-right: 8px;
        padding-top: 1px;
      
        .m-distributions-cnames-table-row-ssl-cell-status-icon--success {
          color: $green4;
        }
      
        .m-distributions-cnames-table-row-ssl-cell-status-text--success {
          color: $green4;
          margin-left: 3px;
          margin-top: 1px;
        }
      }

      .m-distributions-cnames-table-row-ssl-cell-update-button {
        color: $gray5;
        border: none;
        font-weight: 400;
        height: inherit;
        padding: 2px;
      }

      .m-distributions-cnames-table-row-ssl-cell-update-button:hover {
        background-color: $light-gray3;
        color: $gray3;
      }

      .m-distributions-cnames-table-row-ssl-cell-delete-button {
        border: none;
        color: $gray5;
        font-weight: 400;
        height: inherit;
        padding: 2px;
      }

      .m-distributions-cnames-table-row-ssl-cell-delete-button:hover {
        background-color: $light-gray3;
        color: $gray3;
      }
    }
  }
}

.m-distributions-cnames-main-callout{
  margin-top: 15px;

}
.ssl-dialog{
  .m-basic-dialog-container {
    width: 800px !important;
  }
}