/* From time to time, we display items' ids next to some other fields.
 * This rule is used for those table cells.
 */
.m-table-cell-with-id {
  display: flex;

  .m-table-cell-with-id-id {
    color: $light-gray1;
    font-weight: 500;
    margin-left: 4px;
  }
}

/* SECTION RULES */

.m-section-title-uppercase {
  color: $text-color;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}