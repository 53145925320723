.m-distributions-general {
  
  .m-distributions-general-supported-http-versions-container {
    display: flex;
  }

  .m-distributions-general-pricing-zones-container {
    display: flex;
    flex-wrap: wrap;
  }
}