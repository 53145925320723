.m-distributions-cache-invalidation-main {
  .dynamic-table {
    padding-top: 10px;
  }
  .m-distributions-cache-invalidation-table-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .m-distributions-cache-invalidation-table-head-create-button {
      width: max-content;
    }

    .m-distributions-cache-invalidation-table-head-search {
      height: 30px;
      margin-left: 16px;
      width: 256px;
    }
  }

  .m-distributions-cache-invalidation-table-head-link {
    display: inline-block;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    .crs-page-head-right {
      width: min-content !important;

      .m-distributions-cache-invalidation-table-head-create-button {
        margin-bottom: 12px;
      }
    }
  }
  .datatable {
    .m-distributions-cache-invalidation-table-status-cell {
      align-items: center;
      display: flex;

      .m-distributions-cache-invalidation-table-status-cell-icon--processing {
        color: $gray4;
        width: min-content;
      }

      .m-distributions-cache-invalidation-table-status-cell-icon--success {
        color: $green4;
        width: min-content;
      }

      .m-distributions-cache-invalidation-table-status-cell-text--processing {
        color: $gray4;
        font-size: 12px;
        font-weight: 500;
        margin-right: 4px;
        text-transform: uppercase;
        padding-top: 5px;
      }

      .m-distributions-cache-invalidation-table-status-cell-text--success {
        color: $green4;
        font-size: 12px;
        font-weight: 500;
        margin-right: 4px;
        text-transform: uppercase;
        padding-top: 5px;
      }
    }
  }
}

$table-header: #262f3870;
$table-header-border: #434343a1;
$table-border: #9999990d;
$row-bg: #4242421c;

.tooltip-table-container {
  display: block;
  width: 100%;
  max-width: 300px;

  .flex-table {
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px $table-border;
    border-bottom: solid 1px #9999990d;
    align-items: center;
    transition: 0.5s;
    font-size: 12px;
    &:first-of-type {
      border-top: solid 1px $table-header-border;
      border-left: solid 1px $table-header-border;
    }
    &:first-of-type .flex-row {
      background: $table-header;
      color: white;
      border-color: $table-header-border;
      padding: 3px;
      padding-left: 13px;
      text-align: left;
    }
    &:nth-child(odd) {
      background: $row-bg;
    }
    &:hover {
      background: #f5f5f5;
      transition: 500ms;
    }
  }

  .flex-row {
    width: calc(100% / 2);
    text-align: left;
    padding: 3px;
    border-right: solid 1px $table-border;
    padding-left: 13px;
    min-width: 70px;
  }

  .rowspan {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
  }

  .column {
    display: flex;
    flex-flow: column wrap;
    width: 75%;
    padding: 0;
    .flex-row {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      padding: 0;
      border: 0;
      border-bottom: solid 1px $table-border;
      &:hover {
        background: #f5f5f5;
        transition: 500ms;
      }
    }
  }

  .flex-cell {
    width: calc(100% / 3); //1px = border right
    text-align: center;
    padding: 0.5em 0.5em;
    border-right: solid 1px $table-border;
    //flex: 1 1 33.3%;
    &:last-child {
      // border-right: 0;
    }
  }
}
