.m-tooltip-component {
  background-color: $light-gray1;
  border-radius: 3px;
  color: $gray1;
  font-size: 16px;
  line-height: 16px;

  .m-tooltip-component-content {
    font-weight: 500;
    padding: 4px 6px;
  }
}

.m-tooltip-component:hover {
  background-color: $brand-color-contrast;
  color: #fff;
}

.m-tooltip-component-portal {
  
  .bp3-tooltip {

    .bp3-popover-content {
      background-color: $text-color !important;
      padding: 6px 8px !important;
      text-align: center !important;
      max-width: 200px;
      word-wrap: break-word !important;
    }

    .bp3-popover-arrow-fill {
      fill: $text-color !important;
    }
  }
}