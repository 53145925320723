.m-tabs-component {
  margin: 16px 0;
  
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 2px solid $light-gray4;
    height: 36px;
  }

  li {
    height: 36px;
    padding: 0;
    margin: 0;

    .m-tabs-item-button {
      border: none;
      background-color: inherit;
      border-bottom: 2px solid $light-gray4;
      border-radius: 0;
      color: $light-gray1;
      font-weight: 500;
      height: 36px;
      padding: 0;
    }

    .m-tabs-item-button:hover {
      border-bottom: 2px solid $brand-color-contrast-hover;
      color: $brand-color-contrast;
    }

    .m-tabs-item-button--selected {
      border-bottom: 2px solid $blue4;
      color: $blue4;
    }

    .m-tabs-item-button--selected:hover {
      border-bottom: 2px solid $blue4;
      color: $blue4;
    }
  }

  li:not(:first-child) {
    margin-left: 12px;
  }

  li:not(:last-child) {
    margin-right: 12px;
  }
}