.m-not-found-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .m-nf-content {
    display: flex;
    padding-bottom: 200px;

    .m-nf-content-status-code {
      color: $light-gray3;
      font-size: 128px;
      font-weight: 500;
      line-height: 96px;
      margin-right: 16px;
    }

    .m-nf-content-error-message {
      color: $gray1;
      font-size: 32px;
      line-height: 32px;
      margin-top: auto;
    }
  }
}