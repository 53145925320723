.m-waf-main-lists-component {
  .m-waf-main-lists-section-title-buttons {
    display: flex;
    .m-waf-main-lists-section-title-button {
      margin-left: 10px;
    }
  }
  .m-waf-main-lists-table {
    margin-top: 16px;

    .length {
      width: 10%;
    }
    
    .updated,
    .type {
      width: 20%;
    }

    .name {
      width: initial;
    }
  }

  .m-waf-main-lists-table-no-items {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }

  .m-waf-main-lists-section-title {
    display: flex;
    justify-content: space-between;

    .m-waf-main-lists-section-title-text {
      font-size: 20px;
      font-weight: 500;
    }
  }
}