/* Text */

.text--xs {
  font-size: 10px;
}

.text--s {
  font-size: 12px;
}

.text--sm {
  font-size: 14px;
}

.text--m {
  font-size: 16px;
}

.text--ml {
  font-size: 18px;
}

.text--l {
  font-size: 24px;
}

.text--xl {
  font-size: 32px;
}

.text--xxl {
  font-size: 48px;
}

/* Box Shadow */

.box-shadow--0 {
  box-shadow: 0 0 0 1px rgba(16,22,26,.05),0 0 0 rgba(16,22,26,0),0 1px 1px rgba(16,22,26,.1);
}

.box-shadow--1 {
  box-shadow: 0 0 0 1px rgba(16,22,26,.1),0 0 0 rgba(16,22,26,0),0 1px 1px rgba(16,22,26,.2);
}

.box-shadow--2 {
  box-shadow: 0 0 0 1px rgba(16,22,26,.1),0 1px 1px rgba(16,22,26,.2),0 2px 6px rgba(16,22,26,.2);
}

.box-shadow--3 {
  box-shadow: 5px 5px 24px 0 rgba(0,0,0,0.3);
}

$box-shadow1: 0 0 0 1px rgba(16,22,26,.05),0 0 0 rgba(16,22,26,0),0 1px 1px rgba(16,22,26,.1);

$text-color: $dark-gray5;
$description-color: $gray3;
$description-color-light: $light-gray1;

$border-color: $light-gray3;
$border-color-dark: $light-gray1;
$border-color-light: $light-gray5;
$background-color-light: $light-gray5;

$brand-color: #3C1B6D;
$brand-color-hover: #47237D;
$brand-color-light: #9E8DB6;
$brand-color-light-hover: rgb(176, 157, 204);
$brand-color-light-border: #796E88;
$brand-color-contrast: #FF9424;
$brand-color-contrast-hover: #FFA950;