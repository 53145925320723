.m-mini-sidebar {
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {

    .m-mini-sidebar-item-button {
      border: none;
      background-color: inherit;
      color: $light-gray1;
      font-weight: 400;
      height: inherit;
      padding: 0;
      text-align: left;
      
      .m-mini-sidebar-item-button-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 136px;
      }
    }

    .m-mini-sidebar-item-button:hover {
      color: $brand-color-contrast;
    }

    .m-mini-sidebar-item-button--selected {
      color: $blue4;
      font-weight: 500;
    }

    .m-mini-sidebar-item-button--selected:hover {
      color: $blue4;
    }
  }

  li:not(:last-child) {
    margin-bottom: 6px;
  }
}