.m-basic-dialog-component.m-date-range-picker-dialog-component {
  .m-basic-dialog-container {
    width: min-content !important;
    
    .m-basic-dialog-content {
      min-width: 600px;
  
      .m-date-range-picker-dialog-content {
        display: flex;
        margin: 0;
        width: min-content;

        .m-date-range-picker-dialog-shortcuts {
          border-right: 1px solid $border-color;
          display: flex;
          flex-direction: column;
          padding-right: 16px;
          width: 220px;

          .m-date-range-picker-dialog-shortcut-button {
            align-items: center;
            border: none;
            border-radius: 2px;
            color: $text-color;
            display: flex;
            font-size: 16px;
            font-weight: inherit;
            margin: 0 -6px;
            padding: 0 6px;
            width: 100%;
          }

          .m-date-range-picker-dialog-shortcut-button:hover {
            background-color: $brand-color-contrast;
            color: #fff;
          }

          .m-date-range-picker-dialog-shortcut-button--selected {
            background-color: $cobalt4;
            color: #fff;
          }

          .m-date-range-picker-dialog-shortcut-button--selected:hover {
            background-color: $cobalt4;
            color: #fff;
            cursor: default;
          }
        }

        .m-date-range-picker-dialog-picker {
    
          .m-date-range-picker-dialog-component {
            color: $text-color;
            width: max-content;
      
            .bp3-menu {
              padding-right: 6px;
            }
      
            .DayPicker-Caption {
              select {
                color: inherit;
              }
            }
      
            .DayPicker-Day:hover {
              background-color: $gray3;
            }
        
            .DayPicker-Day--selected-range-start {
              background-color: $cobalt4;
            }
        
            .DayPicker-Day--selected-range-end {
              background-color: $green4;
            }
        
            .DayPicker-Day--selected-range {
              background-color: $light-gray2;
            }

            .DayPicker-Weekday {
              font-size: 12px;
              font-weight: 500;
              text-transform: uppercase;
            }
      
            .bp3-timepicker {
              margin: 0;
        
              .bp3-timepicker-arrow-row {
                font-size: 4px;
                
                .bp3-timepicker-arrow-button {
                  padding: 0;
                }
              }
            }
          }

          .m-date-range-picker-dialog-dates-row {
            display: flex;
            
            .m-date-range-picker-dialog-dates {
              justify-content: flex-end;
              min-width: min-content;
              width: 0;
              flex-grow: 1;
              display: flex;
              flex-wrap: wrap;
        
              .m-date-range-picker-dialog-dates-from,
              .m-date-range-picker-dialog-dates-to {
                align-items: center;
                display: flex;
                justify-content: center;
                margin: 2px 0;
                width: 220px;
              }
              .m-date-range-picker-dialog-dates-from-title {
                background-color: $cobalt4;
                border-radius: 3px;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                margin-right: 6px;
                padding: 4px 6px 3px 6px;
                text-transform: uppercase;
              }
              .m-date-range-picker-dialog-dates-to-title {
                background-color: $green4;
                border-radius: 3px;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                margin-right: 6px;
                padding: 4px 6px 3px 6px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  
    .m-date-range-picker-dialog-footer {
      display: flex;
      padding-top: 16px;
  
      .m-date-range-picker-dialog-buttons {
        margin-left: auto;
  
        .crs-btn {
          margin: 0 5px;
        }
      }
    }
  }
}
