.m-cancelable-dual-tag-component {
  display: flex;

  .m-cancelable-dual-tag-left {
    align-items: center;
    background-color: $brand-color-light;
    border: 1px solid $brand-color-light-border;
    border-radius: 3px 0 0 3px;
    display: flex;
    height: 32px;
    padding: 0 10px 0 4px;

    .m-cancelable-dual-tag-left-cancel-button-container {

      .m-cancelable-dual-tag-left-cancel-button {
        background-color: inherit;
        border: none;
        color: $brand-color-light-border;
        height: 24px;
        padding: 0;
      }

      .m-cancelable-dual-tag-left-cancel-button:hover {
        color: #C2B0DB;
      }
    }

    .m-cancelable-dual-tag-left-name {
      font-weight: 500;
      color: #fff;
      padding-left: 8px;
    }
  }

  .m-cancelable-dual-tag-right {
    align-items: center;
    border: 1px solid $light-gray1;
    border-left: none;
    border-radius: 0 3px 3px 0;
    display: flex;
    padding: 0 10px;
  }
}

.m-cancelable-dual-tag-component--disabled {

  .m-cancelable-dual-tag-left {
    background: none;
    background-color: $light-gray4;
    border-color: $light-gray4;
    color: $gray5;

    .m-cancelable-dual-tag-left-cancel-button-container {

      .m-cancelable-dual-tag-left-cancel-button {
        color: $gray5;
      }

      .m-cancelable-dual-tag-left-cancel-button:hover {
        color: $gray4;
      }
    }

    .m-cancelable-dual-tag-left-name {
      color: $gray5;
    }
  }

  .m-cancelable-dual-tag-right {
    border-color: $light-gray4;

    .m-cancelable-dual-tag-right-value {
      color: $gray5;
    }
  }
}