.m-distributions-cache-prefetching-main {
  .dynamic-table {
    padding-top: 10px;
  }
  .m-distributions-cache-prefetching-table-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .m-distributions-cache-prefetching-table-head-create-button {
      width: max-content;
    }

    .m-distributions-cache-prefetching-table-head-search {
      height: 30px;
      margin-left: 16px;
      width: 256px;
    }
  }

  @media screen and (max-width: 1200px) {
    .crs-page-head-right {
      width: min-content !important;

      .m-distributions-cache-prefetching-table-head-create-button {
        margin-bottom: 12px;
      }
    }
  }

  .datatable{
    .m-distributions-cache-prefetching-table-details-cell {
      align-items: center;
      display: flex;
    }

    .m-distributions-cache-prefetching-table-details-empty-cell {
      align-items: center;
      display: flex;
      margin-left: 40px;
    }

    .m-distributions-cache-prefetching-table-status-cell {
      align-items: center;
      display: flex;

      .m-distributions-cache-prefetching-table-status-cell-icon--processing {
        color: $gray4;
        width: min-content;
      }

       .m-distributions-cache-prefetching-table-status-cell-icon--success {
        color: $green4;
        width: min-content;
      }

      .m-distributions-cache-prefetching-table-status-cell-text--processing {
        color: $gray4;
        font-size: 12px;
        font-weight: 500;
        margin-right: 4px;
        text-transform: uppercase;
      }
        .m-distributions-cache-prefetching-table-status-cell-text--success {
        color: $green4;
        font-size: 12px;
        font-weight: 500;
        margin-right: 4px;
        text-transform: uppercase;
      }
    }

    .m-distributions-cache-prefetching-table-repeat-button {
      background: inherit;
      border: none;
      height: inherit;
      padding: 4px;

      .m-distributions-cache-prefetching-table-repeat-button-icon {
        color: $green4;
      }
    }

  }
}

.prefetch-dialog {
  .m-basic-dialog-container {
    width: 800px !important;
  }
}