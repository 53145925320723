.m-billing-main-payment-content {
	padding-top: 10px;
	.m-billing-main-payment-buttons {
		padding-top: 16px;
		float: right;

		.m-billing-main-payment-add-button {
			white-space: nowrap;
		}
	}

	.m-billing-main-payment-credit-cards {
		width: 768px;
	}
}

.m-billing-main-payment-add-credit-card-dialog {
	.m-billing-main-payment-add-credit-card-dialog-card-information {
		.m-billing-main-payment-add-credit-card-information-line {
			display: flex;
		}
	}
}

.m-billing-main-payment-delete-dialog,
.m-billing-main-payment-default-dialog {
	.m-billing-main-payment-confirmation-dialogs-buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 16px;

		.m-billing-main-payment-confirmation-dialog-button:not(:last-child) {
			margin-right: 8px;
		}
	}
}
