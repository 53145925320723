@import 'billing/billing.scss';
@import 'credentials/credentials.scss';
@import 'buckets/buckets.scss';
@import 'cacheInvalidation/cacheInvalidation.scss';
@import 'cachePrefetching/cachePrefetching.scss';
@import 'certificates/certificates.scss';
@import 'distributions/distributions.scss';
@import 'forgotPassword/forgotpassword.scss';
@import 'login/login.scss';
@import 'origins/forms.origins.scss';
@import 'profile/profile.scss';
@import 'register/register.scss';
@import 'resetPassword/resetpassword.scss';
@import 'waf/forms.waf.scss';
@import 'workspaces/forms.workspaces.scss';

.certificate-item{
  display: flex;
  gap: 5px;

  .certificate-item__expired{
    background-color: #db3737bf;
    background-image: -webkit-gradient(linear,left top, left bottom,from(hsla(0,0%,100%,.1)),to(hsla(0,0%,100%,0)));
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
    border-radius: 3px;
    color: #fff;
    padding: 0px 6px 0px 6px;
    font-size: 12px;
    font-weight: 500;
  }
}

.m-form-text-field {
  margin-bottom: 12px;

  .m-form-text-field-label {
    display: flex;

    .m-form-text-field-left {
      color: $text-color;
      display: flex;
      flex-direction: column;
      padding-right: 32px;
      width: 50%;

      .m-form-text-field-left-title {
        font-size: 16px;
        font-weight: 500;
      }

      .m-form-text-field-left-description {
        color: $gray3;
        font-size: 14px;
      }
    }

    .m-form-text-field-right {
      margin-left: auto;
      position: relative;
      width: 50%;

      .m-form-text-field-right-input {
        display: flex;
        flex-direction: column;

        input {
          border: 1px solid $light-gray3;
          border-radius: 3px;
          color: $gray1;
          font-family: 'Roboto Flex', sans-serif;
          font-size: 16px;
          padding: 6px 32px 6px 12px;
        }

        .m-form-text-input-data-button {
          background: inherit;
          border: none;
          height: inherit;
          position: absolute;
          right: 10px;
          top: 4px;
          padding: 4px;
        }
        
        .m-form-text-input-data-button:focus {
          box-shadow: 0 0 4px #888888;
        }
        input:focus {
          border-color: $light-gray1;
        }

        ::placeholder {
          color: $gray3;
        }
      }

      .m-form-text-field-right-input--error {
        input {
          border-color: $red4;
        }

        input:focus {
          border-color: $red4;
        }
      }

      .m-form-text-field-right-input--disabled {
        input {
          background-color: $light-gray4;
          border-color: $light-gray4;
          border-radius: 3px;
          color: $gray5;
        }

        ::placeholder {
          color: $gray5;
        }
      }

      .m-form-text-field-right-icon {
        position: absolute;
        right: 10px;
        top: 6px;
      }

      .m-form-text-field-right-icon--success {
        color: $green4;
      }

      .m-form-text-field-right-icon--error {
        color: $red4;
      }
    }

    .m-form-text-field-single {
      width: 100%;
    }
  }

  .m-form-text-field-error {
    align-items: center;
    color: $red4;
    display: flex;
    font-size: 12px;
    margin-top: 2px;

    .m-form-text-field-error-icon {
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}

.m-form-text-input-component {
  margin-bottom: 12px;

  .m-form-text-input-label {
    display: flex;

    .m-form-text-input-container {
      margin-left: auto;
      position: relative;

      .m-form-text-input {
        background-color: #fff;
        border: 1px solid $light-gray3;
        border-radius: 3px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        min-height: 54px;
        padding: 8px 12px;

        .m-form-text-input-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        input,
        textarea {
          border: none;
          color: $gray1;
          font-family: 'Roboto Flex', sans-serif;
          font-size: 16px;
          padding-right: 20px;
        }

        .m-form-text-input-data-button{
          background: inherit;
          border: none;
          height: inherit;
          position: absolute;
          right: 12px;
          padding: 4px;
        }
        input:focus,
        textarea:focus {
          border-color: $light-gray1;
        }

        ::placeholder {
          color: $gray3;
        }

        input:disabled,
        textarea:disabled {
          background-color: $light-gray4;
          border: none;
          color: $gray5;
          cursor: not-allowed;
        }

        input:disabled::placeholder {
          color: $gray5;
        }
      }

      .m-form-text-input--disabled {
        background-color: $light-gray4;
        border: 1px solid $light-gray4;
        color: $gray5;
        cursor: not-allowed;

        .m-form-text-input-title {
          color: $gray3;
        }
      }

      .m-form-text-input--error {
        border-color: $red4;
      }

      .m-form-text-input-icon {
        position: absolute;
        right: 10px;
        top: 6px;
      }

      .m-form-text-input-icon--success {
        color: $green4;
      }

      .m-form-text-input-icon--error {
        color: $red4;
      }
    }

    .m-form-text-input-single {
      width: 100%;
    }
  }

  .m-form-text-input-error {
    align-items: center;
    color: $red4;
    display: flex;
    font-size: 12px;
    margin-top: 2px;

    .m-form-text-input-error-icon {
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}

textarea {
  box-sizing: border-box;
  height: 30px;
  resize: none;
}

.m-form-section, .m-section-component {
  .m-form-section-title {
    color: $text-color;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .m-form-section-description {
    font-size: 16px;
    color: $gray3;
  }

  .m-form-section-content {
    padding-top: 8px;
  }

  .m-form-section-custom-field {
    display: flex;

    &-left {
      color: #394B59;
      display: flex;
      flex-direction: column;
      padding-right: 32px;
      width: 50%;

      &-title {
        font-size: 16px;
        font-weight: 500;
      }

      &-description {
        color: #8A9BA8;
        font-size: 14px;
      }
    }

    &-right {
      margin-left: auto;
      position: relative;
      width: 50%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .m-form-text-field {
    .m-form-text-field-label {
      flex-direction: column;

      .m-form-text-field-left {
        margin-bottom: 4px;
        width: 100%;
      }

      .m-form-text-field-right {
        margin-left: unset;
        width: 100%;
      }
    }
  }
}
