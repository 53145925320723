.datatable {
    .options {
        width: 8% !important;
    }
}

.make-admin-dialog-content {
    margin: 10px 0;

    .make-admin-dialog-content-otp-fields {
        margin: 5px;

        .make-admin-dialog-content-otp-section-title {
            color: $gray1;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .make-admin-dialog-content-otp-section-content {
            padding-top: 8px;
        }
    }
}

.user_type_btn {
    padding: 0 !important;
}

.text__link {
    color: #2b95d6 !important;
    cursor: pointer;
    font-weight: 500;
    &:hover {
        color: #106ba3 !important;
    }
}

.m-profile-main-activities-tab {
    .m-profile-main-activities-filter {
        padding-bottom: 16px;
    }

    .m-profile-main-activities-table {
        .ws {
            width: 20%;
        }

        .service {
            width: 10%;
        }

        .action {
            width: 40%;
        }

        .updated {
            width: 25%;
        }

        .options {
            width: 5%;
        }

        .m-profile-main-activities-table-loading {
            height: 240px;
            padding: 24px 0;
        }
    }
}
