.m-distributions-pagerules-main {
  .disable{
    pointer-events: none;
    opacity: 0.3;
  }
  
  .m-spinnerpage-component{
    position: absolute;
    top: 50%;
    left: 45%;
    height: 20px;
  }

  .crs-table-cell {
    position: relative;
  }
  .icon-box{
    width: 60px!important;
  }

  .crs-table-row {
    img {
      width: 20px !important;
      vertical-align: middle;
    }
  }
  .m-distributions-pagerules-main-disable {
    pointer-events: none;
    opacity: 0.3;
  }

  .m-distributions-pagerules-pagerule-table-head {
    display: flex;

    .m-distributions-pagerules-pagerule-table-head-search {
      height: 30px;
      width: 256px;
    }

    .m-distributions-pagerules-pagerule-table-head-add-button {
      margin-right: 12px;
    }
  }

  .m-distributions-pagerules-main-pagerule-table {
    position: relative;
    margin: 16px 0 0 0;

    .m-distributions-pagerules-main-pagerule-table-loading {
      height: 240px;
      padding: 24px 0;
    }
  }

  .m-distributions-pagerules-main-pagerule-table-no-item {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }

  .path-operator {
    width: 15%;
  }

  .path-pattern {
    width: 35%;
  }

  .origin {
    width: 25%;
  }

  .updated-at {
    width: 15%;
  }

  .m-distributions-page-rules-callout {
    margin-top: 15px;
  }
}