.m-table {
  border: 1px solid $light-gray3;
  border-collapse: collapse;
  /*
    --> It improves the performance for large table but not tested.
    --> TODO: Test it when table component is done.
    table-layout: fixed;
  */
  table-layout: fixed;
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: stretch;
}