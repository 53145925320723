.m-reset-password-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  width: 500px;

  .m-reset-password-form-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 8px;
    text-align: center;
  }

  .m-reset-password-form-description {
    font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
  }

  .m-reset-password-form-sign-up-section {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  .m-reset-password-form-link-button {
    background-color: inherit;
    border: none;
    color: $text-color;
    font-weight: 500;
    height: inherit;
    margin: 0 4px;
    padding: 0;
  }

  .m-reset-password-form-link-button:hover {
    color: $cobalt3;
    text-decoration: underline;
  }
  
  .m-reset-password-form-submit-button {
    font-size: 20px;
    height: 40px;
    padding-bottom: 8px;
    width: 100%;
  }
  
  .m-form-section-content {
    padding-top: 2px !important;
  }
}