@import 'authentication/authentication.main.scss';

.security-main {
    display: flex;
    gap: 20px;

    form {
        width: 100%;
        margin-top: 24px;
    }
}

.change-password {
    max-width: 450px;
    width: 100%;
    height: 100%;
    border: 1px solid #e4e7eb;
    padding: 20px;
    border-radius: 6px;
}