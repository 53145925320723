@import "billingDetails/manageBilling.scss";
@import "paymentConfirmation/confirmPayment.scss";
@import "paymentMethod/cardManagement.scss";

.subscription {
  &__container {
    display: flex;
    gap: 20px;
    padding-top: 20px;
  }

  &__left {
    flex: 1;
  }

  &__right {
    flex: none;
    width: 500px;
  }

  .has-error {
    border-top: 2px solid #e76a6e !important;
  }

  .has-error-full {
    border: 1px solid #e76a6e !important;
  }

  &__section {
    margin-bottom: 10px;
    border: 1px solid #e4e7eb;
    padding: 10px;
    border-radius: 6px;

    .m-add-balance-form-section-radio {
      display: flex;
      align-content: center;
      flex-direction: row;
      align-items: flex-start;
    }
    .m-add-balance-form-section-head-title {
      font-size: 17px;
      padding-bottom: 5px;
      margin: 0;
      font-weight: 600;
    }
    .m-add-balance-form-section-head-description {
      font-size: 13px;
    }
    .m-add-balance-form-section-content {
      padding-top: 10px;
    }
    .m-add-balance-form-section-radio {
      padding-right: 10px;
    }
    .m-add-balance-form-section-field-title {
      padding-bottom: 5px;
    }
    .m-add-balance-form-section-head {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
    }
  }
}
