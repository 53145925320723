@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.payment-summary {
  position: relative;

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
    transition: opacity 0.3s;
  }
  .loading {
    opacity: 0.5;
    transition: opacity 0.3s;
    pointer-events: none;
  }
  &__confirm-button {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  .header {
    margin-bottom: 10px;

    h3 {
      font-size: 17px;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  .exchange-rate {
    width: 100%;
    background-color: rgb(146 146 146 / 10%);
    border-radius: 5px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding: 10px;
    animation: fadeIn 0.5s forwards;
    opacity: 0;
  }

  .exchange-rate-info {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .exchange-rate-title {
    font-weight: 600;
  }

  .exchange-rate-value {
    color: #2c2c2c;
    font-weight: 500;
  }
  .exchange-rate-label {
    font-weight: 500;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e4e4e4;
    margin: 10px 0;
  }

  .details {
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-weight: 500;

      &.products {
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .product {
          display: flex;
          justify-content: space-between;
          height: 100%;
          max-height: 37px;

          .label {
            font-weight: 500;
          }
          .description {
            font-size: 12px;
            font-weight: normal;
          }

          .value {
            color: #2c2c2c;
            font-weight: 500;
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
          .discount {
            color: #cd4246;
            text-decoration: line-through;
            font-size: 11px;
          }
        }
      }

      &.discount {
        color: #32a467;
        font-size: 14px;        
      }
      &.vat,
      &.total {
        .label,
        .value {
          font-size: 14px;
        }

        .value {
          color: #333;
          font-weight: 500;
        }
      }
      &.count {
        margin-bottom: 5px !important;
      }
      &.total {
        font-weight: 500;
        color: #2c2c2c;
        .label,
        .value {
          font-size: 15px;
        }
      }
    }
  }
}

.payment-confirm {
  padding-top: 15px;
  .sales-agreement-button {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  .container {
    text-align: center;
    padding-top: 30px;
  }

  .pay-button {
    font-size: 19px;
    height: 48px;
    width: 250px;
  }
}
