.m-workspaces-user-activity-content{
  .m-workspaces-user-activity-filter {
    padding-bottom: 16px;
  }

  .m-workspaces-user-activity-table {
    .name {
      width: 15%;
    }

    .service{
      width: 15%;
    }

    .action {
      width: 50%;
    }

    .updated {
      width: 25%;
    }

    .m-workspaces-user-activity-table-loading {
      height: 240px;
      padding: 24px 0;
    }

    p {
      margin: 0;
    }
  }

  .m-workspaces-user-activity-table-tooltip-portal {
    .bp3-popover-content {
      max-width: 420px;

      .m-workspaces-user-activity-table-tooltip-field-title {
        font-weight: 500;
      }
    }
  }
}