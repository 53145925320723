.m-welcome-page {
  
  .m-welcome-page-head {
    background: url('~common/assets/background-9-mini.png');
    background-position: top;
    background-size: cover;
    border: 1px solid $border-color;
    border-radius: 3px;
    color: #fff;
    display: flex;
    min-height: 400px;
    margin-bottom: 32px;

    .m-welcome-page-head-left {
      align-items: center;
      display: flex;
      flex: 1;

      .m-welcome-page-head-image {
        height: 300px;
        margin-left: 20%;
      }
    }

    .m-welcome-page-head-right {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      padding: 64px 64px;
      
      .m-welcome-page-head-title {
        font-size: 30px;
        letter-spacing: 1px;
        margin-bottom: 16px;
      }
      
      .m-welcome-page-head-description {
        color: $light-gray4;
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .m-welcome-page-faq {
    border: 1px solid $border-color;
    border-radius: 3px;
    margin-bottom: 32px;
    padding: 24px 32px 8px 32px;
    
    .m-welcome-page-faq-title {
      color: $gray5;
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 16px;
    }

    .m-welcome-page-faq-items {
      display: flex;
      flex-wrap: wrap;

      .m-welcome-page-faq-items-line {
        display: flex;

        .m-welcome-page-faq-item {
          flex: 1;
          margin-bottom: 16px;
          text-align: center;

          .m-welcome-page-faq-item-button {
            background: inherit;
            border: none;
            color: $text-color;
            height: inherit;
            min-height: 150px;
            padding: 12px 16px;

            .m-welcome-page-faq-item-title {
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 8px;
            }
  
            .m-welcome-page-faq-item-content {
              font-weight: 400;
            }
          }

          .m-welcome-page-faq-item-button:hover {
            background-color: $light-gray5;
          }
        }
  
        .m-welcome-page-faq-item:not(:last-child) {
          border-right: 1px solid $border-color;
          padding-right: 23px;
        }

        .m-welcome-page-faq-item:not(:first-child) {
          padding-left: 24px;
        }
      }

      .m-welcome-page-faq-items-line--last {

        .m-welcome-page-faq-item {
          width: 287.5px;
        }
      }
    }
  }
}