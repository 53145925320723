.cloner-monitoring__main {
  &__content {
    padding: 16px 0 30px;
    &__info {
      display: flex;
      margin-top: 16px;
      width: 100%;

      .m-info-box-component:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__chart {
      margin-top: 16px;
      width: 100%;

      &__line {
        display: flex;
        width: inherit;

        &--big {
          border: 1px solid $border-color;
          border-radius: 3px;
          width: 100%;

          &:nth-of-type(2n-1) {
            margin-right: 16px;
          }
        }

        &--small {
          border: 1px solid $border-color;
          border-radius: 3px;
          width: calc(40% - 8px);
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .cloner-monitoring__main {
    &__chart {
      &__line {
        flex-direction: column;
        width: inherit;

        &--big {
          margin-bottom: 16px;
          width: 100% !important;
          &:nth-of-type(2n-1) {
            margin-right: 16px;
          }
        }
        &--small {
          width: 100% !important;
        }
      }
    }
  }
}
