.m-info-box-component {
	border: 1px solid $border-color;
	border-left: 5px solid $border-color;
	border-radius: 3px;
	height: min-content;
	padding: 8px 12px;
	width: 100%;
	
	.m-info-box-title {
		color: $gray3;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1px;
		line-height: 15px;
		text-transform: uppercase;
		font-weight: 600;
	}

	.m-info-box-value {
		color: $text-color;
		font-size: 15px;
	}
}