.c-button-group{
  min-width: 300px;
  
  .c-button-group-button{
    &.active{
      // box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
      background-color: #d8e1e8;
    	background-image: none;
      border-color: rgb(24, 32, 38) !important;
    }
  }
}