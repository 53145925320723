.m-home-main-module {
  
  .m-home-main-content {
    padding: 16px 0 30px;
    

    .m-home-main-selectboxes {
      display: flex;

      .m-home-main-selectbox {
        flex: 1;

        .m-home-main-selectbox-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }
      }

      .m-home-main-selectbox:not(:last-child) {
        margin-right: 16px;
      }
    }

    .m-home-main-info-boxes {
      display: flex;
      margin-top: 16px;
      width: 100%;

      .m-info-box-component:not(:last-child) {
        margin-right: 16px;
      }
    }

    .m-home-main-usage-data {
      border-bottom: 1px solid $border-color;
      border-top: 1px solid $border-color;
      display: flex;
      margin-top: 16px;
      padding: 12px 0;

      .m-home-main-usage-data-field {
        flex-grow: 1;

        .m-home-main-usage-data-field-title {
          color: $text-color;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .m-home-main-usage-data-field-value {
          color: $gray1;
          font-size: 16px;
        }
      }

      .m-home-main-usage-data-field:last-child {
        flex-grow: 0;
      }
    }

    .m-home-main-charts {
      margin-top: 16px;
      width: 100%;

      .m-home-main-charts-line {
        display: flex;
        width: inherit;

        .--big {
          border: 1px solid $border-color;
          border-radius: 3px;
          width: calc(60% - 8px);
        }

        .--small {
          border: 1px solid $border-color;
          border-radius: 3px;
          width: calc(40% - 8px);
        }
  
        .m-home-main-charts-chart:nth-of-type(2n-1) {
          margin-right: 16px;
        }
      }

      .m-home-main-charts-line:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .m-home-main-charts-line {
    flex-direction: column;
    width: inherit;

    .--big {
      margin-bottom: 16px;
      width: 100% !important;
    }

    .--small {
      width: 100% !important;
    }

    .m-home-main-charts-chart:nth-of-type(2n-1) {
      margin-right: 16px;
    }
  }
}