.m-m-buckets-pages-main {
  height: 100%;

  .m-m-buckets-pages-main-content {
    padding: 20px 0;

    .m-m-buckets-pages-main-content-info {
      .m-buckets-main-info-boxes {
        display: flex;
        margin-top: 16px;
        width: 100%;

        .m-info-box-component:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    .m-m-buckets-pages-main-content-heading {
      padding: 50px 0;

      .m-m-buckets-pages-main-table-head {
        display: flex;
        height: 30px;

        .m-m-buckets-pages-main-table-head-create-button {
          margin-right: 12px;
        }

        .m-m-buckets-pages-main-table-head-search {
          height: 30px;
          width: 256px;
        }
      }

      .m-m-buckets-pages-main-content-items {
        padding: 16px 0 0 0;

        .m-m-buckets-pages-main-content-items-no-item {
          align-items: center;
          border: 1px solid $light-gray4;
          border-top: none;
          color: $text-color;
          display: flex;
          height: 42px;
          justify-content: center;
        }

        .name {
          width: 35%;
        }

        .visibility {
          width: 15%;
        }

        .size {
          width: 15%;
        }

        .objects {
          width: 15%;
        }

        .createdAt {
          width: 15%;
        }

        .region {
          width: 10%;
        }
      }
    }
  }
}