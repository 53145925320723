.m-workspaces-assigned-workspaces-content {
  .m-workspaces-assigned-workspaces-table {
    margin-top: 16px;

    .role {
      width: 15%;
    }
  }

  .m-workspaces-assigned-workspaces-table-no-items {
      align-items: center;
      border: 1px solid $light-gray4;
      border-top: none;
      color: $text-color;
      display: flex;
      height: 42px;
      justify-content: center;
  }

  .m-workspaces-assigned-workspaces-section {
    .m-workspaces-assigned-workspaces-section-title {
      font-size: 20px;
      font-weight: 500;
    }

    .m-workspaces-assigned-workspaces-section-description {
      color: $gray1;
      font-size: 14px;
      margin-top: 2px;
    }

    .m-workspaces-assigned-workspaces-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;
    }
  }
  
  .m-workspaces-assigned-workspaces-section:not(:last-child) {
    border-bottom: 1px solid $light-gray4;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}