.m-checkbox-component {
  display: flex;

  label {
    color: $text-color;
    cursor: pointer;
    font-size: 16px;

    .m-checkbox-component-label {
      display: flex;

      .m-checkbox-component-label-checked {
        border: 1px solid $border-color;
        border-radius: 2px;
        height: 20px;
        margin-right: 8px;
        width: 20px;

        .m-checkbox-component-label-checked-content {
          margin: 3px;
          height: 12px;
          width: 12px;
        }
      }
    }

    .m-checkbox-component-label--disabled {
      color: $gray5;
      cursor: not-allowed;
    }
  }

  input {
    display: none;
  }

  input:checked + label {

    .m-checkbox-component-label {

      .m-checkbox-component-label-checked {

        .m-checkbox-component-label-checked-content {
          background-color: $cobalt5;
        }
      }
    }
  }
}