.credit-card {
    &__title {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    &__form {
        background-color: #ffffff;
        border-radius: 8px;
        width: 100%;
    }

    &__form-group {
        margin-bottom: 3px;

        &--inline {
            display: flex;
            justify-content: space-between;
        }
    }

    &__fixed {
        flex: none;
        width: 75%;
    }

    &__flex {
        width: 25%;
        flex: 1;
        margin-left: 10px;
    }

    &__label {
        display: block;
        margin-bottom: 5px;
    }

    &__input-container {
        position: relative;
    }

    &__input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    &__logo {
        position: absolute;
        top: 30px;
        right: 10px;
        -webkit-transform: translateY(-45%);
        transform: translateY(-45%);
        width: 35px;
        height: 49px;

        svg {
            width: 35px;
            height: 40px;
        }
    }

    &__button {
        border: none;
        color: #fff;
        border-radius: 4px;
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .m-checkbox-component-label {
            font-size: 15px;
            align-items: center;
        }
    }
}
