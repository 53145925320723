.m-userbox-component {
  margin-right: 12px;

  .m-userbox-button {
    align-items: center;
    background: inherit;
    border: none;
    color: $light-gray3;
    display: flex;
    font-weight: 400;
    height: inherit;
    margin: 0 -12px;
    padding: 6px 12px;

    .m-userbox-button-profile-image {
      align-items: center;
      background-color: $gray1;
      border-radius: 50%;
      color: $light-gray3;
      display: flex;
      height: 24px;
      margin-right: 4px;
      justify-content: center;
      width: 24px;
    }

    .m-userbox-button-info {
      margin-right: 8px;
      margin-top: 2px;

      .m-userbox-username {
        font-size: 10px;
        letter-spacing: 1px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
  }

  .m-userbox-button:hover {
    background-color: $dark-gray5;
  }
}

.m-userbox-popover {
  min-width: 150px;

  .m-userbox-popover-content {

    .m-userbox-popover-content-options {
      padding: 4px;

      .m-userbox-popover-content-option-divider {
        background-color: $border-color;
        height: 1px;
        margin: 8px 12px;
      }

      .m-userbox-popover-content-option {

        .m-userbox-popover-content-option-button {
          border: none;
          border-radius: 0;
          display: flex;
          font-family: 'Roboto Flex', sans-serif;
          font-size: 16px;
          font-weight: 400;
          height: inherit;
          justify-content: inherit;
          width: 100%;

          a {
            display: flex;
            width: 100%;
          }
          
          .m-userbox-popover-content-option-button-icon {
            align-items: center;
            display: flex;
            height: 19px;
            margin-left: auto;
            padding-left: 16px;

            i {
              display: flex !important;
            }
          }
        }

        .m-userbox-popover-content-option-button:not(:disabled) {

          .m-userbox-popover-content-option-button-icon {
            color: $gray1;
          }
        }

        .m-userbox-popover-content-option-button:hover:not(:disabled) {
          background-color: $brand-color-contrast-hover;
          color: #fff;
          
          .m-userbox-popover-content-option-button-icon {
            color: #fff;
          }
        }
      }

      .m-userbox-popover-content-option:first-child {
        
        .m-userbox-popover-content-option-button {
          border-radius: 3px 3px 0 0;
        }
      }

      .m-userbox-popover-content-option:last-child {
        
        .m-userbox-popover-content-option-button {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
}