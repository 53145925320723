.s3-dialog-section {
    .s3-dialog-section-form {
    }
    .m-form-buttons {
        float: right;
    }
}

.m-m-buckets-pages-files {
    .upload-progress {
        top: -100px;
        left: 347px;
    }
}

.m-form-image-uploader {
    .disable {
        opacity: 0.5;
    }
}

