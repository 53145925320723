.m-register-module {
  .m-register-head {
    margin-bottom: 64px;

    .m-register-page-brand-logo {
      width: 300px;
    }
  }

  .m-register-content {
    display: flex;
    min-height: 450px;

    .m-register-content-left {
      align-items: center;
      display: flex;
      width: 400px;
    }

    .m-register-content-right {
      align-items: center;
      display: flex;
      padding-left: 64px;
      flex-direction: column;
      .m-register-page-image {
        width: 400px;
        padding-top: 86px;

      }

      .m-register-content-right-captcha { 
        width: 400px;
        visibility: hidden;

          .m-register-content-right-captcha-link-button {
            font-size: 11px;
          }
          .m-register-content-right-captcha-section-text{
            font-size: 11px;
          }
        }
    }
  }
}
