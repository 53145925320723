  
  
    .m-security-settings-form-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      .m-security-settings-form-section-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        .m-security-settings-form-section-content-or {
            font-size: 18px;
            font-weight: 400;       
             }
      }
      .m-security-settings-form-section-content-manually {
        color: $gray3;
        padding: 10px 0px 14px 0px;       
      }
      .m-security-settings-form-section-qr-code {
        margin-left: 70px;
      }
      .m-security-settings-form-secret-container {
        border: 1px solid $border-color;
        border-radius: 3px;
        display: flex;
        padding: 8px 12px;
  
          .m-security-settings-form-secret-content {
  
            textarea {
              background-color: inherit;
              border: none;
              color: $text-color;
              font-family: 'Roboto Flex', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 16px;
              height: 20px;
              overflow: hidden;
              width: 100%;
            }
  
            .m-security-settings-form-secret-content-copied-message {
              color: $light-gray1;
              font-size: 12px;
              font-weight: 500;
            }
          }

        .m-security-settings-form-secret-utility-buttons {
          align-items: center;
          display: flex;
          margin-left: auto;
  
          .m-security-settings-form-secret-utility-button {
            background: inherit;
            border: none;
            height: inherit;
            padding: 4px;
          }
  
          .m-security-settings-form-secret-utility-button:hover {
            background-color: $light-gray1;
          }
        }
      }
    }
  