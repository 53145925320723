.payment-method {
  position: relative;
  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -25px;
    transition: opacity 0.3s;
  }

  .loading {
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  &__no-item{
    color: $gray5;
    font-size: 16px;
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__list--no-card {
    align-items: center;
    color: $gray5;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    height: 60px !important;
    padding: 0 !important;
    border: 1px solid #dce0e3 !important;

    &:hover {
      .payment-method__indicator--unselected {
        background-color: rgba(43, 148, 214, 0.3) !important;
      }
    }
  }

  &__indicator {
    border-radius: 3px 0 0 3px;
    width: 5px;
    height: 60px;

    &--selected {
      background-color: #4580e6;
    }

    &--unselected {
      border-right: none;
      border: none;
    }
  }

  &__content {
    display: flex;
    border-radius: 0 3px 3px 0;
    border-left: none;
    padding: 12px 16px;
    padding-right: 5px;
    width: 100%;
  }

  &__content-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    min-width: 0;
  }

  &__content-date {
    color: #8a9ba8;
    font-size: 12px;
    font-weight: 400;
  }

  &__content-right {
    display: flex;
    margin-left: auto;
    flex-shrink: 0;
    width: 200px;
    padding-right: 3px;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
  &__default-label {
    background-color: #4580e6;
    border: 1px solid #137cbd;
    border-radius: 3px;
    color: #fff;
    padding: 3px;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__options {
    display: flex;
  }

  &__logo {
    width: 50px;
    height: 35px;
    display: block;
    text-align: center;
    transform: translateY(-13%);

    svg {
      width: 38px;
      height: 45px;
    }
  }
}
