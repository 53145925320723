.image-loader {
	display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	width: 100%;
}
.image-loader span{
	transition: all 500ms ease;
	height: 7px;
	width: 7px;  
	display: inline-block;
	border-radius: 10px;
	animation: colorful 1.5s ease  infinite;
	margin-right: 2px;
  }  
  
  @keyframes colorful {
	from {background-color: #c7c5c5;}
	to {background-color: #545454}
  }
  
  .image-loader span:nth-child(1){  animation-delay: 0; }
  .image-loader span:nth-child(2){  animation-delay: 300ms; }
  .image-loader span:nth-child(3){  animation-delay: 600ms; }
  