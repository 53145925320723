.m-create-certificate-form {

  .m-create-certificate-form-buttons {
    
    .m-create-certificate-form-create-button {
      width: 100%;
    }
  }

  .m-create-certificate-form-dropzone {
    border-radius: 3px;
    color: $gray5;
    cursor: alias;
    height: 182px; //Calculated as (maxLines prop) * 16 + 22px
    min-height: 102px;
    margin-bottom: 16px;

    .m-create-certificate-form-dropzone-input {

      .m-form-text-input {
        min-height: 102px !important;
        justify-content: inherit !important;
      }
    }

    .m-create-certificate-form-dropzone-hover {
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 3px;
      display: flex;
      height: 180px; //Calculated as (maxLines prop) * 16 + 20px
      justify-content: center;
      min-height: 102px;
      padding: 0 10px;
      text-align: center;
    }
  }
}