.m-distributions-redirection-create {

  .m-distributions-redirection-create-content {
    padding: 16px 0;

    .m-generic-form-select-box-redirecction{
      display: flex;
      .m-checkbox-component{
        padding-right: 15px;
      }
    }

    .m-distributions-redirection-create-pattern {
      display: flex;

      .m-selectbox-component {
        flex: 1;
      }

      .crs-editable-text-field-container {
        flex: 4;
        margin-left: 12px;
      }

      &-value {
        font-size: 16px;
        height: 32px;
        width: 100%;

        &::placeholder {
          color: $gray5;
        }
      }
    }
  }
}