.m-distributions-advanced-component {
  .m-distributions-advanced-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4px 16px;
    margin: 4px 0;

    .m-distributions-advanced-line-title {
      font-size: 16px;
      font-weight: 500;
    }
    
    .m-distributions-advanced-line-description {
      color: $gray3;
      font-size: 14px;
    }
  }

  .m-distributions-advanced-line-button {
    width: 80px;
    margin-right: 16px;
  }
}

.m-distributions-advanced-move-dialog-content {
  .m-selectbox-component {
    margin-top: 8px;
  }

  .m-distributions-advanced-move-dialog-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  
    .m-distributions-advanced-move-dialog-button:not(:last-child) {
      margin-right: 8px;
    }
  }
}

