.m-line-chart-component {
  height: 100%;
  padding-bottom: 4px;

  .m-line-chart-head {
    position: relative;
    .csv_download {
      position: absolute;
      right: 15px;
      z-index: 1;
      top: 15px;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .m-line-chart-title-container {
      align-items: center;
      display: flex;
      padding: 20px 24px 2px 24px;

      .m-line-chart-title {
        font-size: 17px;
        font-weight: 500;
        line-height: 24px;
        color: #394B59;
      }

      .m-line-chart-tooltip-container {
        margin-left: 8px;
      }
    }
  }

  .m-line-chart-content {
    display: flex;
    justify-content: center;
    padding: 0 2px;
  }

  .m-line-chart-rendering {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .m-line-chart-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;

    .m-line-chart-empty-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }

    .m-line-chart-empty-description {
      color: $gray3;
      font-size: 16px;
      line-height: 16px;
      margin-top: 8px;
      text-align: center;
    }
  }
}

.m-line-chart-component-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 4px;
}
