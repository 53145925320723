.m-topbar {
  align-items: center;
  background: $dark-gray3;
  background: #202831;
  border-bottom: 1px solid $dark-gray3;
  display: flex;
  height: 50px;
  min-width: 1024px;
  padding: 0 12px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */

  .m-topbar-left {
    align-items: center;
    display: flex;
    width: 190px;
    justify-content: center;

    .m-topbar-brand-logo-button {
      background: inherit;
      border: none;
      height: inherit;
      padding: 0;

      a {
        align-items: center;
        display: flex;
      }

      .m-topbar-brand-logo {
        height: 30px;
      }
      .logo-label {
        position: absolute;
        top: 32px;
        font-size: 11px;
        left: 37px;
        width: 94px;
        text-align: right;
        letter-spacing: 2px;
        background: rgb(19, 150, 167);
        background: -moz-linear-gradient(80deg, rgba(19, 150, 167, 1) 0%, rgba(141, 72, 156, 1) 100%);
        background: -webkit-linear-gradient(80deg, rgba(19, 150, 167, 1) 0%, rgba(141, 72, 156, 1) 100%);
        color: linear-gradient(80deg, rgba(19, 150, 167, 1) 0%, rgba(141, 72, 156, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1396a7",endColorstr="#8d489c",GradientType=1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
      }
    }
  }

  .m-topbar-left-full {
    -webkit-flex: 1 1;
    flex: 1 1;
    padding-left: 26px;
    justify-content: flex-start;
  }

  .m-topbar-button {
    background-color: inherit;
    border: none;
    color: $gray1;
    padding: 0;
    padding-left: 10px;
  }

  .m-topbar-button:hover {
    color: $gray3;
  }

  .m-topbar-buttons {
    color: $border-color;
    display: flex;

    .m-topbar-button:not(:last-child) {
      margin-right: 8px;
    }

    .m-topbar-button:hover {
      color: $border-color-dark;
      cursor: pointer;
    }
  }
  .m-topbar-button-active {
    color: #cdcdcd!important;
  }

  .m-topbar-middle {
    display: flex;
    flex: 1;
    justify-content: center;

    .m-topbar-middle-brand-name {
      color: $border-color;
    }

    .m-topbar-middle-brand-logo {
      height: 25px;
      opacity: 0.2;
    }
  }

  .m-topbar-right {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .m-topbar-balance-section {
    align-items: center;
    background: inherit;
    border: none;
    color: $light-gray3;
    display: flex;
    height: inherit;
    margin: 0 -12px;
    padding: 6px 12px;

    a {
      display: flex;
    }

    .m-topbar-balance-section-field {
      align-items: center;
      display: flex;
    }

    .m-topbar-balance-section-field:not(:last-child) {
      margin-right: 24px;
    }

    .m-topbar-balance-section-title {
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1px;
      margin-right: 8px;
      margin-top: 2px;
      text-transform: uppercase;
    }

    .m-topbar-balance-section-value {
      align-items: center;
      background-color: $gray1;
      border-radius: 3px;
      color: #fff;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      padding: 6px 8px 5px 8px;

      .m-topbar-balance-section-value-currency {
        font-size: 10px;
        margin-right: 2px;
        padding-top: 1px;
      }
    }

    .m-topbar-balance-section-value--blue {
      background-color: $cobalt4;
    }

    .m-topbar-balance-section-value--green {
      background-color: $green4;
    }

    .m-topbar-balance-section-value--orange {
      background-color: $orange4;
    }

    .m-topbar-balance-section-value--red {
      background-color: $red4;
    }
  }

  .m-topbar-balance-section:hover {
    background-color: $dark-gray5;
  }

  .m-topbar-divider {
    background-color: $dark-gray5;
    height: 24px;
    margin: 0 24px;
    width: 1px;
  }

  .m-topbar-divider-admin {
    margin: 0 7px!important;
  }
}
