.bucket-upload {
  &__main {
    &__bar {
      color: white;
      top: 114px;
      width: 100%;
      left: 382px;
      margin-bottom: 15px;
      &__color-blue {
        background-color: #0073bb;
      }
      &__color-green {
        background-color: #1d8102;
      }
      &__color-red {
        background-color: #d13212;
      }
      &__container {
        width: 100%;
        padding: 20px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;

        &__info {
          display: flex;
          flex-direction: column;
          gap: 0.3px;
          &__title {
          }
          &__load-bar {
            width: 600px;
            display: flex;
            flex-direction: row;
            align-content: center;
            gap: 17px;
            align-items: center;
            border-radius: 2px;
            &__percentage {
              width: 100px;
              font-size: 12px;
            }
            .bp3-progress-bar {
              background-color: #ffffff69;
              height: 5px;
              border-radius: 4px;

              .bp3-progress-meter {
                background-color: #e8e8e8;
                border-radius: 4px;
              }
            }
          }
          &__total-remaining {
          }
          &__estimated-time {
          }
        }
        &__content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          &__column {
            display: flex;
            flex-direction: column;
          }
          &__rows {
            padding-left: 10px;
            font-size: 15px;
            &__title {
              font-weight: 500;
            }
          }
        }
      }
    }

    &__summary {
      border: 1px solid #e1e8ed;
      margin-bottom: 15px;

      &__header {
        padding: 15px;
        background-color: #f6f8fa;
        border-bottom: 1px solid #e1e8ed;
        display: flex;
        justify-content: space-between;
      }
      &__content {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        :nth-child(1) {
          border-left: none;
        }

        &__box {
          border-left: 1px solid #e1e8ed;
          padding-left: 25px;
          max-width: 300px;
          width: 100%;

          div {
            padding-bottom: 5px;
            word-wrap: break-word;
          }

          &__area {
            &__link {
              color: #106ba3 !important;
              &:hover {
                text-decoration: underline !important;
                color: #106ba3 !important;
              }
            }
            font-size: 14px;
            &__success {
              color: #1d8102;
            }
            &__error {
              color: #d13212;
            }
          }
        }
      }
    }

    &__content {
      &__files {
        &__table {
          &__row {
            &__completed {
              color: #1d8102;
            }
            &__failed {
              color: #d13212;
            }
            &__error-message {
              border-bottom: var(--border-divider-list-width-iu80dg, 1px) dashed;
            }
          }
          .action {
            width: 5%;
          }
          .name {
            width: 30%;
          }
          .folder {
            width: 35%;
          }
          .type {
            width: 15%;
          }
          .size {
            width: 10%;
          }
          .status {
            width: 16%;
            i {
              padding-right: 2px;
            }
          }
          .error {
            width: 10%;
            i {
              padding-right: 2px;
            }
          }
        }
      }
    }
  }
}
