.m-profile-main-doc-tab {

  .m-profile-main-doc-item-container {
    margin: 20px 20px;

    .m-profile-main-doc-item-container-item {
      margin: 25px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e4e7eb;
    }
  }
}
