.m-waf-policies-component {
  .m-waf-policies-content {
    margin-top: 16px;
  
    .m-waf-policies-table {
      .m-waf-policies-table-loading-state {
        height: 240px;
        padding: 24px 0;
      }
  
      .m-waf-policies-table-items-no-item {
        align-items: center;
        border: 1px solid $light-gray4;
        border-top: none;
        color: $text-color;
        display: flex;
        height: 42px;
        justify-content: center;
      }
    }
  }
}

.m-waf-policies-main-duplicate-policy-dialog,
.m-waf-policies-main-delete-policy-dialog {
  &-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &-button:not(:last-child) {
    margin-right: 8px;
  }
}

.m-waf-policies-head-right {
  display: flex;
  .m-waf-policies-head-right-btn {
    margin-left: 10px;
  }
}
