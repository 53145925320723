.m-create-cache-prefetching-form {

  .m-create-cache-prefetching-form-headers {
    width: 100%;

    .m-create-cache-prefetching-form-headers-fields {
      display: flex;

      .m-create-cache-prefetching-form-headers-field {
        width: calc(50% - 96px);

        .m-create-cache-prefetching-form-headers-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-create-cache-prefetching-form-headers-field-input {
          width: 100%;
        }
      }

      .m-create-cache-prefetching-form-headers-field:not(:last-child) {
        margin-right: 16px;
      }

      .m-create-cache-prefetching-form-headers-button {
        height: 33px;
        margin-top: 14px;
        min-width: 160px;
        white-space: nowrap;
      }
    }
  }

  .m-create-cache-prefetching-form-headers-items {
    .m-create-cache-prefetching-form-header {
      display: flex;
      
      .m-create-cache-prefetching-form-headers-field {
        .m-create-cache-prefetching-form-headers-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-create-cache-prefetching-form-headers-field-input {
          width: 100%;
        }
      }

      .m-create-cache-prefetching-form-headers-field:not(:last-child) {
        flex: 1;
        margin-right: 16px;
      }

      .m-create-cache-prefetching-form-headers-button {
        height: 33px;
        margin-top: 14px;
        min-width: 160px;
        white-space: nowrap;
      }
    }
  }
}