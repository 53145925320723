.m-workspaces-organization-settings-content {
  .m-workspaces-organization-settings-section {
    .m-workspaces-organization-settings-section-title {
      font-size: 20px;
      font-weight: 500;
    }

    .m-workspaces-organization-settings-section-description {
      color: $gray1;
      font-size: 14px;
      margin-top: 2px;
    }
    
    .m-workspaces-organization-settings-section-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;
      width: 768px;
    }
  }
}