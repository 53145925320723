.m-verify-mail-module {
  .m-verify-mail-head {
    margin-bottom: 64px;

    .m-verify-mail-page-brand-logo {
      width: 300px;
    }
  }

  .m-verify-mail-content {
    display: flex;

    .m-verify-mail-message {
      color: #fff;
      font-size: 16px;
      margin-right: 8px;
    }
  }
}