@import "paymentSettings/billing.main.paymentSettings.scss";
@import "billingSettings/billing.main.settings.scss";
@import "plans/billing.main.plans.scss";
@import "dashboard/billing.main.dashboard.scss";

.m-billing-main-tab-content {
	> .m-spinnerpage-component {
		margin: 32px auto;
	}

	.m-billing-main-section {
		.m-billing-main-section-title {
			font-size: 17px;
			font-weight: 500;
		}

		.m-billing-main-section-description {
			color: $gray1;
			font-size: 15px;
			margin-top: 8px;
		}

		.m-billing-main-section-content {
			color: $gray1;
			font-size: 10px;
			padding-top: 16px;
		}
	}

	.m-billing-main-section:not(:last-child) {
		border-bottom: 1px solid $light-gray4;
		margin-bottom: 24px;
		padding-bottom: 24px;
	}
}
