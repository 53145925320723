@import 'pages/WorkspaceUsers.scss';
@import 'pages/InviteUser.scss';
@import 'pages/WorkspaceTransfers.scss';

.m-workspaces-table-no-items {
  align-items: center;
  border: 1px solid $light-gray4;
  border-top: none;
  color: $text-color;
  display: flex;
  height: 42px;
  justify-content: center;
}
