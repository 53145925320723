.m-login-module {

  .m-login-head {
    margin-bottom: 64px;

    .m-login-page-brand-logo {
      width: 300px;
    }
  }

  .m-login-content {
    display: flex;
    height: 420px;

    .m-login-content-left {
      align-items: center;
      display: flex;
      width: 400px;
    }

    .m-login-content-right {
      align-items: center;
      display: flex;
      padding-left: 64px;
      flex-direction: column;
      
      .m-login-page-image {
        width: 400px;
        padding-top: 45px;

      }

      .m-login-content-right-captcha { 
        width: 400px;
        visibility: hidden;

          .m-login-content-right-captcha-link-button {
            font-size: 11px;
          }
          .m-login-content-right-captcha-section-text{
            font-size: 11px;
          }
        }

    }
  }
}