$profile-tab-width: 768px;

@import 'account/Profile.main.account';
@import 'activities/profile.activities';
@import 'apiKey/Profile.main.apiKey';
@import 'personalAccessToken/Profile.main.personalAccessToken';
@import 'securitySettings/profile.main.securitySettings';
@import 'preferences/profile.main.preferences';
@import 'security/security.main.scss';
@import 'docs/Profile.main.doc.scss';

.m-profile-main-module-content-tab
> .m-spinnerpage-component {
	margin-top: 32px;
}
