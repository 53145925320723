.m-suggestbox-component {
  .bp3-popover-wrapper {
    display: block;
    position: relative; // Necessary; otherwise 100% width applies to the viewpoint

    .bp3-popover-target {
      width: 100%;
      width: stretch;
    }

    .bp3-transition-container {
      width: 100%;
      
      .bp3-select-popover {
        width: 100%;
        min-width: 300px;
        max-width: 550px; // Can be adjusted on wish
        
        .bp3-menu {
          color: $gray1 !important;
          font-family: 'Roboto Flex', sans-serif !important;
          font-size: 16px !important;
          max-height: 300px;
          overflow: auto;
          
          .m-suggestbox-option {
            font-size: 16px;
            margin: 2px 0;
            padding: 6px 12px;
          }
          
          .m-suggestbox-option--selected:hover {
            background-color: $green5;
            color: #fff !important;
          }

          .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss.m-suggestbox-option,
          .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss.m-suggestbox-option--selected {
            color: #fff !important;
          }

          .bp3-menu-item {
            .bp3-menu-item-label {
              flex-shrink: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
            }

            .bp3-fill {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }

  .m-suggestbox-input-popover-closed {
    .bp3-popover {
      box-shadow: none;
    }
  }

  .m-suggestbox-input {
    border: 1px solid $light-gray3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 54px;
    padding: 8px 12px;

    .m-suggestbox-input-title {
      color: $gray1;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 2px;
      text-transform: uppercase;
    }

    input {
      border: none;
      color: $gray1;
      font-family: 'Roboto Flex', sans-serif;
      font-size: 16px;
      padding-left: 1px;
      padding-right: 20px;
      max-height: 21px;
      box-shadow: none !important;
    }
    
    input:focus {
      border-color: $light-gray1;
    }
    
    ::placeholder {
      color: $gray3;
    }

    input:disabled, textarea:disabled {
      background-color: $light-gray4;
      border: none;
      color: $gray3;
      cursor: not-allowed;
    }


  }

  .m-suggestbox-errors {
    align-items: center;
    display: flex;

    .m-suggestbox-errors-icon {
      color: $red5;
      margin-bottom: 2px;
    }

    .m-suggestbox-errors-content {
      margin-left: 4px;

      .m-suggestbox-error {
        color: $red5;
      }
    }
  }
}