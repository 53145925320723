.m-update-origin-form {

  .m-update-origin-form-custom-section {
    display: flex;
    margin-bottom: 12px;

    .m-update-origin-form-custom-section-left {
      color: $text-color;
      padding-right: 32px;
      width: 50%;

      .m-update-origin-form-custom-section-left-title {
        font-size: 16px;
        font-weight: 500;
      }

      .m-update-origin-form-custom-section-left-description {
        color: $gray3;
        font-size: 14px;
      }
    }

    .m-update-origin-form-custom-section-right {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .m-origin-form-custom-container {
      align-items: center;
      display: flex;
      margin-left: 16px;
      margin-top: 8px;

      .m-origin-form-custom-container-icon {
        flex-shrink: 0;
        color: $light-gray1;
        width: 32px;
      }

      .m-origin-form-custom-container-rest-container {
        width: 100%;

        .m-origin-form-custom-container-head {
          display: flex;
          margin-bottom: 2px;

          .m-origin-form-custom-container-title {
            color: $gray1;
            font-size: 12px;
            font-weight: 500;
            margin-right: 4px;
          }

          .m-origin-form-custom-container-description {
            color: $gray3;
            font-size: 12px;
          }
        }

        .m-origin-form-custom-container-field {
          .m-form-text-input-component {
            margin-bottom: 0;

            .m-form-text-input {
              min-height: 30px;
              padding: 6px 12px;

              .m-form-text-input-title {
                display: none;
              }

              input {
                font-size: 16px;
                line-height: 20px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  .m-update-origin-form-servers,
  .m-update-origin-form-custom-headers {

    .m-update-origin-form-servers-fields,
    .m-update-origin-form-custom-headers-fields {
      display: flex;

      .m-update-origin-form-servers-field,
      .m-update-origin-form-custom-headers-field {

        .m-update-origin-form-servers-field-title,
        .m-update-origin-form-health-check-field-title,
        .m-update-origin-form-custom-headers-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-update-origin-form-servers-field-input,
        .m-update-origin-form-health-check-field-input,
        .m-update-origin-form-custom-headers-field-input {
          width: 100%;
        }
      }

      .m-update-origin-form-servers-field:not(:last-child),
      .m-update-origin-form-custom-headers-field:not(:last-child) {
        flex: 1;
        margin-right: 1.5%;
      }

      .m-update-origin-form-servers-button,
      .m-update-origin-form-custom-headers-button {
        height: 33px;
      }
    }

    .m-update-origin-form-custom-headers-items {
      display: flex;

      .m-update-origin-form-custom-header {
        display: flex;
        height: 33px;

        .m-update-origin-form-custom-header-left {
          align-items: center;
          background-color: $brand-color-light;
          border: 1px solid $brand-color-light-border;
          border-radius: 3px 0 0 3px;
          color: #fff;
          display: flex;
          padding: 6px 12px 6px 6px;
        }

        .m-update-origin-form-custom-header-right {
          align-items: center;
          border: 1px solid $border-color;
          border-left: none;
          border-radius: 0 3px 3px 0;
          display: flex;
          padding: 6px 12px;
        }

        .m-update-origin-form-custom-header-button {
          background-color: inherit;
          border: none;
          border-radius: 3px;
          color: #fff;
          height: min-content;
          margin-right: 6px;
          padding: 2px;
        }

        .m-update-origin-form-custom-header-button:hover {
          background-color: $brand-color-light-hover;
        }
      }

      .m-update-origin-form-custom-header:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .m-update-origin-form-divider {
    background-color: $border-color;
    height: 1px;
    margin: 24px 0;
  }

  .m-update-origin-form-servers-items {
    .m-update-origin-form-server {
      display: flex;

      .m-update-origin-form-servers-field {
        .m-update-origin-form-servers-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-update-origin-form-servers-field-input {
          width: 100%;
        }
      }

      .m-update-origin-form-servers-field:not(:first-child).m-update-origin-form-servers-field:not(:last-child) {
        flex: 1;
        margin-right: 1.5%;
      }

      .m-update-origin-form-servers-remove-button {
        height: 33px;
        margin-right: 16px;
        margin-left: -32px;
        border: none;
      }

      .m-update-origin-form-servers-remove-button:hover {
        background-color: $gray1;
        color: white;
      }

      .m-update-origin-form-servers-button {
        height: 33px;
      }

      .m-update-origin-form-servers-field-hidden {
        display: none;
      }
    }
  }
}
