.m-logs-main-component {

  .m-logs-main-head-right {
    display: flex;
    flex-direction: row;

    .crs-btn {
      width: max-content;
    }

    .m-logs-main-head-button-icon {
      margin-right: 4px;
    }
  }

  .m-logs-main-content {
    padding-top: 16px;
    padding-bottom: 72px;

    .m-logs-main-collapse {
      background-color: $light-gray5;
      color: $gray2;
      margin: 10px 0;
      
      .m-logs-main-collapse-content {
        border: 1px solid $light-gray4;
        display: flex;
        padding: 10px 20px;

        .m-logs-main-collapse-apply {
          margin-left: auto;

          .m-logs-main-collapse-apply-button {
            background-color: $dark-gray5;
            border-color: $dark-gray4;
          }

          .m-logs-main-collapse-apply-button:hover {
            background-color: $dark-gray4;
            border-color: $dark-gray3;
          }

          .m-logs-main-collapse-apply-button.btn--disabled {
            background-color: $light-gray3;
            border-color: $light-gray3;
            border-radius: 3px;
            color: $gray5;
          }
        }

        button {
          color: #fff;
          margin: 5px;

          .m-logs-filter-button-icon {
            margin-right: 4px;
          }
        }

        button.disabled {
          background-color: $light-gray3;
          border-color: $light-gray3;
          border-radius: 3px;
          color: $gray5;
        }
        
        .twoxx {
          background-color: $turquoise4;
          border-color: $turquoise3;
          font-weight: 500;
        }
        
        .twoxx:hover {
          background-color: $turquoise3;
          border-color: $turquoise2;
        }

        .threexx {
          background-color: $cobalt5;
          border-color: $cobalt4;
          font-weight: 500;
        }

        .threexx:hover {
          background-color: $cobalt4;
          border-color: $cobalt3;
        }

        .fourxx {
          background-color: $orange5;
          border-color: $orange4;
          font-weight: 500;
        }

        .fourxx:hover {
          background-color: $orange4;
          border-color: $orange3;
        }

        .fivexx {
          background-color: $red4;
          border-color: $red3;
          font-weight: 500;
        }

        .fivexx:hover {
          background-color: $red3;
          border-color: $red2;
        }
      }
    }

    .m-logs-main-table {
      .m-logs-main-table-header {

        .status-code {
          width: 10%;
        }
  
        .date-time {
          width: 20%;
        }
  
        .ip-address {
          width: 15%; 
        }

        .size {
          width: 10%;
        }
      }
        
      .m-logs-main-table-loading-state {
        height: 240px;
        padding: 24px 0;
      }

      .m-logs-main-table-body {
        .m-logs-table-status-code {
          background-color: $dark-gray5;
          border: 1px solid $dark-gray4;
          border-radius: 3px;
          color: #fff;
          padding: 3px 6px 1px 6px;
        }

        .twoxx {
          background-color: $turquoise4;
          border-color: $turquoise3;
          font-weight: 500;
        }

        .threexx {
          background-color: $cobalt5;
          border-color: $cobalt4;
          font-weight: 500;
        }

        .fourxx {
          background-color: $orange5;
          border-color: $orange4;
          font-weight: 500;
        }

        .fivexx {
          background-color: $red4;
          border-color: $red3;
          font-weight: 500;
        }

        .bp3-popover-target {
          word-wrap: nowrap;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          
          .m-logs-table-path {
            color: $blue4 !important;
          }
          
          .m-logs-table-path:hover {
            color: $blue3 !important;
            text-decoration: underline !important;
          }
        }

        .expand-cell {
          text-align: right;
        }

        .m-logs-main-table-body-expanded-row {
          background-color: $light-gray5;

          .m-logs-main-table-body-expansion-cell:first-child {
            padding-top: 12px;
          }
          
          .m-logs-main-table-body-expansion-cell:last-child {
            padding-bottom: 12px;
          }

          .m-logs-main-table-body-expansion-cell {
            display: flex;
            padding: 2px 16px;
  
            .m-logs-main-table-body-expansion-cell-title {
              font-weight: 500;
            }
  
            .m-logs-main-table-body-expansion-cell-value {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .m-logs-main-table-items-no-item {
      align-items: center;
      border: 1px solid $light-gray4;
      border-top: none;
      color: $text-color;
      display: flex;
      height: 42px;
      justify-content: center;
    }
  }
}

.m-logs-table-tooltip-portal {
  .bp3-popover-content {
    max-width: 420px;

    .m-logs-table-tooltip-field-title {
      font-weight: 500;
    }
  }
}