@import 'pages/main/distributions.main.scss';
@import 'pages/create/distributions.create.scss';
@import 'pages/details/distributions.details.scss';

.m-distributions-welcome-page {
  
  .m-distributions-welcome-page-head {
    background-size: contain;
    border: 1px solid $border-color;
    border-radius: 3px;
    display: flex;
    height: 400px;

    .m-distributions-welcome-page-head-left {
      padding: 96px 64px;
      width: 60%;
      
      .m-distributions-welcome-page-head-left-title {
        font-size: 36px;
        letter-spacing: 1px;
        margin-bottom: 16px;
      }
      
      .m-distributions-welcome-page-head-left-description {
        color: $gray1;
        font-size: 16px;
      }
    }

    .m-distributions-welcome-page-head-right {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 40%;

      .m-distributions-welcome-page-head-right-box {
        background-color: #fff;
        border: 1px solid $border-color;
        padding: 24px;
        width: 300px;
      }
    }
  }
}

.m-distributions-no-item-found {
  padding: 16px 0;

  .m-distributions-no-item-found-content {
    align-items: center;
    border: 1px solid $border-color;
    border-radius: 3px;
    display: flex;
    height: 200px;
    justify-content: center;
    padding: 32px;
    position: relative;

    .m-distributions-no-item-found-description {
      font-size: 17px;
    }
  }
}