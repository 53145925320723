.m-logs-raw-component {
  .m-logs-raw-head {
    .crs-page-head-right {
      width: 500px;
    }
  }
  .m-logs-raw-table {
    margin-top: 16px;
    
    .status{
      width: 5%;
    }
    .dist{
      width: 30%;
    }
    .size{
      width: 20%;
    }
    .last_activated_at{
      width: 35%;
    }
    .raw_logs{
      width: 10%;
    }
  }
  .m-logs-raw-details-table {
    margin-top: 16px;
    
    .date {
      width: 40%;
    }
    .size{
      width: 20%;
    } 
    .download {
      width: 50%;
    }

    .m-logs-raw-table-cell-collecting {
      color: #A7B6C2;
      font-size: 12px;
      font-weight: 500;

      i {
        margin-left: 4px;
      }
    }

    .m-logs-raw-table-cell-link {
      color: $blue4 !important;
    }
    
    .m-logs-raw-table-cell-link:hover {
      color: $blue3 !important;
      text-decoration: underline !important;
    }
  }

  .m-logs-raw-table-items-no-item {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }
}

.activation-date{
  display: flex;
  .score{
    padding: 0px 10px 0px 10px;
  }
}