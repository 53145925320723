.m-m-service-route-pages-main {
    height: 100%;

    .m-m-service-route-pages-main-content {
        padding: 16px 0;

        .m-m-service-route-pages-main-content-items {
            .m-m-service-route-pages-main-content-items-table {
                display:block;
                overflow:auto;
                max-height:600px;
                width:100%;

                .head-visibility {
                    visibility: collapse;
                }
            }

            .m-m-service-route-pages-main-content-items-no-item {
                align-items: center;
                border: 1px solid $light-gray4;
                border-top: none;
                color: $text-color;
                display: flex;
                height: 42px;
                justify-content: center;
            }

            .method {
                width: 10%;
            }

            .operation {
                width: 10%;
            }

            .path {
                width: 55%;
            }

            .permission {
                width: 20%;
            }

            .options {
                width: 5%;
            }

            .status {
                width: 10%;
            }

            .health-status {
                width: 10%;
                padding-left: 5px;
            }

            .health-service {
                width: 20%;
            }

            .health-time {
                width: 20%;
            }

            .health-datetime {
                width: 20%;
            }

            .health-entries {
                width: 20%;
            }

            .health-options {
                width: 10%;
            }

            .health_check_table {
                &__content_table {
                    display: block;
                    overflow: auto;
                    max-height: 600px;
                    width: 100%;

                    .head-visibility {
                        visibility: collapse;
                    }
                }

                &__row_bottom_disabled {
                    border-bottom: 1px solid rgba(235, 241, 245, 0) !important;
                }

                &__row_vertical_line {
                    background-image: linear-gradient(#dedede, #e2e2e2);
                    background-size: 2px 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .bp3-popover-wrapper {
                        display: ruby-text;
                        left: 0;
                        margin-left: 0;
                    }
                }
            }

            .service {
                width: 25%;

                .service-name {
                    margin: 0 !important;
                    color: $dark-gray4;
                    font-weight: 500;

                    &__healthy {
                        color: $green5;
                        margin: 0 !important;
                        font-weight: 500;
                    }
                    &__degraded {
                        color: $orange5;
                        margin: 0 !important;
                        font-weight: 500;
                    }
                    &__unhealthy {
                        color: $red5;
                        margin: 0 !important;
                        font-weight: 500;
                    }
                }
            }

            .time {
                width: 20%;
            }

            .datetime {
                width: 25%;
            }

            .entries {
                width: 10%;
            }
        }
    }
}

.service-route-detail-dialog {
    position: relative;
    &__info {
        display: block;
        float: right;
        top: 3px;
        position: absolute;
        right: 65px;
        &__table {
            tbody {
                tr {
                    td {
                        font-size: 13px;
                        overflow: hidden;
                        padding: 2px;
                        &:first-child {
                            min-width: 85px !important;
                            width: 85px !important;
                        }
                        width: 100%;
                    }
                }
            }
        }
    }
    .m-basic-dialog-head-title {
        font-size: 17px !important;
    }
    .m-basic-dialog-container {
        width: 800px !important;
        height: 450px;
        padding: 15px !important;
    }
    .m-basic-dialog-content {
        padding-top: 0px !important;
    }

    .entries-table {
        display: inline-grid;
        grid-template-columns: auto auto auto auto auto;
    }

    .entries-row {
        width: 120px;
        display: block;
        margin: 5px;
        border: 1px solid $light-gray1 !important;
        padding: 5px;

        .entries-key-title {
            display: block;
            font-weight: bold;
            font-size: 0.83em;
        }
    }
}

.service-detail-section-content {
    margin: 10px 0px;

    &__title {
        color: #5c7080;
        font-size: 12.5px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 2px;
        text-transform: uppercase;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding-bottom: 5px;
    }

    &__label {
        color: #5c7080;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 2px;
        text-transform: uppercase;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding-bottom: 5px;
    }

    .description-text-area {
        .m-form-text-input {
            height: 120px !important;
            justify-content: inherit !important;
        }
    }
}

.service-detail-divider {
    background-color: $border-color;
    height: 1px;
    margin: 24px 0;
}

.service-detail-callout {

}
