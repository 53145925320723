.upload-progress {
  display: flex;
  align-items: center;
  width: 550px;
  height: 75px;
  position:absolute;
  bottom:0;
  left: 25%;
  border: 1px solid $border-color;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: white;

  .upload-progress-content {
    width: 80%;
    .upload-progress-description {
      display: flex;
      margin-bottom: 8px;

      .upload-progress-description-text {
        font-weight: 500;
        font-size: 16px;
      }

      .upload-progress-description-name {
        width: 50%;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        font-size: 16px;
      }

      .upload-progress-description-quantity {
        margin-left: auto;
      }
    }
  }

  .upload-progress-details {
    width: 20%;
    display: flex;
    justify-content: flex-end;

    .upload-progress-details-button {
      margin-left: 5px;
      width: auto;
    }
  }

  .upload-progress-details-close-button {
    padding: 0px;
    margin-left: 5px;
    background: none;
    position: absolute;
    left: 96%;
    bottom: 68%;
    border: none;
  }
  
}
.upload-progress-details-dialog {
  .upload-progress-details-dialog-file {
    display: flex;
    background-color: #A854A840;
    padding: 8px;
    margin-bottom: 16px;

    .upload-progress-details-dialog-file-name {
      font-size: 12px;
      color: $dark-gray1;
    }
    .upload-progress-details-dialog-file-size{
      font-size: 12px;
      margin-left: auto;
      color: $dark-gray1;
    }
  }

  .upload-progress-details-dialog-button {
    width: 100%;
  }
}