  .circle-timer{
    margin-bottom: 10px;

    .timer-wrapper {
      display: flex;
      justify-content: center;
    }
    
    .timer {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
    }
    
    .text {
      color: #aaa;
    }
    
    .value {
      font-size: 20px;
    }
    
  }
