.cloner__main {
  .m-tooltip-component{
    line-height: 12px!important;
    height: 20px!important;
  }

  &__container {
    &__content {
      &__title-error {
        padding-bottom: 10px;
        color: red;
      }
      &__box {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        &__item {
          .m-section-content-component {
            margin-top: 0px;
            padding-left: 10px;
          }
        }
      }

      &__section--dynamic {
        display: flex;
        flex-direction: row;
        flex-direction: column;
        height: 33px;
        column-gap: 21px;
      }

      &__section {
        &__title {
          padding-bottom: 5px;
          color: #5c7080;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }
        &__multiple {
          display: flex;
          flex-direction: row;
          column-gap: 10px;
          padding-left: 5px;
          height: 60px;

          .m-selectbox-button-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__form-group {
          flex-direction: column;
          flex-wrap: nowrap;
          padding-bottom: 16px;
          .m-form-text-input {
            padding: 0 !important;
          }
          &__custom-input {
            border: 1px solid #e1e8ed;
            border-radius: 3px;
            color: #5c7080;
            font-family: "Roboto Flex", sans-serif;
            font-size: 14px;
            line-height: 14px;
            outline: none;
            padding: 10px;
            cursor: pointer;
            margin-top: 10px;
            width: 258px;
            min-height: 33px;
          }
          &__title {
            color: #5c7080;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 1px;
            margin-bottom: 2px;
            text-transform: uppercase;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
          }
          &__select-box {
            width: 190px;
          }
          &__check-box {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            column-gap: 17px;

            .m-checkbox-component {
              padding-bottom: 3px;
              min-width: 145px;
              .m-checkbox-component-label {
                font-size: 14px;
              }
            }
          }
          &__input {
            input {
              font-size: 16px !important;
              height: 32px !important;
              min-width: 350px !important;
            }
          }
        }
      }
    }
  }
}

.cron-expression__dialog {
  .m-basic-dialog-container {
    width: 650px !important;
  }
}

.cron_builder {
  background-color: white !important;
  border: none !important;
}

.cron_builder .well {
  padding: 6px !important;
  background-color: #f5f5f500 !important;
  border: 1px solid #eaeaea8a !important;
  text-align: left;
  // box-shadow: none !important;

  input[type="checkbox"] {
    accent-color: #a854a8;
  }
  input[type="radio"] {
    accent-color: #a854a8;
  }
}
.cron-builder-bg {
  background-color: #ebf1f5 !important;
  color: #515151 !important;
}
.nav.nav-tabs {
  border-bottom: 2px solid #ebf1f5 !important;
}
.cron_builder .nav-tabs .nav-link.active {
  border-bottom: 2px solid #2b95d6 !important;
  color: #2b95d6 !important;
}
.cron_builder_bordering {
  border: none !important;
}

.cron_builder .nav-tabs .nav-link:focus,
.cron_builder .nav-tabs .nav-link:hover {
  background-color: white !important;
  border-bottom: 2px solid #ffa950 !important;
  color: #ff9424 !important;
}
