.m-progressbar-component {
	.bp3-progress-bar {
		height: 10px;
	}

	.m-progressbar-progress {
		color: white;
		display: none;
		font-weight: 500;
	}

	.m-progressbar-progress.--empty {
		color: $gray1;
	}
}

.m-progressbar-component:hover {
	height: 16px;

	.bp3-popover-target,
	.bp3-progress-bar {
		height: 16px;
	}

	.m-progressbar-progress {
		display: inline-block;
		position: relative;
		bottom: 16px;
		z-index: 1;
	}
}

.m-progressbar-tooltip-component-portal {
	.bp3-popover-content {
		text-transform: capitalize;
	}
}