.m-success-notification-dialog {

  .m-notification-component {
    padding: 12px 16px;
  }

  .m-success-notification-dialog-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    text-align: center;
  }

  .m-success-notification-dialog-text {
    text-align: center;
  }
}