.m-generic-form {

  .m-generic-form-content {

    .m-generic-form-policy {

      .m-generic-form-policy-title {
        color: $gray1;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 2px;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .m-generic-form-policy-tooltip {
          font-size: inherit;
          margin-left: 2px;

          .crs-tooltip-component-content {
            padding: 0 4px;
          }
        }
      }

      .m-generic-form-policy-content {
        .m-form-text-input-component {
          margin-bottom: 0;

          .m-form-text-input {
            min-height: 30px;
            padding: 6px 12px;

            .m-form-text-input-title {
              display: none;
            }
            
            input {
              font-size: 16px;
              line-height: 20px;
              padding: 0;
            }
          }
        }

        .m-generic-form-custom-container {
          align-items: center;
          display: flex;
          margin-left: 16px;
          margin-top: 8px;

          .m-generic-form-custom-container-icon {
            flex-shrink: 0;
            color: $light-gray1;
            width: 32px;
          }

          .m-generic-form-custom-container-rest-container {
            width: 100%;

            .m-generic-form-custom-container-head {
              display: flex;
              margin-bottom: 2px;

              .m-generic-form-custom-container-title {
                color: $gray1;
                font-size: 12px;
                font-weight: 500;
                margin-right: 4px;
              }
              .m-generic-form-custom-container-description {
                color: $gray3;
                font-size: 12px;
              }
            }

            > .m-generic-form {
              .m-generic-form-content {
                display: flex;

                > .m-generic-form-policy {
                  flex-grow: 1;
                  margin-bottom: 0;

                  &:not(:last-child) {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }

        .m-generic-form-textbox-buttons {
          float: right;
          position: relative;
          bottom: 34px;
          right: 4px;
          margin-bottom: -34px;

          .m-generic-form-textbox-button {
            border: none;

            &:hover {
              background-color: $gray3;
              color: white;
            }

            &:active {
              background-color: $gray2;
              color: white;
            }
          }
        }
      }
    }

    .m-generic-form-policy:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .m-generic-form-buttons {
    margin-top: 16px;
  }
}

.m-generic-form-custom-container-link {
  color: $indigo3 !important;

  .m-generic-form-custom-container-link:hover {
    color: $cobalt3 !important;
    text-decoration: none !important;
  }
}