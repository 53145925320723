.m-m-certificates-pages-main {
  .m-m-certificates-pages-main-table-head {
    display: flex;
    height: 30px;

    .m-m-certificates-pages-main-table-head-add-button {
      margin-right: 12px;
    }

    .m-m-certificates-pages-main-table-head-search {
      height: 30px;
      width: 256px;
    }
  }

  .m-m-certificates-pages-main-content {
    padding: 16px 0;

    .m-m-certificates-pages-main-content-head {
      display: flex;
      height: 30px;
      margin-bottom: 16px;

      .m-m-certificates-pages-main-content-head-search {
        height: 30px;
        margin-left: auto;
        width: 256px;
      }

      .m-m-certificates-pages-main-content-head-select-options-container {
        .m-m-certificates-pages-main-content-head-select-options {
          align-items: center;
          border: 1px solid $light-gray3;
          border-radius: 3px;
          color: $gray1;
          display: flex;
          height: 30px;
          padding: 0 8px;

          :hover {
            color: $text-color;
            cursor: pointer;
          }
        }
      }

      .m-m-certificates-pages-main-content-head-view-mode-switch-container {
        margin-left: auto;
      }
    }

    .m-m-certificates-pages-main-content-items {
      .m-m-certificates-pages-main-content-items-no-item {
        align-items: center;
        border: 1px solid $light-gray4;
        border-top: none;
        color: $text-color;
        display: flex;
        height: 42px;
        justify-content: center;
      }
      
      .m-m-certificates-pages-main-content-box-view {
        display: flex;
        flex-wrap: wrap;
        
        .m-m-certificates-pages-main-content-box {
          margin-bottom: 16px;
          min-width: 592px;
          max-width: calc(100% / 2 - 8px);
          width: -webkit-fill-available;
          width: -moz-available;

          .m-m-certificates-pages-main-content-box-title {
            display: flex;

            .m-m-certificates-pages-main-content-box-title-button {
              background-color: inherit;
              border: none;
              color: $blue4;
              padding: 0;
              font-family: inherit;
              font-size: inherit;
              font-weight: inherit;
              height: min-content;
            }
  
            .m-m-certificates-pages-main-content-box-title-button:hover {
              color: $blue2;
            }

            .m-m-certificates-pages-main-content-box-title-status {
              align-items: center;
              display: flex;
              justify-content: center;
              margin-left: 4px;
            }
          }
        }

        .m-m-certificates-pages-main-content-box:nth-child(2n+1) {
          margin-right: 16px;
        }

        .m-m-certificates-pages-main-content-box:last-child {
          margin-bottom: 0;
        }

        .m-m-certificates-pages-main-content-box:not(:last-child):nth-last-child(2):nth-child(2n-1) {
          margin-bottom: 0;
        }
      }

      .crs-table-cell {
        span {
          height: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          display: block;
        }
      }

      .options-cell {
        span {
          height: 24px;
        }
      }

      .selection-cell {
        span {
          height: 17px;
        }

        .success {
          color: $green4;
        }

        .error {
          color: $red4;
        }

        .warning {
          color: $gold4;
        }
      }

      .issuer {
        width: 18%;
      }

      .valid-from {
        width: 16%
      }

      .valid-to {
        width: 16%
      }

      .m-certificates-pages-main-content-table-valid-to-error {
        font-weight: 500; 
        color: $red4;
      }
      
      .m-certificates-pages-main-content-table-valid-to-warning {
        font-weight: 500; 
        color: $gold4;
      }
    }

    .m-m-certificates-pages-main-content-items--box {
      .m-m-certificates-pages-main-content-items--box-head {
        display: flex;
        margin-bottom: 16px;

        .m-m-certificates-pages-main-content-items--box-head-add-button {
          margin-left: auto;
        }
      }

      .m-m-certificates-pages-main-content-items--box-content {
        .m-m-certificates-pages-main-content-items--box-box {
          width: 600px;
        }
      }

      .m-m-certificates-pages-main-content-items--box-pagination {
        margin-top: 16px;
      }
    }
  }
}

.m-certificates-main-delete-dialog,
.m-certificates-main-copy-dialog {
  &-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  &-button:not(:last-child) {
    margin-right: 8px;
  }
}