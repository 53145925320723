.m-input-component {
  width: inherit;

  input {
    border: 1px solid $light-gray3;
    border-radius: 3px;
    color: $gray1;
    font-family: 'Roboto Flex', sans-serif;
    font-size: 16px;
    padding: 6px 32px 6px 12px;
    width: inherit;
  }

  input:focus {
    border-color: $light-gray1;
  }

  ::placeholder {
    color: $gray3;
  }
}