.disable_content {
  opacity: 0.5;
  pointer-events: none;
}

.crs-pgn__disabled{
  opacity: 0.7;
  pointer-events: none;
}

.datatable {
  position: relative;
  
  .disabled_column{
    height: 40px;
  }

  .crs-table-body {
    tr:nth-child(even) {
      background-color: #f6f8fa;
    }
  }

  .action-buttons {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
  }

  .crs-table-cell {
    .success-box {
      background-color: #65bb68;
    }
    .numeric-box {
      background-color: $gray2;
    }
    .bp3-button-groups {
      display: flex;
      gap: 6px;
      .bp3-button {
        min-height: 20px;
        min-width: 24px;
        box-shadow: none;
        &:hover {
          background-color: $green4 !important;
          box-shadow: none !important;
        }
      }

      .bp3-button-primary {
        background-color: $gray2 !important;
        &:hover {
          background-color: $gray3 !important;
        }
      }

      .bp3-button-danger {
        background-color: $red3 !important;
        &:hover {
          background-color: $red4 !important;
        }
      }

      .bp3-button-open-page {
        background-color: $blue4 !important;
        &:hover {
          background-color: $blue5 !important;
        }
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .link-button,
    .bp3-popover-target {
      cursor: pointer;
      text-decoration: none !important;
      text-underline-offset: 2px;
      color: #02619c !important;
      .bp3-fill {
        text-underline-offset: 2px;
      }
      &:hover {
        color: #0682cf !important;
        .numeric-box {
          background-color: #8a9ba8 !important;
        }
      }
    }
    .link-underline-button {
      text-decoration: underline !important;
      text-underline-offset: 2px;
      .bp3-fill {
        text-decoration: underline !important;
        text-underline-offset: 2px;
      }
      &:hover {
        .numeric-box {
          background-color: #8a9ba8 !important;
        }
      }
    }
  }
  &__empty {
    text-align: center;
    height: 60px;
  }
  &__deleted {
    opacity: 0.5;
    color: #d13212;
  }
  .bp3-tag {
    text-align: center;
    min-width: 33px;
    min-height: 20px;
  }
  .intent-box {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .crs-table-cell {
    word-break: break-all;
    img {
      vertical-align: middle;
    }
  }

  &__loading {
    z-index: 400;
    position: absolute;
    top: 0rem;
    right: 0rem;
    left: 0rem;
    bottom: 0rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    overflow: hidden;
  }
  &__column {
  }

  &__row {
  }
}
