.m-profile-main-activities-tab {
  .m-profile-main-activities-filter {
    padding-bottom: 16px;
  }

  .m-profile-main-activities-table {
    .ws {
      width: 25%;
    }

    .service {
      width: 10%;
    }

    .action {
      width: 50%;
    }

    .updated {
      width: 25%;
    }

    .m-profile-main-activities-table-loading {
      height: 240px;
      padding: 24px 0;
    }
  }

  .m-profile-main-activities-table-no-items {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }

  .m-profile-main-activities-table-tooltip-portal {
    .bp3-popover-content {
      max-width: 420px;

      .m-profile-main-activities-table-tooltip-field-title {
        font-weight: 500;
      }
    }
  }
}