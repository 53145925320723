.m-workspaces-invite-user-module {
  padding-bottom: 72px;
  .m-workspaces-invite-user-role-content {
    padding-bottom: 72px;

    .pbox {
      display: flex;
      height: 500px;
      margin-top: 10px;
      //min-height: 100vh;

      &__form {
        padding: 0px !important;
        border: none !important;

        &__group {
          padding-bottom: 10px;
          &__title {
            font-size: 16px;
            font-weight: 500;
          }
          &__description {
            color: #8a9ba8;
            font-size: 14px;
          }
          &__field {
            padding-top: 5px;

            .m-form-text-field {
              margin-bottom: 0 !important;
            }
            .m-form-text-field-left {
              width: 0 !important;
              padding-right: 0 !important;
            }
            .m-form-text-field-right {
              margin-left: 0 !important;
              width: 100% !important;
            }
          }
        }

        &__button {
          float: right;
        }
      }
      &__column {
        width: 500px;
        border: 1px solid $border-color;

        &__header {
          &__title {
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $border-color;
            padding: 10px;
            padding-left: 13px;

            i {
              cursor: pointer;
              color: #646464;
            }
          }
          &__search-bar {
            position: relative;
            padding: 10px;
            border-bottom: 1px solid $border-color;
            &__icon {
              position: absolute;
              z-index: 9;
              color: #9f9f9f;
              padding-top: 5px;
              padding-left: 11px;
            }
            &__input {
              width: 100%;
              height: 30px;
              border: 1px solid $border-color;
              padding-left: 35px;

              border-top-color: white;
              border-left-color: white;
              border-right-color: white;
            }
          }
        }
        &__content {
          height: 410px;
          display: block;
          position: relative;
          overflow: auto;

          &__disable{
            pointer-events:none;
            opacity: 0.5;
          }
          .bp3-tree-node-secondary-label {
            display: block;
            position: relative;
            width: 50px;
            height: 20px;
            a {
              position: absolute;
              right: 0px;
              width: 100%;
              height: 100%;
              z-index: 99999;

              .m-tooltip-component-with-content {
                padding-left: 30px;
              }
            }
            .bp3-popover-wrapper {
              color: #646464;
            }
          }

          .bp3-tree-node-label-content-text {
            height: 30px;
            padding-top: 7px;
          }

          i {
            padding-right: 2px;
          }
          .bp3-tree-node-content {
            padding-left: 5px;
            cursor: pointer;
            .disabled {
              opacity: 0.3;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.m-workspaces-invite-user-custom-role-name-dialog {
  .m-form-text-input-component {
    margin-top: 12px;
  }

  .m-basic-dialog-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .crs-btn {
      margin: 0 8px;
    }

    .crs-btn:last-child {
      margin-right: 0;
    }
  }
}
