.m-multiplecheckbox-component {

  .m-multiplecheckbox-errors {
    align-items: center;
    display: flex;

    .m-multiplecheckbox-errors-icon {
      color: $red5;
      margin-bottom: 2px;
    }

    .m-multiplecheckbox-errors-content {
      margin-left: 4px;

      .m-multiplecheckbox-error {
        color: $red5;
      }
    }
  }
  
  .m-multiplecheckbox-content {
    display: flex;
    flex-wrap: wrap;

    .m-multiplecheckbox-checkbox:hover {
      
      label {
        color: $text-color;

        .m-multiplecheckbox-checkbox-label {
          .m-multiplecheckbox-checkbox-label-checked {
            border-color: $light-gray1;
          }
        }
      }
    }
    
    .m-multiplecheckbox-checkbox {
      display: flex;
      margin-bottom: 8px;
      margin-right: 16px;
  
      label {
        color: $gray1;
        cursor: pointer;
  
        .m-multiplecheckbox-checkbox-label {
          align-items: center;
          display: flex;
  
          .m-multiplecheckbox-checkbox-label-checked {
            border: 1px solid $border-color;
            border-radius: 2px;
            height: 20px;
            margin-right: 6px;
            width: 20px;
  
            .m-multiplecheckbox-checkbox-label-checked-content {
              margin: 2px;
              height: 14px;
              width: 14px;
            }
          }

          .m-multiplecheckbox-checkbox-label-text {
            padding-top: 1px;
          }
        }
      }
  
      input {
        display: none;
      }
  
      input:checked + label {
        color: $gray1;
  
        .m-multiplecheckbox-checkbox-label {
  
          .m-multiplecheckbox-checkbox-label-checked {
  
            .m-multiplecheckbox-checkbox-label-checked-content {
              background-color: $green5;
            }
          }
        }
      }
  
      input:hover + label {
  
        .m-billing-main-module-add-credit-card-option-label {
  
          .m-multiplecheckbox-label-checked {
            border-color: $gray5;
  
            .m-multiplecheckbox-label-checked-content {
              
            }
          }
        }
      }
    }
  }
}