.m-form-text-input-component {
  margin-bottom: 12px;

  .m-form-text-input-label {
    display: flex;
    position: relative;

    .m-form-text-input-container {
      margin-left: auto;
      position: relative;

      .m-form-text-input {
        background-color: #fff;
        border: 1px solid $light-gray3;
        border-radius: 3px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        // min-height: 54px;
        padding: 8px 12px;

        .m-form-text-input-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        input,
        textarea {
          border: none;
          color: $gray1;
          font-family: "Roboto Flex", sans-serif;
          font-size: 16px;
          padding-right: 20px;
          width: 100%;
        }
        .m-form-text-input-data-button {
          background: inherit;
          border: none;
          height: inherit;
          position: absolute;
          right: 12px;
          padding: 4px;
        }
        input:focus,
        textarea:focus {
          border-color: $light-gray1;
        }

        ::placeholder {
          color: $gray3;
        }

        input:disabled,
        textarea:disabled {
          background-color: $light-gray4;
          border: none;
          color: $gray5;
          cursor: not-allowed;
        }

        input:disabled::placeholder {
          color: $gray5;
        }
      }

      .m-form-text-input--disabled {
        background-color: $light-gray4;
        border: 1px solid $light-gray4;
        color: $gray5;
        cursor: not-allowed;

        .m-form-text-input-title {
          color: $gray3;
        }
      }

      .m-form-text-input--error {
        border-color: $red4;
      }

      .m-form-text-input-icon {
        position: absolute;
        right: 10px;
        top: 6px;
      }

      .m-form-text-input-icon--success {
        color: $green4;
      }

      .m-form-text-input-icon--error {
        color: $red4;
      }
    }

    .m-form-text-input-single {
      width: 100%;
    }
  }

  .m-form-text-input-error {
    align-items: center;
    color: $red4;
    display: flex;
    font-size: 12px;
    margin-top: 2px;
    .m-form-text-error {
      margin-top: 3px;
    }

    .m-form-text-input-error-icon {
      margin-right: 3px;
      margin-bottom: 2px;
    }
  }
}
.phone-drop-down {
  background-color: #fff;
}
.flag-dropdown {
  background-color: #fff !important;
  border: none !important;
  margin-left: -5px !important;
  .selected-flag {
    background-color: #ebf1f5 !important;
  }
}
.form-control {
  padding-left: 40px !important;
  height: 21px !important;
}
.country-list {
  overflow: hidden;
}

.react-tel-input .country-list .country{
  padding-left: 16px!important;
}