.m-profile-main-api-key-tab {

  .m-profile-main-api-key-callout {
    margin-bottom: 10px;
  }

  .m-profile-main-api-key-tab-box-no-key {
    width: $profile-tab-width;
  }

  .m-profile-main-api-key-tab-box-key-existing {
    width: $profile-tab-width;

    .m-profile-main-api-key-tab-box-key-existing-spinner-page-container {
      height: 57px;
    }

    .m-profile-main-api-key-tab-box-key-existing-key-container {
      border: 1px solid $border-color;
      border-radius: 3px;
      display: flex;
      padding: 8px 12px;

      .m-profile-main-api-key-tab-box-key-existing-key {
        flex-grow: 1;

        .m-profile-main-api-key-tab-box-key-existing-key-title {
          color: $gray3;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-profile-main-api-key-tab-box-key-existing-key-content {

          textarea {
            background-color: inherit;
            border: none;
            color: $text-color;
            font-family: 'Roboto Flex', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            height: 20px;
            overflow: hidden;
            width: 100%;
          }

          .m-profile-main-api-key-tab-box-key-existing-key-content-copied-message {
            color: $light-gray1;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .m-profile-main-api-key-tab-box-key-existing-utility-buttons {
        align-items: center;
        display: flex;
        margin-left: auto;

        .m-profile-main-api-key-tab-box-key-existing-utility-button {
          background: inherit;
          border: none;
          height: inherit;
          padding: 4px;
        }

        .m-profile-main-api-key-tab-box-key-existing-utility-button:hover {
          background-color: $light-gray1;
        }

        .m-profile-main-api-key-tab-box-key-existing-utility-button:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    .m-profile-main-api-key-tab-box-key-existing-button-container {
      margin-top: 12px;
    }
  }
}
