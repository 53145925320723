.security {
  border: 1px solid #e4e7eb;
  border-radius: 6px;

  .methods {
    color: #5a5a5a;
    padding: 20px;
    border-radius: 6px;

    &__header {
      font-size: 16px;
      margin-bottom: 5px;
      color: #3a4a59;
      font-weight: 500;
      border-bottom: 1px solid #e4e7eb;
      height: 30px;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      padding: 15px 0;
      border-bottom: 1px solid #e4e7eb;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      &-icon {
        margin-right: 8px;
        align-self: flex-start;
        color: #5c7080;
      }

      &-content {
        padding: 0 2px;
        position: relative;
        color: #5c7080;
        font-size: 16px;
        grid-gap: 20px;
        gap: 0px;
        display: flex;
        align-items: flex-start;
        margin-top: 2px;
        flex-grow: 1;
        flex-direction: column;
      }

      &__header {
        display: flex;
        padding-top: 3px;

        &-title {
          color: #5c7080;
          font-weight: bold;
          padding-bottom: 6px;
          font-size: 14px;
        }

        &-status {
          display: inline-block;
          margin-left: 10px;
          padding: 3px 5px;
          border-radius: 4px;
          color: #ffffff;
          font-size: 12px;

          &.configured {
            background-color: #4580e6;
            height: 100%;
          }
        }
      }

      &-description {
        font-size: 14px;
        color: #5C7080;
      }

      &-restriction {
        font-size: 14px;
        color: $orange4;
      }

      &-action {
        padding: 8px 0;
        font-size: 14px;

        .button2 {
          width: 75px;
        }
      }
    }
  }
}

.verify-form {
  &__description {
    padding-bottom: 15px;
  }

  .verify-code-btn {
    width: 100%;
    height: 40px;
    font-size: 17px;
    ;
  }

  .qrcode {
    width: 100%;
    text-align: center;

    img {
      width: 200px;
    }
  }

  .resend {
    display: flex;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.security-confirmation-buttons {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  flex-direction: row;
  justify-content: flex-end;
}