.m-profile-main-module {
  padding-bottom: 96px;

  .m-profile-main-module-content {
    .m-profile-main-module-content-user-box {
      border: 1px solid $border-color;
      border-radius: 3px;
      display: flex;
      padding: 24px;

      .m-profile-main-module-content-user-box-profile-picture-container {
        height: 140px;
        margin-right: 48px;
        width: 140px;

        .m-profile-main-module-content-user-box-profile-picture {
          border-radius: 50%;
          height: 140px;
          width: 140px;
        }
      }

      .m-profile-main-module-content-user-box-details {
        width: 100%;

        .m-profile-main-module-content-user-box-name {
          border-bottom: 1px solid $border-color;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 12px;
          padding-bottom: 12px;
        }

        .m-profile-main-module-content-user-box-line {
          display: flex;

          .m-profile-main-module-content-user-box-field {
            flex: 1;

            .m-profile-main-module-content-user-box-field-title {
              color: $text-color;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1px;
              text-transform: uppercase;
            }

            .m-profile-main-module-content-user-box-field-value {
              color: $gray1;
              font-size: 16px;
            }
          }

          .m-profile-main-module-content-user-box-field:not(:last-child) {
            margin-right: 16px;
          }
        }

        .m-profile-main-module-content-user-box-line:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    .m-profile-main-module-email-verify-notification {
      background-color: rgba(255, 115, 115, 0.2);
      border: 3px dashed rgba(255, 115, 115, 0.6);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 24px;
      padding: 24px;

      .m-profile-main-module-email-verify-notification-resend-button-container {
        padding-top: 16px;

        .m-profile-main-module-email-verify-notification-resend-button {
          border: none;
        }
      }
    }
  }

  .m-profile-main-section {
    .m-profile-main-section-title {
      color: #3a4a59;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e4e7eb;
      padding-bottom: 8px;
    }

    .m-profile-main-section-content {
      color: $gray1;
      font-size: 16px;
      margin-top: 24px;
    }
  }

  .m-profile-main-section:not(:last-child) {
    border-bottom: 1px solid $light-gray4;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

@import "tabs/Profile.main.tabs.scss";