.m-radio-group-component {

  .bp3-control.bp3-inline {
    margin-right: 16px !important;
  }
  
  .bp3-control {
    margin-bottom: 0 !important;
  }

  .bp3-control input:checked ~ .bp3-control-indicator {
    background-color: $green4 !important;
    box-shadow: none !important;
  }
}