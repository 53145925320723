@import "./tabs/stats.main.tabs.scss";

.m-stats-main-component {
  padding-bottom: 72px;

  .m-stats-main-filter {
    padding: 16px 0;
  }

  .m-stats-main-content {
    padding-top: 12px;
    display: flex;

    .m-stats-main-content-sidebar {
      margin-right: 24px;
      min-width: 136px;
    }

    .m-stats-main-content-tab-container {
      width: calc(100% - 160px);
    }
  }
}



.m-visitors-main-component {
  padding-bottom: 72px;

  .m-visitors-main-selectboxes {
    display: flex;

    .m-visitors-main-selectbox {
      flex: 1 1;
      margin: 16px 0;

      .m-visitors-main-selectbox-title {
        color: $gray1;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 2px;
        text-transform: uppercase;
      }
    }

    .m-visitors-main-selectbox:not(:last-child) {
      margin-right: 16px;
    }
  }

  .m-visitors-main-filter {
    padding: 16px 0;
  }

  .m-visitors-main-content {
    display: flex;
    padding-top: 12px;

    .m-visitors-main-content-sidebar {
      margin-right: 24px;
      min-width: 136px;
    }

    .m-visitors-main-content-tab-container {
      width: calc(100% - 160px);
    }
  }
}