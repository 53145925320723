@import 'customRules/waf.managePolicy.customRules';
@import 'managedRules/waf.managePolicy.managedRules';
@import 'general/waf.managePolicy.general';

.m-waf-manage-policy-component {
  .m-error-page-component {
    margin-top: 16px;
  }

  .m-waf-manage-policy-section {
    &:not(:last-child) {
      border-bottom: 1px solid #EBF1F5;
      margin-bottom: 24px;
      padding-bottom: 24px;
    }
  
    &-title {
      font-size: 20px;
      font-weight: 500;
    }
  }
}
