.m-mixed-tag-input {
	&-component {
		margin-bottom: 12px;
	}

	&-container {
		background-color: #fff;
		border: 1px solid $light-gray3;
		border-radius: 3px;
		padding: 6px 12px;
		height: 34px;
		display: flex;
		align-items: center;

		&.--disabled {
			background-color: $light-gray4;
			border: 1px solid $light-gray4;
			color: $gray5;
			cursor: not-allowed;
			
			.m-mixed-tag-input-edit-button,
			input {
				background-color: $light-gray4;
			}

			input {
				cursor: not-allowed;
			}
		}

		.m-mixed-tag-input-edit-button {
			border: none;
			height: 20px;
			padding: 0;
		}

		input {
			flex: 1;
			border: none;
			color: $gray1;
			height: 20px;
			font-family: 'Roboto Flex', sans-serif;
			font-size: 16px;
			padding-right: 20px;
		}
	}

	&-tags {
		margin: 4px 0;
		padding-right: 5px !important;

		.--matching {
			text-decoration: line-through;
		}
			.bp3-tag{
	margin-right: 5px !important;
	}
	}

}
