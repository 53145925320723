.m-distributions-update-waf-form {
	&-field {
		margin-bottom: 12px;

		&-title {
			color: $gray1;
			font-size: 11px;
			font-weight: 500;
			letter-spacing: 1px;
			margin-bottom: 2px;
			text-transform: uppercase
		}
	}
}