.m-m-distributions-pages-create {
  .m-m-distributions-pages-create-extension-warning {
    color: $red4;
    margin-top: -10px;
    margin-bottom: 15px;
    
    .m-m-distributions-pages-create-extension-warning-text {
      vertical-align: middle;
    }

    .crs-tooltip-component {
      display: inline-block;
      margin-left: 4px;
    }

    .crs-tooltip-component-content {
      font-size: 14px;
      line-height: 1;
      padding: 2px 4px;
    }
  }

  .m-m-distributions-pages-create-content {
    padding: 16px 0;
  }
}

.m-m-distributions-pages-create-plans-dialog {
  width: 50% !important;

  .m-basic-dialog-container {
    width: 100% !important;

    .m-basic-dialog-head{
      margin-left: 25px;
    }
  }

  .m-basic-dialog-head-left-side {
    margin-left: auto;
  }

  .m-m-distributions-pages-create-plans-dialog-text{
    font-size: 18px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .m-m-distributions-pages-create-plans-dialog-title-link,
  .m-m-distributions-pages-create-plans-dialog-text-link {
    color: $indigo3 !important;
  }

  .m-m-distributions-pages-create-plans-dialog-title-link:hover,
  .m-m-distributions-pages-create-plans-dialog-text-link:hover {
    color: $indigo2 !important;
  }

  .m-m-distributions-pages-create-plans-dialog-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .m-checkbox-component {
      input:checked + label {
        .m-checkbox-component-label {
          .m-checkbox-component-label-checked {
            .m-checkbox-component-label-checked-content {
              background-color: $indigo3;
            }
          }
        }
      }
    }
  }

  .m-m-distributions-pages-create-plans-dialog-image {
    display: flex;
    justify-content: center;

    .m-m-distributions-pages-create-plans-dialog-image-link {
      width: 60%;

      img {
        width: 100%;
      }
    }
  }

  .m-checkbox-component {
    margin: 12px 0;
  }

  .m-m-distributions-pages-create-plans-dialog-button-link {
    background-color: $indigo3;
    border: none;
    color: white !important;
    display: block;
    margin: 0 auto;
    width: max-content;
  }

  .m-m-distributions-pages-create-plans-dialog-button-link:hover {
    background-color: $indigo2;
  }
}