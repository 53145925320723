.m-profile-main-module-account-tab {

  .m-profile-main-module-account-tab-content {
    margin-top: 24px;
    .m-profile-main-module-account-tab-profile-details {
      width: $profile-tab-width;
      border: 1px solid #e4e7eb;
      padding: 20px;
      border-radius: 6px;
      margin-top: 5px;
    }

    .m-profile-main-module-account-tab-change-password {
      width: $profile-tab-width;
    }

    .m-profile-main-module-account-tab-divider {
      background-color: $border-color;
      height: 1px;
      margin: 24px 0;
      width: $profile-tab-width;
    }
  }
}