.m-distributions-origins-main {

  .m-distributions-origins-origin-table-head {
    display: flex;
    height: 30px;

    .m-distributions-origins-origin-table-head-search {
      height: 30px;
      width: 256px;
    }
    
    .m-distributions-origins-origin-table-head-add-button {
      margin-right: 12px;
    }
  }

  .m-distributions-origins-origin-table {
    margin: 16px 0 48px 0;

    .name {
      width: 20%;
    }
    
    .hostname {
      width: 20%;
    }
    
    .ip {
      width: 35%;
    }
    
    .updated-at {
      width: 15%;
    }    
  }

  .m-distributions-origins-origin-group-table-head {
    display: flex;
    height: 30px;

    .m-distributions-origins-origin-group-table-head-search {
      height: 30px;
      width: 256px;
    }
    
    .m-distributions-origins-origin-group-table-head-add-button {
      margin-right: 12px;
    }
  }

  .m-distributions-origins-callout {
    margin-top: 15px;
  }

  .m-distributions-origins-origin-group-table {
    margin: 16px 0;

    .origin-group-name {
      width: 25%;
    }

    .origin-group-origins {
      width: 60%;
    }
  }
}
