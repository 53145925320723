.m-stats-main-traffic-content {
  .m-stats-main-traffic-table {
    .date,
    .usage {
      width: 20%;
    }

    .dist,
    .zone {
      width: 30%;
    }
  }
}