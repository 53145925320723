@import './pages/settings/buckets.settings.scss';
@import './pages/files/buckets.files.scss';
@import './pages/metrics/buckets.metrics.scss';
@import './pages/files/components/general-dialog.scss';
@import './pages/files/components/uploader.scss';
@import './pages/upload/buckets.upload.scss';
@import './pages/upload/uploader/buckets.uploader.scss';

.m-m-buckets-pages-details {
	height: 100%;
  .m-m-buckets-pages-details-content-container {
		height: 100%;
		display: flex;
		padding: 16px 0;

		.m-m-buckets-pages-details-sidebar {
			margin-right: 24px;
			min-width: 136px;
		}

		.m-m-buckets-pages-details-content {
			width: 100%;
			width: -moz-available;          /* WebKit-based browsers will ignore this. */
			width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
			width: stretch;
		}
	}
}