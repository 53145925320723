.m-workspaces-notification-settings-content {
  .m-workspaces-notification-settings-table {
    margin-top: 16px;

    .icon {
      width: 3%;
      padding: 0;
      text-align: center;
    }

    .role {
      width: 15%;
    }

    .m-workspaces-notification-settings-table-expandable-row {
      cursor: pointer;
    }
  }

  .m-workspaces-notification-settings-filter {
    margin-bottom: 16px;
    margin-top: 16px;

    .m-workspaces-notification-settings-filter-content {
      display: flex;

      .m-workspaces-notification-settings-filter-select-box {
        flex: 1;

        .m-workspaces-notification-settings-filter-select-box-title {
          color: #5c7080;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .m-workspaces-notification-settings-table-no-items {
    align-items: center;
    border: 1px solid $light-gray4;
    border-top: none;
    color: $text-color;
    display: flex;
    height: 42px;
    justify-content: center;
  }

  .m-workspaces-notification-settings-section {
    .m-workspaces-notification-settings-section-title {
      display: flex;
      justify-content: space-between;

      .m-workspaces-notification-settings-section-title-text {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .m-workspaces-notification-settings-section-description {
      color: $gray1;
      font-size: 14px;
      margin-top: 2px;
    }

    .m-workspaces-notification-settings-content {
      color: $gray1;
      font-size: 16px;
      padding-top: 16px;
    }
  }

  .m-workspaces-notification-settings-section:not(:last-child) {
    border-bottom: 1px solid $light-gray4;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }

  .m-workspace-notification-settings-expanded-row {
    margin: 8px;
    padding-top: 4px;

    .m-workspace-notification-settings-expanded-row-event {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 0;

      .m-workspace-notification-settings-expanded-row-channels {
        display: flex;

        .m-workspace-notification-settings-expanded-row-channel {
          margin-left: 12px;
          display: flex;

          .m-workspace-notification-settings-expanded-row-channel-icon-success{
            color: $green4;
            margin-left: 1px;

          }
          .m-workspace-notification-settings-expanded-row-channel-icon-error{
            color: $red5;
            margin-left: 1px;

          }
        }
      }
    }
  }
}

.m-edit-alert-notification-dialog {
  .m-basic-dialog-container {
    width: 900px !important;
  }

  .m-edit-alert-form-sms-warning {
    color: $orange4;
    margin-bottom: 12px;

    .m-edit-alert-form-sms-warning-text {
      margin-left: 4px;
      vertical-align: middle;
    }
  }
}

@media screen and (max-width: 1600px) {
  .m-workspaces-notification-settings-content {
    .m-workspaces-notification-settings-table {
      .icon {
        width: 4%;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .m-workspaces-notification-settings-content {
    .m-workspaces-notification-settings-table {
      .icon {
        width: 5%;
      }
    }
  }
}
