.m-distributions-restrictions {
	.m-distributions-restrictions-callout {
		margin-bottom: 20px;
	}
	.m-distributions-restrictions-content {
		margin: 16px 0;

		.m-distributions-restrictions-content-section {
			.m-distributions-restrictions-content-section-title {
				color: $gray1;
				font-size: 11px;
				font-weight: 500;
				letter-spacing: 1px;
				margin-bottom: 2px;
				text-transform: uppercase;
			}
		}

		.m-distributions-restrictions-content-section:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}

.m-add-list-content-form {
	height: 100%;

	&-file {
		border: 1px solid;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;

		&.--dragged {
			color: $border-color;
		}
	}

	&-new-item-line {
		display: flex;

		.m-add-list-content-form-field {
			width: 100%;
			margin-right: 8px;

			&-input {
				height: 50px;
			}

			&-title {
				color: #5c7080;
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 1px;
				height: 12px;
				margin-bottom: 2px;
				text-transform: uppercase;
			}
		}

		.m-add-list-content-form-button {
			height: 33px;
			width: 100px;
			margin-top: 0 !important;
		}
	}

	&-content {
		padding: 4px 0 12px;

		.m-add-list-content-form-item {
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			.m-add-list-content-form-item-delete-button {
				border: none;
				margin-left: 7px;

				&:hover {
					background-color: $gray3;
					color: white;
				}

				&:active {
					background-color: $gray2;
					color: white;
				}
			}

			.m-add-list-content-form-item-line-no {
				width: 36px;
				text-align: end;
			}

			.m-form-text-field {
				align-self: flex-start;
				width: 90%;
				margin-bottom: 0;
				margin-left: 0px;
			}
		}

		.crs-pgn {
			border-top: 1px solid $border-color;
			margin-top: 8px;
		}

		.m-add-list-content-form-submit-button {
			margin-top: 8px;
		}
	}
}
