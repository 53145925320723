.m-certificates-details-cnames-component {
  .m-certificates-details-cnames-search {
    width: 250px;
  }
  
  .m-certificates-details-cnames-content {
    padding-top: 16px;


    .m-certificates-details-cnames-table {
      .cname {
        width: unset;
      }

      .date {
        width: 15%;
      }
    }

    .m-certificates-details-cnames-table-no-item {
      align-items: center;
      border: 1px solid $light-gray4;
      border-top: none;
      color: $text-color;
      display: flex;
      height: 42px;
      justify-content: center;
    }

    .m-certificates-details-cnames-table-loading {
      height: 240px;
      padding: 24px 0;
    }
  }
}