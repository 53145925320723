.m-custom-rule-edit-form-component {
  margin-top: 10px;

  .m-custom-rule-edit-form {
    &-add-condition {
      display: flex;
      width: 100%;

      &-field {
        flex: 1 0;
        margin-right: 8px;

        &-title {
          color: #5C7080;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }
      }

      &-button {
        height: 33px;
        width: 80px;
        margin-top: 14px;
      }
    }

    .m-custom-rule-edit-form-condition {
      padding: 6px 12px;
      display: flex;
      width: 100%;

      &-group {
        background-color: $border-color;
        border-radius: 4px;
        padding: 4px 0;
      }
      
      &:first-child {
        padding-top: 16px;

      }

      &:last-child {
        padding-bottom: 16px;
      }

      &-left-button {
        width: 52px;
      }

      &-remove-button {
        height: 33px;
        border: none;

        &:hover {
					background-color: $gray3;
					color: white;
				}
				&:active {
					background-color: $gray2;
					color: white;
				}
      }

      &-field {
        margin-right: 8px;
        width: 300px;

        .m-form-text-field {
          margin-bottom: 0;
        }

        &.--grow {
          flex: 1 0;
        }

        &-title {
          color: #5C7080;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }
      }

      &-button {
        height: 33px;
        width: 80px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      &-joint {
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        
        &.--and {
          padding: 0 12px;
        }

        &.--or {
          padding: 20px 12px;
        }
      }
    }
  }

  .m-form-section-field-btn {
    padding: 16px 0;
  }
}