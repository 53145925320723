.m-form-image-uploader{

  .m-form-image-uploader-description{
    color: $gray3;
    font-size: 14px;
  }

.m-form-image-uploader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  margin-bottom: 6px;

.m-form-image-uploader-dropzone{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin-bottom: 12px;
    margin-top: 12px;
    border: 6px solid $border-color;
    box-sizing: content-box;
    background-size: cover;
    background-position: center;
}

.m-form-image-uploader-dropzone-hover{
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
}
  .m-form-image-uploader-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin-bottom: 12px;
    margin-top: 12px;
    border: 6px solid $border-color;
    box-sizing: content-box;
    background-size: cover;
    background-position: center;

    img {
      flex-shrink: 0;
    }
  }

  .m-form-image-uploader-local {
    display: flex;
    width: 100%;
  }

  .m-form-text-input-component {
    width: 100%;
    margin-bottom: 0;

    .m-form-text-input {
      border: none !important;
      // flex-direction: row !important;
      padding: 5px !important;
      .m-form-text-input-line {
        display: flex;
      }

      .m-form-text-input-line-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      #imageName {
        background: initial;
        color: $gray1;
        flex: 1;
      }
      
      #imageName::placeholder {
        color: $gray3;
      }

      .m-form-image-uploader-button {
        height: 36px;
        padding: 0;

        .m-form-image-uploader-content {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          padding: 6px 12px;

          .m-form-image-uploader-content-icon {
            color: $violet4;
            margin-right: 5px;
          }

          #imageInput {
            height: 0.1px;
            width: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }
        }
      }

      .m-form-image-uploader-file-dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 36px;
        border: 3px solid $border-color;
        box-sizing: content-box;
        background-size: cover;
        background-position: center;

        .m-form-image-uploader-content {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          padding: 6px 12px;

          .m-form-image-uploader-content-icon {
            color: $violet4;
            margin-right: 5px;
          }

          #imageInput {
            height: 0.1px;
            width: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }

  // .m-form-text-field {
  //   flex: 1;
  //   margin: 0;
  //   // margin: 0 16px 0 0;

  //   input {
  //     background-color: initial;
  //   }
  // }
  // .btn--success {
  //   // font-size: 18px;
  //   // height: 56px;
  //   // width: 120px;

  //   .m-form-image-uploader-content {
  //     #imageInput {
  //       height: 0.1px;
  //       width: 0.1px;
  //       opacity: 0;
  //       overflow: hidden;
  //       position: absolute;
  //       z-index: -1;
  //     }
  //   }
  // }
}
.m-form-image-uploader-container-img-source {
  .preview-image{
        border: 6px solid $border-color;

  }
.m-form-image-uploader-dropzone-img-source{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 300px;
    height: 300px;
    margin-bottom: 12px;
    margin-top: 12px;
    border: 6px solid $border-color;
    box-sizing: content-box;
    background-size: cover;
    background-position: center;
}

.m-form-image-uploader-dropzone-hover{
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
}
  .m-form-image-uploader-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin-bottom: 12px;
    margin-top: 12px;
    border: 6px solid $border-color;
    box-sizing: content-box;
    background-size: cover;
    background-position: center;

    img {
      flex-shrink: 0;
    }
  }

  .m-form-image-uploader-local {
    display: flex;
    width: 100%;
  }

  .m-form-text-input-component {
    width: 100%;
    margin-bottom: 0;

    .m-form-text-input {
      border: none !important;
      // flex-direction: row !important;
      padding: 5px !important;
      .m-form-text-input-line {
        display: flex;
      }

      .m-form-text-input-line-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      #imageName {
        background: initial;
        color: $gray1;
        flex: 1;
      }
      
      #imageName::placeholder {
        color: $gray3;
      }

      .m-form-image-uploader-button {
        height: 36px;
        padding: 0;

        .m-form-image-uploader-content {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 100%;
          padding: 6px 12px;

          #imageInput {
            height: 0.1px;
            width: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }

  // .m-form-text-field {
  //   flex: 1;
  //   margin: 0;
  //   // margin: 0 16px 0 0;

  //   input {
  //     background-color: initial;
  //   }
  // }
  // .btn--success {
  //   // font-size: 18px;
  //   // height: 56px;
  //   // width: 120px;

  //   .m-form-image-uploader-content {
  //     #imageInput {
  //       height: 0.1px;
  //       width: 0.1px;
  //       opacity: 0;
  //       overflow: hidden;
  //       position: absolute;
  //       z-index: -1;
  //     }
  //   }
  // }
}
}