.server {
    &__main {
        &__button-create {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }
}

.m-deleted-server-update-dialog-description-warning {
    color: $orange3;
    display: flex;
    flex-direction: row;
    align-items: center;

    .m-deleted-server-update-dialog-description-warning-icon-container {
        margin-right: 15px;
    }
}
