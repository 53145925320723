$package-border-radius: 8px;
$chosen-package-background-gradient: linear-gradient(36deg,
    rgba(177, 177, 177, 0.03) 0%,
    rgba(177, 177, 177, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 100.002%),
  linear-gradient(195deg,
    rgba(11, 11, 11, 0.02) 0%,
    rgba(11, 11, 11, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 100%),
  linear-gradient(262deg,
    rgba(234, 234, 234, 0.03) 0%,
    rgba(234, 234, 234, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 100.002%),
  linear-gradient(34deg,
    rgba(192, 192, 192, 0.03) 0%,
    rgba(192, 192, 192, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 100.002%),
  linear-gradient(281deg,
    rgba(11, 11, 11, 0.03) 0%,
    rgba(11, 11, 11, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 100%),
  linear-gradient(268deg,
    rgba(211, 211, 211, 0.02) 0%,
    rgba(211, 211, 211, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 100%),
  linear-gradient(139deg,
    rgba(248, 248, 248, 0.03) 0%,
    rgba(248, 248, 248, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 100%),
  linear-gradient(52deg,
    rgba(171, 171, 171, 0.02) 0%,
    rgba(171, 171, 171, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 100%),
  linear-gradient(90deg, #ffffff9c, #ffffff9e);

$active-package-background-gradient: linear-gradient(36deg,
    rgba(177, 177, 177, 0.03) 0%,
    rgba(177, 177, 177, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 100.002%),
  linear-gradient(195deg,
    rgba(11, 11, 11, 0.02) 0%,
    rgba(11, 11, 11, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 100%),
  linear-gradient(262deg,
    rgba(234, 234, 234, 0.03) 0%,
    rgba(234, 234, 234, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 100.002%),
  linear-gradient(34deg,
    rgba(192, 192, 192, 0.03) 0%,
    rgba(192, 192, 192, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 100.002%),
  linear-gradient(281deg,
    rgba(11, 11, 11, 0.03) 0%,
    rgba(11, 11, 11, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 100%),
  linear-gradient(268deg,
    rgba(211, 211, 211, 0.02) 0%,
    rgba(211, 211, 211, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 100%),
  linear-gradient(139deg,
    rgba(248, 248, 248, 0.03) 0%,
    rgba(248, 248, 248, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 100%),
  linear-gradient(52deg,
    rgba(171, 171, 171, 0.02) 0%,
    rgba(171, 171, 171, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 100%),
  linear-gradient(180deg, #c1ffd1c9, #efffd7);

.m-package-selector {
  .m-package-selector-bg {
    background: $chosen-package-background-gradient;
    color: #fff;
  }

  .m-package-active-bg {
    background: $active-package-background-gradient;
    color: #fff;
  }

  .m-package-selector-component {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
    justify-content: flex-start;

    .m-package-selector-package {
      flex: 1 0 250px;
      /* flex-grow, flex-shrink, flex-basis */
      min-width: 250px;
      max-width: 270px;
      position: relative;

      .m-package-selector-package-head-top-badges-featured {
        background-color: #15b371;
        border: 1px solid #0f9960;
        border-radius: 2px;
        color: #fff;
        padding: 1px 4px 1px 4px;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        width: max-content;
        margin-bottom: -18px;
        margin-right: -6px;
        z-index: 1;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }

      .m-package-selector-package-head-top-badges-discount {
        text-decoration: line-through;
        color: #8b8b8b;
        font-weight: 600;
        position: relative;
        display: block;
        top: 5px;
        left: 23px;
      }

      .m-package-selector-package-main {
        border: 1px solid $border-color;
        border-radius: $package-border-radius;
        color: $text-color;
        height: 100%;

        .m-package-selector-package-content {
          height: 100%;
        }

        .m-package-selector-package-body {
          display: flex;
          flex-direction: column;
          height: calc(100% - 100px);
          justify-content: space-between;
        }

        .m-package-selector-package-head {
          padding: 0;
          border-radius: $package-border-radius $package-border-radius 0 0;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          background-color: $light-gray4;
          height: 100px;
          display: flex;
          flex-direction: column;
          padding: 16px 16px;
          justify-content: space-between;
          border-bottom: 2px #d5d5d5 solid;

          .m-package-selector-package-head-top-name {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: #4d4d4d;
          }

          .m-package-selector-package-pricing {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .m-package-selector-package-price {
              display: flex;
              text-align: center;
              color: #4d4d4d;

              .m-package-selector-package-price-free {
                font-size: 28px;
                font-weight: 450;
                letter-spacing: 1px;
              }

              .m-package-selector-package-price-old {
                font-size: 13px;
                margin-top: 16px;
                margin-right: 10px;
                position: relative;
              }

              .m-package-selector-package-price-old:after {
                border-bottom: 1px solid;
                content: "";
                left: 0;
                margin-top: 7px;
                position: absolute;
                right: 0;
              }

              .m-package-selector-package-price-currency {
                font-size: 14px;
                font-weight: 430;
                margin-top: 5px;
              }

              .m-package-selector-package-price-amount1 {
                font-size: 30px;
                font-weight: 450;
                letter-spacing: 1px;
              }

              .m-package-selector-package-price-amount2 {
                font-size: 14px;
                font-weight: 430;
                margin-top: 5px;
              }

              .m-package-selector-package-price-per {
                font-size: 14px;
                font-weight: 400;
                margin-top: 16px;
              }
            }
          }

          .m-package-selector-package-head-bottom {
            display: flex;
            justify-content: space-between;
            align-items: inherit;

            .m-package-selector-package-head-bottom-date {
              font-size: 10px;
              font-weight: 200;
              height: 30px;
              position: absolute;
              bottom: -11px;

              .date {
                margin-right: 2px;
              }
            }

            .m-package-selector-package-head-bottom-discount {
              height: 30px;
              position: absolute;
              bottom: -10px;
              right: -10px;
              width: 65px;
              color: #fff;
              padding: 4px;
              font-size: 11px;
              font-weight: 400;
              text-transform: uppercase;
              // width: max-content;
              // transform: rotate(-45deg);
              background-color: $red4;
            }
          }
        }

        .m-package-selector-package-bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          font-size: 12px;
        }

        .m-package-selector-package-button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;
        }

        .m-package-selector-package-button-sales {
          display: flex;
          margin-bottom: 16px;

          .m-form-submit-button-anchor {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .m-package-selector-package-attributes {
          padding: 16px;
          padding-bottom: 32px;
        }

        .m-package-selector-package-attributes-item {
          display: flex;
          line-height: 1.4;
          font-size: 12px;
          margin-bottom: 1px;

          .m-package-selector-package-attributes-item-icon--included {
            margin-right: 4px;
            color: #3dcc91;
          }

          .m-package-selector-package-attributes-item-icon--not-included {
            margin-right: 4px;
            color: $gray5;
          }

          .m-package-selector-package-attributes-item-overuse {
            font-size: 11px;
            color: $gray1;
          }
        }

        .m-package-selector-package-attributes-item:last-child {
          margin-bottom: 4px;
        }

        input:checked+.m-package-selector-package-content {
          .m-package-selector-package-head {
            background: $chosen-package-background-gradient;
            color: #fff;
          }

          .m-package-selector-package-name {
            border-bottom: 1px solid white;
            margin-bottom: 12px;
          }
        }
      }
    }

    .m-package-selector-subscribe-button {
      width: 100%;
      margin: 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}