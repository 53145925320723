.m-reset-password-module {
  .m-reset-password-head {
    margin-bottom: 64px;

    .m-reset-password-page-brand-logo {
      width: 300px;
    }
  }

  .m-reset-password-content {
    display: flex;
    height: 400px;
  }
}