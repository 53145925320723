.m-origin-form-health-check {

  .m-origin-form-health-check-form-custom-section {
    display: flex;
    margin-bottom: 12px;

    .m-origin-form-health-check-form-custom-section-left {
      color: $text-color;
      padding-right: 32px;
      width: 50%;

      .m-origin-form-health-check-form-custom-section-left-title {
        font-size: 16px;
        font-weight: 500;
      }

      .m-origin-form-health-check-form-custom-section-left-description {
        color: $gray3;
        font-size: 14px;
      }
    }

    .m-origin-form-health-check-form-custom-section-right {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .m-origin-form-custom-container {
      align-items: center;
      display: flex;
      margin-left: 16px;
      margin-top: 8px;

      .m-origin-form-custom-container-icon {
        flex-shrink: 0;
        color: $light-gray1;
        width: 32px;
      }

      .m-origin-form-custom-container-rest-container {
        width: 100%;

        .m-origin-form-custom-container-head {
          display: flex;
          margin-bottom: 2px;

          .m-origin-form-custom-container-title {
            color: $gray1;
            font-size: 12px;
            font-weight: 500;
            margin-right: 4px;
          }

          .m-origin-form-custom-container-description {
            color: $gray3;
            font-size: 12px;
          }
        }

        .m-origin-form-custom-container-field {
          .m-form-text-input-component {
            margin-bottom: 0;

            .m-form-text-input {
              min-height: 30px;
              padding: 6px 12px;

              .m-form-text-input-title {
                display: none;
              }

              input {
                font-size: 16px;
                line-height: 20px;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  .m-origin-form-health-check-description {
    color: $gray3;

    .description-warning {
      color: $orange3;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14.5px;

      .description-warning-icon-container {
        margin-right: 15px;
      }
    }
  }

  .m-origin-form-health-check-fields {
      display: flex;
      .m-origin-form-health-check-field {
        .m-origin-form-health-check-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .m-origin-form-health-check-field-input {
          width: 100%;
        }
      }

      .m-origin-form-health-check-field:not(:last-child) {
        flex: 1;
        margin-right: 1.5%;
      }

      .m-origin-form-health-check-button {
        height: 33px;
      }
    }

  .m-origin-form-health-check-fields-margin {
      margin: 15px 0;

      .left-right-margin {
        margin: 10px 10px;
      }
    }

  .crs-switch {
    float: right !important;
  }

  .bp3-card {
    padding-bottom: 15px;
  }

  .health-check-custom-headers {

    .health-check-fields {
      display: flex;

      .health-check-field  {

        .health-check-field-title {
          color: $gray1;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
          height: 12px;
          margin-bottom: 2px;
          text-transform: uppercase;
        }

        .health-check-field-input {
          width: 100%;
        }
      }

      .health-check-field:not(:last-child) {
        flex: 1;
        margin-right: 1.5%;
      }

      .health-check-custom-headers-button {
        height: 33px;
      }
    }

    .health-check-custom-headers-items {
      display: flex;

      .health-check-custom-header-item {
        display: flex;
        height: 33px;

        .health-check-custom-header-item-left {
          align-items: center;
          background-color: $brand-color-light;
          border: 1px solid $brand-color-light-border;
          border-radius: 3px 0 0 3px;
          color: #fff;
          display: flex;
          padding: 6px 12px 6px 6px;
        }

        .health-check-custom-header-item-right {
          align-items: center;
          border: 1px solid $border-color;
          border-left: none;
          border-radius: 0 3px 3px 0;
          display: flex;
          padding: 6px 12px;
        }

        .health-check-custom-header-header-button {
          background-color: inherit;
          border: none;
          border-radius: 3px;
          color: #fff;
          height: min-content;
          margin-right: 6px;
          padding: 2px;
        }

        .health-check-custom-header-header-button:hover {
          background-color: $brand-color-light-hover;
        }
      }

      .health-check-custom-header-item:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
