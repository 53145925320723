$active-package-background-gradient: linear-gradient(36deg,
    rgba(177, 177, 177, 0.03) 0%,
    rgba(177, 177, 177, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 100.002%),
  linear-gradient(195deg,
    rgba(11, 11, 11, 0.02) 0%,
    rgba(11, 11, 11, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 100%),
  linear-gradient(262deg,
    rgba(234, 234, 234, 0.03) 0%,
    rgba(234, 234, 234, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 100.002%),
  linear-gradient(34deg,
    rgba(192, 192, 192, 0.03) 0%,
    rgba(192, 192, 192, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 100.002%),
  linear-gradient(281deg,
    rgba(11, 11, 11, 0.03) 0%,
    rgba(11, 11, 11, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 100%),
  linear-gradient(268deg,
    rgba(211, 211, 211, 0.02) 0%,
    rgba(211, 211, 211, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 100%),
  linear-gradient(139deg,
    rgba(248, 248, 248, 0.03) 0%,
    rgba(248, 248, 248, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 100%),
  linear-gradient(52deg,
    rgba(171, 171, 171, 0.02) 0%,
    rgba(171, 171, 171, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 100%),
  linear-gradient(180deg, #c1ffd1c9, #efffd7);

$cancelled-package-background-gradient: linear-gradient(36deg,
    rgba(177, 177, 177, 0.03) 0%,
    rgba(177, 177, 177, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 100.002%),
  linear-gradient(195deg,
    rgba(11, 11, 11, 0.02) 0%,
    rgba(11, 11, 11, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 100%),
  linear-gradient(262deg,
    rgba(234, 234, 234, 0.03) 0%,
    rgba(234, 234, 234, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 100.002%),
  linear-gradient(34deg,
    rgba(192, 192, 192, 0.03) 0%,
    rgba(192, 192, 192, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 100.002%),
  linear-gradient(281deg,
    rgba(11, 11, 11, 0.03) 0%,
    rgba(11, 11, 11, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 100%),
  linear-gradient(268deg,
    rgba(211, 211, 211, 0.02) 0%,
    rgba(211, 211, 211, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 100%),
  linear-gradient(139deg,
    rgba(248, 248, 248, 0.03) 0%,
    rgba(248, 248, 248, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 100%),
  linear-gradient(52deg,
    rgba(171, 171, 171, 0.02) 0%,
    rgba(171, 171, 171, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 100%),
  linear-gradient(90deg, #ffffff9c, #ffffff9e);

$downgrade-package-background-gradient: linear-gradient(36deg,
    rgba(177, 177, 177, 0.03) 0%,
    rgba(177, 177, 177, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 16.667%,
    rgba(95, 95, 95, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 33.334%,
    rgba(237, 237, 237, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 50.001%,
    rgba(28, 28, 28, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 66.668%,
    rgba(174, 174, 174, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 83.335%,
    rgba(51, 51, 51, 0.03) 100.002%),
  linear-gradient(195deg,
    rgba(11, 11, 11, 0.02) 0%,
    rgba(11, 11, 11, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 25%,
    rgba(107, 107, 107, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 50%,
    rgba(228, 228, 228, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 75%,
    rgba(155, 155, 155, 0.02) 100%),
  linear-gradient(262deg,
    rgba(234, 234, 234, 0.03) 0%,
    rgba(234, 234, 234, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 16.667%,
    rgba(191, 191, 191, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 33.334%,
    rgba(236, 236, 236, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 50.001%,
    rgba(85, 85, 85, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 66.668%,
    rgba(222, 222, 222, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 83.335%,
    rgba(38, 38, 38, 0.03) 100.002%),
  linear-gradient(34deg,
    rgba(192, 192, 192, 0.03) 0%,
    rgba(192, 192, 192, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 16.667%,
    rgba(200, 200, 200, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 33.334%,
    rgba(23, 23, 23, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 50.001%,
    rgba(224, 224, 224, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 66.668%,
    rgba(181, 181, 181, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 83.335%,
    rgba(34, 34, 34, 0.03) 100.002%),
  linear-gradient(281deg,
    rgba(11, 11, 11, 0.03) 0%,
    rgba(11, 11, 11, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 20%,
    rgba(29, 29, 29, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 40%,
    rgba(91, 91, 91, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 60%,
    rgba(239, 239, 239, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 80%,
    rgba(19, 19, 19, 0.03) 100%),
  linear-gradient(268deg,
    rgba(211, 211, 211, 0.02) 0%,
    rgba(211, 211, 211, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 25%,
    rgba(137, 137, 137, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 50%,
    rgba(14, 14, 14, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 75%,
    rgba(220, 220, 220, 0.02) 100%),
  linear-gradient(139deg,
    rgba(248, 248, 248, 0.03) 0%,
    rgba(248, 248, 248, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 12.5%,
    rgba(185, 185, 185, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 25%,
    rgba(243, 243, 243, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 37.5%,
    rgba(13, 13, 13, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 50%,
    rgba(99, 99, 99, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 62.5%,
    rgba(193, 193, 193, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 75%,
    rgba(241, 241, 241, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 87.5%,
    rgba(38, 38, 38, 0.03) 100%),
  linear-gradient(52deg,
    rgba(171, 171, 171, 0.02) 0%,
    rgba(171, 171, 171, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 20%,
    rgba(118, 118, 118, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 40%,
    rgba(7, 7, 7, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 60%,
    rgba(235, 235, 235, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 80%,
    rgba(190, 190, 190, 0.02) 100%),
  linear-gradient(90deg, #ffd25c70, #ffe1ab);



.subscription-card {
  border: 1px solid #ced9e0;
  border-radius: 8px;
  //margin-bottom: 20px;
  overflow: hidden;
  flex: 0 0 32.222%;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    flex-basis: 48% !important;
  }

  @media (max-width: 1300px) {
    flex-basis: 32%;
  }


  &--active {
    .subscription-card__header {
      background: $active-package-background-gradient;
    }
  }

  &--cancelled {
    .subscription-card__header {
      background: $cancelled-package-background-gradient;
    }
  }

  &--downgraded {
    .subscription-card__header {
      background: $downgrade-package-background-gradient;
    }
  }

  &__feature {
    &__item {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding-bottom: 8px;

      &--included {
        color: #3dcc91;
      }

      &--not-included {
        color: #BFCCD6;
      }
    }
  }

  &__header {
    padding: 1rem;
    color: #4d4d4d;
    // border-bottom: 2px #d5d5d5 solid;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #dedede, #e2e2e2);
    }
  }

  &__price {
    position: absolute;
    right: 0;
    bottom: 8px;
    padding-right: 21px;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 5px;
    margin: 0;
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;

    .date-label {
      width: 35%;
      text-align: left;
      padding-right: 10px;
      font-weight: 500;
    }

    .date-value {
      width: 65%;
      text-align: left;
      font-weight: 500;
    }
    .danger{
      color: #f55656;
    }
  }

  &__body {
    padding: 10px;
    background-color: #fff;
    height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #d3d3d3;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px;
    }
  }

  .subscription-card__item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 5px 10px 5px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .subscription-card__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .subscription-card__item-name {
      font-weight: 600;
    }

    .subscription-card__item-label {
      opacity: 0.6;
    }
  }

  .subscription-card__item-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .subscription-card__detail-item {
    display: flex;
    justify-content: space-between;
  }

  .subscription-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-right: 20px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #f2f2f2, #f2f2f2);
    }
  }

  .subscription-button {
    button {
      width: 150px;
    }
  }

  .subscription-details {}

  .subscription-details__discounted-amount {
    font-size: 13px;
    color: #999;
    margin-right: 0.2em;
    text-decoration: line-through;
  }

  .subscription-details__total-amount {
    font-size: 18px;
    font-weight: 600;
  }
}