.m-role-permissions-form {
  .m-role-permissions-form-section-content {
    column-count: 2;
    padding-left: 0;
  }

  .m-form-section-custom-field {
    padding: 8px 0;
  }

  .m-radio-group-component {
    > div {
      display: flex;
      justify-content: space-between;
    }

    .bp3-control.bp3-radio.bp3-inline:last-child {
      .bp3-control-indicator {
        display: none;
      }
    }
  }

  .m-role-permissions-form-exception-field {
    display: flex;

    .m-selectbox-component {
      width: 25%;
      margin: 0 8px;
    }

    .m-role-permissions-form-exception-field-remove-role-button {
      height: 33px;
      border: none;
    }

    .m-role-permissions-form-exception-field-remove-role-button:hover {
      background-color: $gray1;
      color: white;
    }
  }

  .m-role-permissions-form-exception-field:not(:last-child) {
    margin-bottom: 12px;
  }

  .m-role-permissions-form-exception-field-permissions {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
  }
}

$border-color: #ced9e0;

.role-form__button {
  padding-top: 10px;
}

.pbox {
  display: flex;
  height: 500px;
  margin-top: 10px;
  //min-height: 100vh;

  &__form {
    padding: 0px 15px 15px 15px !important;
    border: none !important;

    &__group {
      padding-bottom: 10px;
      &__title {
        font-size: 16px;
        font-weight: 500;
      }
      &__description {
        color: #8a9ba8;
        font-size: 14px;
      }
      &__field {
        padding-top: 5px;

        .m-form-text-field {
          margin-bottom: 0 !important;
        }
        .m-form-text-field-left {
          width: 0 !important;
          padding-right: 0 !important;
        }
        .m-form-text-field-right {
          margin-left: 0 !important;
          width: 100% !important;
        }
      }
    }

    &__button {
      float: right;
    }
  }
  &__column {
    width: 500px;
    border: 1px solid $border-color;

    &__header {
      &__title {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
        padding: 10px;
        padding-left: 13px;

        i {
          cursor: pointer;
          color: #646464;
        }
      }
      &__search-bar {
        position: relative;
        padding: 10px;
        border-bottom: 1px solid $border-color;
        &__icon {
          position: absolute;
          z-index: 9;
          color: #9f9f9f;
          padding-top: 5px;
          padding-left: 11px;
        }
        &__input {
          width: 100%;
          height: 30px;
          border: 1px solid $border-color;
          padding-left: 35px;

          border-top-color: white;
          border-left-color: white;
          border-right-color: white;
        }
      }
    }
    &__content {
      height: 410px;
      display: block;
      position: relative;
      overflow: auto;

      &__disable{
        pointer-events:none;
        opacity: 0.5;
      }
      .bp3-tree-node-secondary-label {
        display: block;
        position: relative;
        width: 50px;
        height: 20px;
        a {
          position: absolute;
          right: 0px;
          width: 100%;
          height: 100%;
          z-index: 99999;
       
          .m-tooltip-component-with-content {
            padding-left: 30px;
          }
        }
        .bp3-popover-wrapper {
          color: #646464;
        }
      }

      .bp3-tree-node-label-content-text {
        height: 30px;
        padding-top: 7px;
      }

      i {
        padding-right: 2px;
      }
      .bp3-tree-node-content {
        padding-left: 5px;
        cursor: pointer;
        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
}
