@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700&display=swap');
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "common/colors.scss";
@import "common/constants.scss";
@import 'common/common.scss';
@import "components/components.scss";
@import "modules/modules.scss";

html {
  height: 100%;
  margin: 0;
}

#root {
  font-family: 'Roboto Flex', sans-serif;
  height: 100%;
  letter-spacing: 0.02em;
}

body {
  color: $text-color !important;
  font-family: 'Roboto Flex', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.link {
  color: #182026;
  text-decoration: none;
}

.link :hover {
  color: #182026;
  text-decoration: none;
}

:focus {
  outline: none;
}

button {
  font-family: 'Roboto Flex', sans-serif;
}

a,
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

.crs-btn {
  font-family: 'Roboto Flex', sans-serif;
  font-weight: 500;
}

.bp3-spinner .bp3-spinner-head {
  stroke: #fff !important;
}

.bp3-breadcrumbs {
  font-weight: 500;
}

.crs-sidebar-group-title {
  font-weight: 700 !important;
}

.bp3-toast-container {
  position: fixed !important;
}