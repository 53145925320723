.m-certificates-details-main-content {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .m-section-component {
    width: 49%;
  }

  .m-info-box-component {
    margin: 12px 0;
  }

  .m-certificate-details-chain-link {
    padding: 0 64px;
  }

  .m-certificates-details-main-chain-info-box {
    display: flex;
    font-size: 14px;
    padding: 6px 0;

    .m-certificates-details-main-chain-info-text {
      margin-left: 12px;
    }

    .m-certificates-details-main-chain-info-key {
      font-weight: 500;
      margin-right: 4px;
    }
  }

  .m-certificates-details-main-chain-info-icon {
    color: $gray3;
    display: flex;
    align-items: center;
  }

  .m-certificates-details-main-chain-info--error {
    color: $red5;
  }

  .m-certificates-details-main-chain-info--warning {
    color: $orange5;
  }

  .m-certificates-details-main-chain-info--success {
    color: $green5;
  }

  .m-section-content-component {
    padding-left: 0;
  }
}
