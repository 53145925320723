@import './cancelabledualtag/cancelabledualtag.scss';
@import './content/sectioncontent.scss';
@import './field/sectionfield.scss';
@import './head/sectionhead.scss';

.m-section-component:not(:last-child) {
  margin-bottom: 24px;
}

.m-section-component--bordered {
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 16px 24px;
}