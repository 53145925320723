.m-credential-pages-main {
  height: 100%;

  .m-credential-pages-main-table-head {
    display: flex;
    height: 30px;

    .m-credential-pages-main-table-head-create-button {
    }

    .m-credential-pages-main-table-head-search {
      height: 30px;
      width: 256px;
    }
  }

  .m-credential-pages-main-content {
    padding: 16px 0;

    .m-credential-pages-main-content-items {
      .crs-table {
        position: relative;
      }
      .disable_table {
        pointer-events: none;
        opacity: 0.3;
      }
      .m-spinnerpage-component {
        position: absolute;
        top: 50%;
        left: 45%;
        height: 20px;
      }

      .m-credential-pages-main-content-items-no-item {
        align-items: center;
        border: 1px solid $light-gray4;
        border-top: none;
        color: $text-color;
        display: flex;
        height: 42px;
        justify-content: center;
      }

      .dist__link {
        color: #2b95d6 !important;
        cursor: pointer;
        font-weight: 500;
        &:hover {
          color: #106ba3 !important;
        }
      }

      .access_key_id {
        width: 30%;
      }

      .description {
        width: 25%;
      }

      .ip_adress {
        width: 15%;
      }

      .created-at {
        width: 10%;
      }
    }
  }

  .m-credential-pages-main-no-content {
    align-items: center;
    border: 2px dashed rgba(21, 179, 113, 0.2);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 0 64px 0;
    margin: 32px 0;

    .m-credential-pages-main-no-content-icon {
      color: rgba(21, 179, 113, 0.2);
    }

    .m-credential-pages-main-no-content-main-text {
      color: $green2;
      font-size: 24px;
      margin-bottom: 8px;
      margin-top: 32px;
    }

    .m-credential-pages-main-no-content-alt-text {
      color: rgba(21, 179, 113, 0.6);
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}

.m-credential-main-move-dialog-content {
  .m-selectbox-component {
    margin-top: 8px;
  }
}

.m-credential-main-move-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .m-credential-main-move-dialog-button:not(:last-child) {
    margin-right: 8px;
  }
}
