.billing__main {
  .invoices-table {
    .options {
      width: 12% !important;
    }

    &__action-buttons {
      button {
        gap: 3px;
      }
    }
  }

  &__container {
    &__section {
      .subscription-section {
        border-radius: 6px;
        margin-bottom: 20px;

        &__header {
          font-size: 16px;
          margin-bottom: 5px;
          color: #3a4a59;
          font-weight: 500;
          border-bottom: 1px solid #e4e7eb;
          height: 30px;
          margin-bottom: 20px;
        }

        &__list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;

        }

        &__empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          &__text {
            font-size: 16px;
            padding-bottom: 15px;
          }

          &__button {
            button {
              font-size: 15px;
              width: 150px;
              height: 35px;
            }
          }
        }
      }

      .subscriptions-orders {
        display: flex;
        flex-direction: column;
      }

      .subscriptions-orders>* {
        margin-bottom: 10px;
      }

      .subscriptions-orders>*:last-child {
        margin-bottom: 20px;
      }
    }
  }
}


.content-transition {
  opacity: 0.5;
  transition: opacity 0.8s ease-in-out;
}

.content-transition.show {
  opacity: 1;
}

.m-billing-plans-tab-content {
  .m-billing-plans-tab-package-filter {
    margin-bottom: -27px;
    text-align: right;

    .c-button-group {
      min-width: 450px;

      .bp3-button-text {
        text-align: center;
      }
    }
  }
}

.m-billing-plans-update-plan-dialog-description>p {
  margin-bottom: 4px;
}


.plan__main {
  display: flex;

  &__spinner {
    display: block;
    margin-top: 100px;
  }


  &__menu {
    width: 300px;
    height: 100%;
    border: 1px solid #ced9e0;
    border-radius: 5px;
    margin-right: 10px;

    &__container {
      &__menu-items {
        padding-left: 0;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0px;

        li {
          display: block;
          text-decoration: none;
          padding: 10px 20px;
          color: #777;
          text-align: left;
          height: 35px;
          position: relative;
          border-bottom: 1px solid #efefef;
          cursor: pointer;

          &:hover:before,
          &.active:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            border-radius: 0;
          }

          &:hover:before {
            background-color: rgba(43, 148, 214, 0.3);
          }

          &.active:before {
            background-color: #4580e6;
          }

          &.active {
            background-color: #ebf1f5;
          }

          &:hover {
            background-color: #ebf1f5;
          }

          &:first-child:hover:before,
          &:first-child.active:before {
            border-radius: 5px 0 0 0;
          }

          &:last-child:hover:before,
          &:last-child.active:before {
            border-radius: 0 0 0 5px;
          }
        }
      }
    }
  }

  &__plan {
    width: 100%;
    min-height: 100vh;

    &__container {
      display: block;
      position: relative;
      min-height: 100vh;

      &__spinner {
        position: absolute;
        left: 50%;
        top: 20%;
        margin-top: -25px;
        margin-left: -25px;
      }

      &__loading {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

.subscription-dialog {
  .bold-text {
    font-weight: 600;
  }

  .dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 50px;
  }
}