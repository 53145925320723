.m-multipleselectbox-component {
  .m-multipleselectbox-errors {
    align-items: center;
    display: flex;

    .m-multipleselectbox-errors-icon {
      color: $red5;
      margin-bottom: 2px;
    }

    .m-multipleselectbox-errors-content {
      margin-left: 4px;

      .m-multipleselectbox-error {
        color: $red5;
      }
    }
  }

  .m-multipleselectbox-clear-button-container {
    align-items: center;
    display: flex;
    height: 31px;
    padding-right: 4px;

    .m-multipleselectbox-clear-button {
      border: none;
      height: min-content;
      padding: 2px;
    }

    .m-multipleselectbox-clear-button:hover {
      background-color: $light-gray4;
      color: $text-color;
    }
  }

  .bp3-input {
    border: 1px solid $border-color !important;
    box-shadow: none !important;
    min-width: 300px !important;

    input {
      color: $gray1 !important;
      font-family: 'Roboto Flex', sans-serif !important;
      font-size: 16px !important;
      line-height: initial !important;
      margin-bottom: 0 !important;
      padding: 4px 8px !important;
    }

    .bp3-tag {
      margin-bottom: 2px;
    }
  }

  .bp3-tag-input {
    padding-left: 0 !important;

    .bp3-tag-input-values {
      align-items: center !important;
      display: flex !important;
      margin: 0 !important;
      padding: 2px 4px !important;
    }
  }

  .bp3-tag-input.bp3-active {
    box-shadow: none !important;
  }
}

.m-multipleselectbox-disabled-button {
  border: 1px solid $border-color;
  font-size: 16px;
  font-weight: 400 !important;
  height: 33.2px;
  text-align: left;
  width: 100%;
}

.bp3-multi-select-popover {
  .bp3-menu {
    color: $gray1 !important;
    font-family: 'Roboto Flex', sans-serif !important;
    font-size: 16px !important;
    min-width: 300px !important;
    max-height: 300px !important;
    overflow-y: auto !important;

    .m-multipleselectbox-option {
      font-size: 16px;
      margin: 2px 0;
      padding: 6px 12px;
    }
    
    .m-multipleselectbox-option--selected:hover {
      background-color: $green5;
      color: #fff !important;
    }

    .bp3-menu-item.bp3-active.bp3-intent-primary.bp3-popover-dismiss.m-multipleselectbox-option {
      color: #fff !important;
    }
  }

  //Not in .bp3-menu part specifically to not overwrite the .bp3-active class
  .m-multipleselectbox-option--selected {
    background-color: $green5;
    color: #fff !important;
    font-size: 16px;
    margin: 2px 0;
    padding: 6px 12px;
  
    .m-multipleselectbox-option-icon {
      color: #fff !important;
    }
  }
}