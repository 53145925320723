.crs-page-head-component {
  .bp3-breadcrumbs > li::after {
    margin: 0 0px !important;
  }

  .crs-page-head-top-back-button {
    .crs-page-head-back-button {
      align-items: center;
      border: none;
      color: #8697a5;
      display: flex;
      margin-bottom: 16px;
      padding: 0;

      .crs-page-head-back-button-icon {
        margin-right: 8px;
      }
    }

    .crs-page-head-back-button:hover {
      color: $brand-color-contrast;
      cursor: pointer;
    }
  }

  .crs-page-head-content {
    display: flex;

    .crs-page-head-left {
      .crs-page-head-title-container {
        align-items: center;
        color: $text-color;
        display: flex;
        font-weight: 500;
        font-size: 19px !important;

        .crs-page-head-title--passive {
          color: $gray5;
        }

        .crs-page-head-tooltip {
          margin-left: 12px;
        }
      }
    }

    .crs-page-head-right {
      margin-left: auto;
      width: max-content;

      .crs-page-head-back-button {
        align-items: center;
        color: $description-color-light;
        display: flex;

        .crs-page-head-back-button-icon {
          margin-right: 8px;
        }
      }

      .crs-page-head-back-button:hover {
        color: $brand-color-contrast;
        cursor: pointer;
      }
    }
  }

  .crs-page-head-description {
    color: $description-color;
    margin-top: 5px;
    line-height: 20px;
  }
}
