@import "./spaceSelector.scss";

$avatar-width: 36px;
$avatar-height: 36px;

.space-switcher {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex: 1 1;

  .m-spinnerpage-component {
    padding-left: 20px;
    svg {
      width: 20px;
    }
  }

  &__container {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    color: white;
    padding: 6px 12px;
    height: 43px;

    &:hover {
      background-color: #394b59;
      border-radius: 5px;
    }

    &__box {
      height: 27px;
    }
    &__avatar {
      width: $avatar-width;
      height: $avatar-height;
      img {
        width: $avatar-width;
        height: $avatar-height;
        border-radius: 2px;
        border: 1px solid #000;
      }
    }
    &__info {
      padding-left: 5px;
      max-width: 150px;
      padding-top: 1px;

      &__label--org {
        font-size: 9px;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 10px;
      }
      &__label-org__billing {
        font-size: 13px;
        letter-spacing: 0.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 4px;
      }
      &__label--ws {
        font-size: 13px;
        letter-spacing: 0.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__selector-icon {
      line-height: 25px;
    }
  }
}

.space__selector__dialog {
  .m-basic-dialog-container {
    width: 100%!important;
    padding: 0;
    .m-basic-dialog-head {
      padding: 22px;
      padding-bottom: 0;
    }
  }
  .manage__workspaces__loader {
    height: 100%;
    display: block;
    margin-top: 18%;
  }
}

.space__selector__dialog__full {
  .m-basic-dialog-container {
    max-width: 940px !important;
    height: 540px;
  }
}

.space__selector__dialog__fit {
  .m-basic-dialog-container {
    max-width: 710px !important;
    height: 460px;
  }
  .space-selector__content {
    max-height: 250px !important;
    .space-selector__content__loader{
      height: 230px!important;
    }
  }
}
