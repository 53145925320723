$header-height: 40px;
$default-border-radius: 3px;
$default-label-color: #5c7080;
$default-label-hover-color: #394b59;
$avatar-width: 48px;
$avatar-height: 48px;

.space-selector {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  position: relative;
  //gap: 10px;

  .initial_spinner {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
  }

  & > div {
    margin: 15px 15px 0px 15px;
  }

  &__header {
    display: flex;
    height: $header-height;
    justify-content: space-between;

    &__info {
      display: flex;
      align-items: center;
      &__avatar {
        padding-right: 5px;
        position: relative;
        img {
          width: $avatar-width;
          height: $avatar-height;
          border: 1px solid #c9c9c9;
          border-radius: $default-border-radius;
        }
        &__id {
          bottom: -3px;
        }
      }
      &__label {
        color: $default-label-color;
      }
      &__empty {
        &__label {
          color: $default-label-color;
          display: flex;
          flex-direction: row;
          align-items: center;
          i {
            background-color: #f8f8f8a6;
            border: 1px solid #e1e8ed;
            margin-right: 5px;
            border-radius: $default-border-radius;
            width: $avatar-width;
            height: $avatar-height;
            display: flex !important;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    &__action-menu {
      &__button {
        border: none !important;
        &:hover {
          .space-selector__header__action-menu__button__text {
            color: $default-label-hover-color !important;
          }

          svg {
            fill: $default-label-hover-color;
          }
        }

        svg {
          fill: $default-label-color;
        }

        &__text {
          font-size: 13px;
          float: right;
          line-height: 21px;
          padding-left: 5px;
          color: $default-label-color;
        }
      }
    }
  }

  &__search-area {
    height: 55px;
    position: relative;
    &__search-icon {
      position: absolute;
      z-index: 9;
      color: #5c7080;
      margin: 15px;
      &__active {
        svg {
          width: 22px;
        }
        top: 7px;
      }
    }
    &__clear-icon {
      cursor: pointer;
      position: absolute;
      display: none !important;
      z-index: 9;
      color: #5c7080;
      margin: 15px;
      right: 0;
      top: 0;
      &__show {
        display: block !important;
      }
    }
    input {
      padding-left: 35px;
      font-size: 14px !important;
    }
  }

  &__action-bar {
    display: flex;
    height: 40px;
    background-color: #f8f8f8a6;
    justify-content: space-between;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 1px solid #e1e8ed;

    & > div {
      padding: 10px 15px 0 15px;
    }

    &__back {
      &__disable {
        opacity: 0.3;
      }
      svg {
        fill: $default-label-color;
      }
      label {
        cursor: pointer;
        font-size: 12px;
        padding-left: 10px;
        color: $default-label-color;
        &:hover {
          color: $default-label-hover-color;
        }
      }
    }
  }

  &__info-box {
    padding: 10px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: overlay;
    max-height: 320px;
    margin-left: 0px !important;
    width: 100vw;
    max-width: 100%;

    &__loader {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 300px;
    }

    &__info-box {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 50px;
      font-size: 16px;
      align-items: center;
      color: $default-label-color;
      height: 300px;

      svg {
        padding-right: 5px;
        fill: $default-label-color;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      cursor: pointer;

      width: 213px;
      height: 70px;
      margin-bottom: 15px;
      margin-left: 16px;

      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #efefef70;
      border-radius: $default-border-radius;
      position: relative;

      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);

      &__active {
        background: #f3f3f3a6;
      }

      &:hover {
        background: #f3f3f3a6;
      }

      &__avatar {
        width: $avatar-width;
        height: $avatar-height;
        margin-left: 10px;
        position: relative;
        background-color: #a6a5a514;
        img {
          border-radius: $default-border-radius;
          width: $avatar-width;
          height: $avatar-height;
          border: 1px solid #dedede;
        }
      }

      &__label {
        padding-left: 7px;
        color: $default-label-color;
        width: 148px;
        word-wrap: break-word;
        white-space: normal;
        overflow-wrap: break-word;
      }
      &__id {
        position: absolute;
        left: 1px;
        bottom: 1px;
        color: #ffffff;
        font-size: 9px;
        width: 46px;
        text-align: left;
        font-weight: 600;
        background-color: #00000054;
        padding-left: 4px;
        border-radius: 0px 0px 2px 2px;
      }
    }
  }
}
