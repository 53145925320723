.dynamic-table {
    &__header {
        text-align: right;
        display: flex;
        flex-direction: row;
        background-color: #f6f8fa;
        padding: 10px;
        margin-bottom: 0;
        border: 1px solid #e1e8ed;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        &__info {
        }
        &__group {
            display: flex;
            flex-direction: row;
            grid-gap: 10px;
            gap: 10px;
            &__buttons {
                display: flex;
                flex-direction: row;
                gap: 7px;

                .btn--disabled {
                    background-color: #ebf1f5 !important;
                    color: #bfccd6 !important;
                    svg {
                        fill: #bfccd6 !important;
                    }
                }
                .crs-btn {
                    height: 25px !important;
                    padding: 3px 9px !important;
                    color: #ffff;
                    svg {
                        fill: #ffff;
                    }
                }
                &__filter {
                    //background-color: $blue4 !important;
                    //border-color: $blue4 !important;
                    //font-size: 12px !important;
                    background-color: $blue4;
                    border-color: $blue4;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    &:hover {
                        background-color: $blue5;
                        border-color: $blue5;
                    }
                }
                &__clear {
                    display: flex;
                    background-color: $red4;
                    border-color: $red4;
                    display: flex;
                    align-items: center;
                }
                &__reflesh {
                    background-color: $orange4;
                    border-color: $orange4;
                }
            }
        }
    }

    &__content {
    }
}